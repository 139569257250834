import { PunctuationMarkType, type LanguageMetadata } from '../languages-config'
import words10k from './ru_10k.json'
import ngrams from './ru_ngrams.json'

// prettier-ignore
// (printWidth)
export const ruMetadata: LanguageMetadata = {
  code: 'ru',
  title: 'Russian',
  emoji: '🇷🇺',

  lowerLetters: 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя',
  upperLetters: 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ',
  nonEssentialLetters: 'ёЁ',
  // NOTE: Russian PC types big E dieiresis instead of Ё (MacOS Sonoma 14.3.1)

  punctuation: [
    {
      char: '-',
      type: PunctuationMarkType.Hyphen,
    },
    // {
    //   char: '—',
    //   type: PunctuationMarkType.Dash,
    // },
    {
      char: ',',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '.',
      type: PunctuationMarkType.End,
    },
    {
      char: '?',
      type: PunctuationMarkType.End,
    },
    {
      char: '!',
      type: PunctuationMarkType.End,
    },
    {
      char: ':',
      type: PunctuationMarkType.Comma,
    },
    {
      char: ';',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '()',
      type: PunctuationMarkType.Bracket,
    },
    {
      char: '"',
      type: PunctuationMarkType.Bracket,
    },
    // {
    //   char: '«»',
    //   type: PunctuationMarkType.Bracket,
    // },
    // {
    //   char: '„“',
    //   type: PunctuationMarkType.Bracket,
    // },
  ],
  
  dicts: {
    words10k,
    ngrams,
  }
}
