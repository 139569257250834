<script setup lang="ts">
import { useMouse } from '@vueuse/core'
import { computed, useTemplateRef } from 'vue'
import Emoji from './Emoji.vue'

type Props = {
  show: boolean
  text: string
  emoji: string
}
const props = defineProps<Props>()

const { x, y } = useMouse()
const toastRef = useTemplateRef('toastRef')

const toastStyle = computed(() => {
  const toastWidth = toastRef.value?.offsetWidth || 0
  const toastHeight = toastRef.value?.offsetHeight || 0

  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight

  let posX = x.value + 10 // Default to the right of the cursor
  let posY = y.value + 10 // Default to below the cursor

  // Check if there’s enough space to show on the right
  if (posX + toastWidth > viewportWidth) {
    posX = x.value - toastWidth - 10 // Show on the left of the cursor
  }

  // Check if there’s enough space to show below
  if (posY + toastHeight > viewportHeight) {
    posY = y.value - toastHeight - 10 // Show above the cursor
  }

  return {
    top: `${posY}px`,
    left: `${posX}px`,
    opacity: props.show ? '100%' : '0',
  }
})
</script>

<template>
  <div class="toast-wrapper" ref="toastRef" :style="toastStyle">
    <Emoji :name="emoji" type="separate" class="emoji-component" />
    <p>{{ text }}</p>
  </div>
</template>

<style lang="scss" scoped>
.toast-wrapper {
  position: fixed;
  background-color: var(--c-divider);
  color: var(--c-text-primary);
  padding: var(--s-md) var(--s-lg);
  border-radius: 100px;
  display: flex;
  gap: var(--s-sm);
  align-items: center;
  z-index: 999;
  cursor: pointer;
  font-size: var(--fz-sm);
  font-weight: 500;
  transition: opacity 0.2s ease-in-out;
  box-shadow: var(--box-shadow-default);

  .emoji-component {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
