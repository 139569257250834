import { random, sample } from 'lodash-es'

export const generateRandomText = (dict: string[], textLength: number) => {
  let result = ''
  const addedWords: string[] = []

  while (result.length < textLength) {
    let randomWord = sample(dict) as string
    if (!addedWords.includes(randomWord)) {
      result += randomWord + ' '
      addedWords.push(randomWord)
    }
  }

  return result.trim()
}

export const generateRandomPseudoWord = (minLen: number, maxLen: number, availableChars: string[], requiredChar = '') => {
  const MAX_REPEATING_CHARS = 2
  const allKnownChars = [...new Set([...availableChars, requiredChar]).values()]

  let word = ''
  const wordLength = random(minLen, maxLen)
  let requiredCharIndex = random(0, wordLength - 1)

  const isTooRepeatativeChar = (latestChars: string[], newChar: string) => {
    return latestChars.length >= MAX_REPEATING_CHARS && latestChars.every((c) => c === newChar)
  }

  let i = 0
  while (i < wordLength) {
    const latestChars = word.slice(-MAX_REPEATING_CHARS).split('')
    let randomChar = ''

    if (i === requiredCharIndex) {
      if (isTooRepeatativeChar(latestChars, requiredChar)) {
        word = word.slice(0, -1)
        i--
        requiredCharIndex--
      }

      randomChar = requiredChar
    } else {
      while (!randomChar || isTooRepeatativeChar(latestChars, randomChar)) {
        randomChar = sample(allKnownChars)!
      }
    }

    i++
    word += randomChar
  }

  return word
}
