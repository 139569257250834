import { useShortcutStore } from '@/stores/shortcutStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { onBeforeUnmount, onMounted, ref } from 'vue'

export default function useKeyboardShortcuts(shortcuts: KeyboardShortcut[]) {
  const shortcutStore = useShortcutStore()
  const localShortcuts = ref<KeyboardShortcut[]>([])

  onMounted(() => {
    localShortcuts.value = [...shortcuts]
    shortcutStore.addShortcuts(localShortcuts.value)
  })

  onBeforeUnmount(() => {
    shortcutStore.removeShortcuts(localShortcuts.value)
  })
}
