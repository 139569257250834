const localStorageAvailable = () => {
  if (typeof window === 'undefined') {
    return false
  }

  const inWindow = typeof window.localStorage === 'object' && typeof window.localStorage.setItem === 'function'
  if (!inWindow) {
    return false
  }
  // Safari private mode has localStorage in the window, but throws when `setItem` is called
  const key = 'safeLocalStorageTest'
  try {
    window.localStorage.setItem(key, 'succeeds')
    window.localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

const noop = () => {}

const useStorageWhenAvailable = (nativeMethod: string, args: string[], callback = noop) => {
  if (localStorageAvailable()) {
    return window.localStorage[nativeMethod](...args)
  } else {
    callback()
  }
}

export const safeLocalStorage = {
  get: (key: string, onLocalStorageNotAvailable = noop): string | null => {
    return useStorageWhenAvailable('getItem', [key], onLocalStorageNotAvailable)
  },
  set: (key: string, value: string, onLocalStorageNotAvailable = noop) => {
    useStorageWhenAvailable('setItem', [key, value], onLocalStorageNotAvailable)
  },
  remove: (key: string, onLocalStorageNotAvailable = noop) => {
    useStorageWhenAvailable('removeItem', [key], onLocalStorageNotAvailable)
  },
  removeAll: (onLocalStorageNotAvailable = noop) => {
    useStorageWhenAvailable('clear', [], onLocalStorageNotAvailable)
  },
}
