import { buildCourse } from '@/course/course-builder'
import { generateLesson } from '@/course/course-lesson-texts'
import { getLessonTitle } from '@/course/course-lesson-titles'
import type { Chapter } from '@/course/course-types'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { PressLog, type CharLog } from '@/types/PressLog'
import { CharTypingResult } from '@/types/typing-result/CharTypingResult'
import dayjs from 'dayjs'
import { min, sample, sum } from 'lodash-es'
import { Char } from './keyboards/KeyChar'
import { typeableKeyCodes, type TypeableKeyCode } from './keyboards/KeyCode'
import { Layer } from './keyboards/Layer'
import { randomMinMax } from './main-utils'
import type { FullTypingResult } from './Trainer'

const lessonDayMin = 10
const lessonDayMax = 15

const wpmMin = 10
const wpmMax = 30

const avgAcc = 0.96

export const fullacc = (tillLessonTitle: string) => {
  const userStore = useUserStore()
  userStore.saveData({ hasFullAccess: true })
}

export const genstat = (tillLessonTitle: string) => {
  const courseStore = useCourseStore()
  const layout = courseStore.current.layout
  const course = buildCourse(layout)!

  const calcLessonsCount = () => {
    let lessonsCount = 0
    for (const ch in course.content) {
      const chapter = ch as Chapter
      for (let index = 0; index < course.content[chapter].length; index++) {
        const lessonData = course.content[chapter][index]

        if (getLessonTitle(lessonData) === tillLessonTitle) {
          return lessonsCount
        }
        lessonsCount++
      }
    }
    return lessonsCount
  }

  let lessonsCount = calcLessonsCount()
  const lessonsPerDay: number[] = []
  while (lessonsCount !== 0) {
    const dl = min([lessonsCount, randomMinMax(lessonDayMin, lessonDayMax)])!
    lessonsPerDay.push(dl)
    lessonsCount -= dl
  }

  const getNextStartDate = () => {
    const nextDayIndex = lessonsPerDay.findIndex((d) => d !== 0)
    if (nextDayIndex === -1) {
      throw Error('No days left')
    }
    const daysAgo = lessonsPerDay.length - nextDayIndex
    const minutesAgo = lessonsPerDay[nextDayIndex] * 2 // each lesson per 2 mins
    lessonsPerDay[nextDayIndex] -= 1
    const nextDate = dayjs().subtract(daysAgo, 'days').subtract(minutesAgo, 'minutes')
    return nextDate
  }

  for (const ch in course.content) {
    const chapter = ch as Chapter
    for (let index = 0; index < course.content[chapter].length; index++) {
      const lessonData = course.content[chapter][index]

      if (getLessonTitle(lessonData) === tillLessonTitle) {
        return
      }

      const text = generateLesson(course, chapter, index)

      const wpm = randomMinMax(wpmMin, wpmMax)
      const avgPressTime = 60000 / (wpm * 5)

      const charLogs: CharLog[] = []
      for (const letter of text.split('')) {
        const presses: PressLog[] = []
        const extraPresses: PressLog[] = []
        const keys = layout.getKeysToType(new Char(letter))!
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]

          const makeTypo = Math.random() > avgAcc

          if (makeTypo) {
            let randomTypo = sample(typeableKeyCodes)
            while (randomTypo === key.keyCode) {
              randomTypo = sample(typeableKeyCodes)
            }
            const typoLayered = new LayeredKeyCode(randomTypo, Layer.Default)
            extraPresses.push(
              new PressLog(typoLayered, layout.getChar(typoLayered.keyCode as TypeableKeyCode, typoLayered.layer)!, letter, avgPressTime, false),
            )
            extraPresses.push(new PressLog(new LayeredKeyCode('Backspace', Layer.Default), '', letter, avgPressTime, false))
          }

          presses.push(new PressLog(key, keys.length > 1 && i === 0 ? '' : letter, letter, avgPressTime, keys.length > 1 && i === 1 ? true : false))
        }
        charLogs.push({
          char: letter,
          isPressed: true,
          extraPresses,
          // @ts-expect-error
          extraFirstPresses: extraPresses.slice(0, 1).map((p) => p.pressedKey),
          extraPressTimeMs: sum(extraPresses.map((p) => p.pressTimeMs)),
          usedKeys: presses,
          pressTimeMs: sum(presses.map((p) => p.pressTimeMs)),
        })
      }

      const startedAt = getNextStartDate()
      const result: FullTypingResult = {
        startedAt,
        finishedAt: startedAt.add(sum(charLogs.map((c) => c.pressTimeMs))!, 'milliseconds'),
        lessonCoords: {
          chapter,
          index,
        },
        charLogs,
      }

      const typingResult = CharTypingResult.fromFullTypingResult(result, layout.os)
      courseStore.saveTraining(result, typingResult)
    }
  }
}
