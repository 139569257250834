import { OS } from '@/types/main-types'
import { Speed } from '@/types/metric-types'

export const localStorageKey = {
  userData: 'user-data',
  aboutDemoDataViewed: 'about-demo-data-viewed',
  theme: 'theme',
  cookieSettings: 'cookie-settings',
  currentLesson: 'current-lesson',
  trainingSession: 'training-session',
}

export const MAX_ACTIVE_LAYOUTS = 3
export const TRAINING_LENGTH_CHARS = 200 * 5 * 2 // 200 wpm x 2 minutes
export const DEFAULT_NUMS_SPEED = new Speed(20 * 5)
export const MIN_NUMBER_LENGTH = 1
export const MAX_NUMBER_LENGTH = 5
export const STATS_MS_PRECISION = 3

export const MAX_CALENDAR_DATA_DAYS = 33
export const MAX_PROFILE_DATA_DAYS = 7

type DoubleDeadKeyBehavior = 'exec-new' | 'exec-new-exec' | 'exec'
type DeadKeyConfig = {
  secondDeadSame: DoubleDeadKeyBehavior
  secondDeadOther: DoubleDeadKeyBehavior
  consumesBackspace: boolean
}
export const deadKeysBehaviorConfig = {
  [OS.mac]: {
    secondDeadSame: 'exec-new', // ¨¨u produces ¨ü
    secondDeadOther: 'exec-new',
    consumesBackspace: true, // ¨← is 0 chars
  } as DeadKeyConfig,
  [OS.win]: {
    secondDeadSame: 'exec-new-exec', // ¨¨u produces ¨¨u
    secondDeadOther: 'exec-new-exec',
    consumesBackspace: false, // ¨← is -1 char
  } as DeadKeyConfig,
  [OS.lnx]: {
    secondDeadSame: 'exec', // ¨¨u produces ¨u
    secondDeadOther: 'exec-new-exec',
    consumesBackspace: true,
  } as DeadKeyConfig,
}
