import { installHotjar, installMixpanel } from '@/helpers/analytics'
import { safeLocalStorage } from '@/helpers/local-storage'
import { localStorageKey } from '@/helpers/main-config'
import { cloudFunctionUrl } from '@/helpers/main-utils'
import axios from 'axios'
import { defineStore } from 'pinia'
import { ref } from 'vue'

type CookieSettings = {
  isAnalyticsAllowed: boolean
  areCookieSettingsResolved: boolean
}

const defaultCookieSettings: CookieSettings = {
  isAnalyticsAllowed: false,
  areCookieSettingsResolved: false,
}

export const getCookieSettingsFromLocalStorage = (): CookieSettings => {
  let cookieSettings = {
    ...defaultCookieSettings,
    ...(JSON.parse(safeLocalStorage.get(localStorageKey.cookieSettings) ?? '{}') as CookieSettings),
  }
  return cookieSettings
}

export const useCookieStore = defineStore('cookie', () => {
  const config = getCookieSettingsFromLocalStorage()
  const areCookieSettingsResolved = ref(config.areCookieSettingsResolved)
  const isAnalyticsAllowed = ref(config.isAnalyticsAllowed)

  const setAnalyticsAllowed = (value: boolean) => {
    isAnalyticsAllowed.value = value
    areCookieSettingsResolved.value = true
    const newConfig: CookieSettings = {
      isAnalyticsAllowed: isAnalyticsAllowed.value,
      areCookieSettingsResolved: areCookieSettingsResolved.value,
    }
    safeLocalStorage.set(localStorageKey.cookieSettings, JSON.stringify(newConfig))

    axios.post(cloudFunctionUrl(value ? 'acceptCookie' : 'rejectCookie'))

    if (value) {
      installMixpanel()
      installHotjar()
    }
  }

  return {
    areCookieSettingsResolved,
    isAnalyticsAllowed,
    setAnalyticsAllowed,
  }
})
