import { KeyboardKey } from '@/helpers/keyboards/KeyboardKey'
import { KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { LayeredKeycodeMap } from '@/types/LayeredKeycodeMap'
import { OS } from '@/types/main-types'
import type { StyleValue } from 'vue'
import { isTypeable, type KeyCode, type ModifierKeyCode, type TypeableKeyCode } from './keyboards/KeyCode'
import { Layer, allLayersByPriority, isOptionLayer } from './keyboards/Layer'

const keysToPressPriority: TypeableKeyCode[] = [
  'KeyA',
  'KeyQ',
  'Backquote',
  'Equal',
  'Backslash',
  'Slash',
  'KeyZ',
  'KeyS',
  'KeyW',
  'Digit1',
  'Minus',
  'BracketRight',
  'Quote',
  'Period',
]

// This aims to avoid situation when both hands are too close and overlap (could happen, because AltGr is from 1 side only)
const keysAtRightSideToAvoidForAltGr: KeyCode[] = [
  'Digit9',
  'Digit0',
  'Minus',
  'Equal',
  'KeyO',
  'KeyP',
  'BracketLeft',
  'BracketRight',
  'Backslash',
  'KeyL',
  'Semicolon',
  'Quote',
  'Period',
  'Slash',
]
const betterAvoid = (layout: KeyboardLayout, keycode: LayeredKeyCode) => {
  return layout.os === OS.win && isOptionLayer(keycode.layer) && keysAtRightSideToAvoidForAltGr.includes(keycode.keyCode)
}

// first func to call
export const filterCandidates = (userLayout: KeyboardLayout, candidates: KeyboardLayout[]): KeyboardLayout[] => {
  const filtered = candidates.filter((candidate) => {
    for (const userKey of userLayout.allKeys()) {
      const currCandidateKey = candidate.keymap.get(userKey.code) as KeyboardKey

      // results into something like [true, true, false, true] (per layer)
      const symbolsEqualityPerLayer = allLayersByPriority.map((layer) => {
        return (
          userKey.keyChars[layer].isTemplate ||
          userKey.keyChars[layer].value === currCandidateKey.keyChars[layer].value ||
          (userKey.keyChars[layer].value === 'Dead' && currCandidateKey.keyChars[layer].isDead())
        )
      })

      if (symbolsEqualityPerLayer.includes(false)) {
        // if at least one layer is different, this candidate is not suitable
        return false
      }
    }
    return true
  })

  return filtered
}

// second func to call
export const getDiffKeys = (userLayout: KeyboardLayout, candidates: KeyboardLayout[], addNonDiffsToUserLayout = false): LayeredKeyCode | null => {
  if (!candidates.length) {
    return null
  }

  // const result = Object.fromEntries(allLayersByPriority.map((l) => [l, new Map<TypeableKeyCode, number>()]))
  const result = new LayeredKeycodeMap<number>()

  for (const layer of allLayersByPriority.slice(0, 4)) {
    for (const userKey of userLayout.allKeys()) {
      // skip filled (entered) symbols
      if (!userKey.keyChars[layer].isTemplate) {
        continue
      }

      const allKeyChars = Array.from({ length: candidates.length }).map((v, i) => candidates[i].keymap.get(userKey.code)!.keyChars[layer])
      const nonDeadKeyChars = allKeyChars.filter((k) => !k.isDead())
      const uniqueNonDead = new Set(nonDeadKeyChars.map((k) => k.value))

      // experiement to get variants for specific key
      // if (userKey.code === 'Digit4') {
      //   if (layer === Layer.Default) {
      //     console.log(Array.from({ length: candidates.length }).map((v, i) => candidates[i].layoutId))
      //   }
      //   console.log(allKeyChars.map((k) => k.value))
      // }

      if (uniqueNonDead.size > 1) {
        result.set(new LayeredKeyCode(userKey.code, layer), uniqueNonDead.size)

        // optimization experiment —
        // const standardKeyChar = candidates.find((c) => c.layoutId === 'abc_india')?.keymap.get(userKey.code)!.keyChars[layer]
        // console.log('standardKeyChar', standardKeyChar)
        // console.log(
        //   'nonDeadKeyChars.filter((k) => k.value === standardKeyChar.value).length',
        //   nonDeadKeyChars.filter((k) => k.value === standardKeyChar?.value).length,
        // )
        // if (standardKeyChar && !standardKeyChar.isDead() && nonDeadKeyChars.filter((k) => k.value === standardKeyChar.value).length === 1) {
        //   result.set(new LayeredKeycode(userKey.code, layer), 999)
        // }
      } else if (addNonDiffsToUserLayout && nonDeadKeyChars.length === allKeyChars.length) {
        // if symbol is same across all candidates, add it to user layout automatically
        userLayout.addKeyValue(layer, candidates[0].keymap.get(userKey.code)!.keyChars[layer].value, userKey.code)
      }
    }
  }

  if (!result.entries().length) {
    return null
  }

  const keyCodesPriorityPoints = [...keysToPressPriority].reverse()

  let maxDiff = {
    layeredKeyCode: LayeredKeyCode.parse(result.entries()[0][0]),
    diff: result.entries()[0][1],
    points: keyCodesPriorityPoints.indexOf(LayeredKeyCode.parse(result.entries()[0][0]).keyCode as TypeableKeyCode),
  }

  // if nothing from Default/Shift layers left and we don't know if user layout supports AltGr, give single AltGr press
  if (isOptionLayer(maxDiff.layeredKeyCode.layer) && !userLayout.supportsOptionLayer) {
    return new LayeredKeyCode('AltGraph', Layer.Default)
  }

  for (const entry of result.entries().slice(1)) {
    const newDiff = {
      layeredKeyCode: LayeredKeyCode.parse(entry[0]),
      diff: entry[1],
      points: keyCodesPriorityPoints.indexOf(LayeredKeyCode.parse(entry[0]).keyCode as TypeableKeyCode),
    }

    if (
      !betterAvoid(userLayout, newDiff.layeredKeyCode) &&
      (betterAvoid(userLayout, maxDiff.layeredKeyCode) ||
        maxDiff.diff < newDiff.diff ||
        (maxDiff.diff === newDiff.diff && maxDiff.layeredKeyCode.layer === newDiff.layeredKeyCode.layer && maxDiff.points < newDiff.points))
    ) {
      // NOTE: little hack to make most popular "Standard" layout easier to setup
      if (newDiff.layeredKeyCode.keyCode === 'Digit6' && newDiff.layeredKeyCode.layer === Layer.ShiftOption) {
        continue
      }

      maxDiff = newDiff
    }
  }

  return maxDiff.layeredKeyCode
}

export const keyStyleStepOne = (code: KeyCode): StyleValue => {
  if (code === 'KeyZ') {
    return {}
  }

  const keysToShow: KeyCode[] = [
    'ShiftLeft',
    'KeyZ',
    'ControlLeft',
    'ControlRight',
    'MetaLeft',
    'MetaRight',
    'Space',
    'AltLeft',
    'AltRight',
    'AltGraph',
  ]

  return {
    visibility: keysToShow.includes(code) ? 'visible' : 'hidden',
    '--background-color': 'var(--c-surface)',
  }
}

export const keyStyleStepTwo = (
  code: KeyCode,
  value: string,
  highlightedModifiers: ModifierKeyCode[],
  highlightedKey: LayeredKeyCode | null,
  layoutState: Layer | null,
  userLayout: KeyboardLayout,
): StyleValue => {
  if (highlightedModifiers.includes(code as ModifierKeyCode)) {
    return {}
  }
  if (highlightedKey && layoutState && highlightedKey.keyCode === code) {
    return {}
  }

  if (!isTypeable(code) || code === 'Space' || (layoutState && !!userLayout.getChar(code as TypeableKeyCode, layoutState))) {
    return {
      '--background-color': 'var(--c-surface)',
    }
  }

  return {
    '--background-color': 'var(--c-surface)',
  }
}
