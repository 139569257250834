import type { Layer } from '@/helpers/keyboards/Layer'
import { LayeredKeyCode, type LayeredKeycodeId } from './LayeredKeycode'

export class LayeredKeycodeMap<T> {
  value: Record<LayeredKeycodeId, T> = {}

  getOrCreate(layeredKeycode: LayeredKeyCode, template: T): T {
    if (!this.value[layeredKeycode.id()]) {
      this.value[layeredKeycode.id()] = template
    }
    return this.value[layeredKeycode.id()]
  }

  get(layeredKeycode: LayeredKeyCode): T | null {
    return this.value[layeredKeycode.id()] ?? null
  }

  set(layeredKeycode: LayeredKeyCode, data: T) {
    this.value[layeredKeycode.id()] = data
  }

  keys() {
    return Object.keys(this.value)
  }

  values() {
    return Object.values(this.value)
  }

  entries(): [LayeredKeycodeId, T][] {
    return Object.entries(this.value).map(([k, v]) => [Number(k), v])
  }

  perLayer(): Partial<Record<Layer, LayeredKeycodeMap<T>>> {
    let result: Partial<Record<Layer, LayeredKeycodeMap<T>>> = {}

    for (const [key, val] of this.entries()) {
      const layer = LayeredKeyCode.parse(key).layer
      result[layer] = result[layer] ?? new LayeredKeycodeMap<T>()
      result[layer]!.set(LayeredKeyCode.parse(key), val)
    }

    return result
  }

  filterByLayer(layer: Layer): LayeredKeycodeMap<T> {
    const result = new LayeredKeycodeMap<T>()
    const layerIndexStr = layer.toString()
    result.value = Object.fromEntries(Object.entries(this.value).filter(([k]) => k.toString()[0] === layerIndexStr))
    return result
  }

  serialize() {
    return this.value
  }

  toJSON() {
    return this.serialize()
  }

  static parse<T>(data: Record<LayeredKeycodeId, T>) {
    const result = new LayeredKeycodeMap<T>()
    result.value = data
    return result
  }
}
