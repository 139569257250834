import type { FullTypingResult } from '@/helpers/Trainer'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTrainingStore = defineStore('training', () => {
  const lastTypingResult = ref<FullTypingResult | null>(null)

  return {
    lastTypingResult,
  }
})
