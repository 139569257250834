<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue'
import Keyboard from '@/components/Keyboard.vue'
import { shortcutsConfig } from '@/configs/shortcuts-config'
import { buildCourse } from '@/course/course-builder'
import { getChapterTitle, getLessonTitle } from '@/course/course-lesson-titles'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { Layer } from '@/helpers/keyboards/Layer'
import { typingTextWidthPx } from '@/helpers/Trainer'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { computed, onBeforeMount, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

onBeforeMount(() => {
  document.body.classList.add('typing')
  document.documentElement.scrollTo(0, 0)
})
onUnmounted(() => {
  document.body.classList.remove('typing')
})

const { t, locale } = useI18n()
const router = useRouter()
const userStore = useUserStore()
const courseStore = useCourseStore()

const lessonInfo = computed(() => userStore.currentLesson!)
const course = buildCourse(courseStore.current.layout)!
const lesson = computed(() => course.content[lessonInfo.value.chapter][lessonInfo.value.index])

const onContinue = () => {
  router.push({ name: 'typing' })
}

const shortcuts = {
  continue: new KeyboardShortcut(shortcutsConfig.resume, () => {
    onContinue()
  }),
}
useKeyboardShortcuts(Object.values(shortcuts))

const spaceKeyCode = new LayeredKeyCode('Space', Layer.Default)
</script>

<template>
  <div class="training-title">{{ getChapterTitle(lessonInfo.chapter) }}. {{ getLessonTitle(lesson) }}</div>

  <section class="trainer">
    <teleport to="#app-header">
      <AppHeader> </AppHeader>
    </teleport>

    <div class="text-wrapper">
      <p>
        {{ t('Typing.introText') }}
      </p>
      <div class="action">
        <div class="shortcut-hint">
          {{ t('Typing.pressSpaceToStartTyping') }}
        </div>
      </div>
    </div>

    <div class="keyboard-wrapper">
      <Keyboard :handle-presses="true" :show-hands="true" :show-keyboard="true" :key-to-press="spaceKeyCode" show-finger-mapping />
    </div>
  </section>
</template>

<style lang="scss" scoped>
:global(body.typing) {
  overflow: hidden;
}

.training-title {
  line-height: 1;
  font-size: var(--fz-sm);
  position: absolute;
  bottom: var(--grid-cell);
  left: var(--grid-cell);
  color: var(--c-text-tertiary);
}

.trainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: v-bind(typingTextWidthPx);
  justify-content: center;
  position: relative;

  .text-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 35rem;
    margin: var(--s-lg) auto var(--s-xl);
    font-size: var(--fz-lg);

    h1 {
      line-height: 1.4;
      margin-bottom: var(--s-lg);
    }

    h2 {
      font-size: var(--font-size-lg);
      font-weight: 600;
      margin-top: var(--s-lg);
    }

    p {
      margin-top: var(--s-md);
    }

    .action {
      margin-top: var(--s-xl);
      display: flex;
      justify-content: center;

      .shortcut-hint {
        font-size: var(--fz-sm);
      }
    }
  }

  .keyboard-wrapper {
    position: relative;
  }
}
</style>
