<script setup lang="ts">
import Button from '@/components/Button.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'
import { signInWithGoogle } from '@/plugins/firebase/auth'
import { navigateToLanding } from '@/plugins/i18n'
import { useCourseStore } from '@/stores/courseStore'
import { useTrainingSessionStore } from '@/stores/trainingSessionStore'
import { useUserStore } from '@/stores/userStore'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import DarkLightImage from './DarkLightImage.vue'

type Props = {
  divided?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  divided: false,
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const couseStore = useCourseStore()
const trainingSessionStore = useTrainingSessionStore()

const isTrainingView = computed(() => route.name === 'intro' || route.name === 'typing' || route.name === 'result')
const hideLoginBtn = computed(() => isTrainingView.value)
const hideSignupBtn = computed(() => isTrainingView.value || route.name === 'courseSetup')

const handleLogoClick = () => {
  const goToLanding = route.name === 'home' || !trainingSessionStore.isActive
  if (goToLanding) {
    trainingSessionStore.clear()
    navigateToLanding(userStore.settings.locale)
    window.scrollTo({ top: 0 })
    return
  }
  router.push({ name: 'home' })
}
</script>

<template>
  <nav :class="{ divided }">
    <div class="left-wrapper">
      <slot name="left">
        <div class="logo-wrapper">
          <RouterLink to="/home" @click="handleLogoClick" class="logo">
            <img src="/src/assets/img/logo-keyboard.png" alt="Typing School" />
            <DarkLightImage path="logo-text-beta" ext="svg" alt="Typing School" />
          </RouterLink>
        </div>
        <slot name="after-logo"></slot>
      </slot>
    </div>

    <slot name="middle"> </slot>

    <div class="right-wrapper">
      <slot name="right">
        <div class="action-items">
          <Button variant="primary" v-if="!userStore.authData && !hideSignupBtn" @click="signInWithGoogle">
            {{ t('signUp') }}
          </Button>
          <Button variant="secondary" v-if="!userStore.authData && !hideLoginBtn" @click="signInWithGoogle">
            {{ t('login') }}
          </Button>
          <DropdownMenu variant="mainSettings" />
        </div>
      </slot>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  height: var(--header-height, 3.75rem);
  width: 100%;
  min-width: var(--min-viewport-inner-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: var(--c-background);
  border-bottom: 1px solid transparent;

  &.divided {
    border-bottom-color: var(--c-divider);
  }
}

.left-wrapper {
  display: flex;
  flex: 3;

  .view-title {
    font-weight: 500;
    height: var(--header-inner-height);
    font-size: var(--fz-md);
  }
}

.logo-wrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;

  .logo {
    text-decoration: none;
    outline: none;
    font-size: 0;
    :deep(img) {
      height: 2rem;
    }
  }
}

.right-wrapper {
  flex: 3;
  display: flex;
  justify-content: flex-end;

  .action-items {
    display: flex;
    align-items: center;
    gap: var(--s-sm);
  }
}
</style>
