import type { LanguageCode } from '@/languages/languages-config'
import { UserLocale, type LocalizedText } from '@/plugins/i18n'
import type { OS } from '@/types/main-types'

export enum ShortcutVariant {
  Default = 'Default',
  German = 'German',
  French = 'French',
}

type LayoutConfig = {
  title: LocalizedText
  primaryLanguage: LanguageCode
  shortcutsLayout?: string
  shortcutsVariant?: ShortcutVariant
}

export const layoutsConfig: Record<OS, Record<string, LayoutConfig>> = {
  // MacOS
  mac: {
    // english
    // ABC, US, Canadian, Australian
    standard: {
      primaryLanguage: 'en',
      title: {
        en: 'U.S / ABC', // Canadian English; Australian
        ru: 'США / ABC', // Канадсская английская; Австралийская
        es: 'Estadounidense / ABC', // Inglés canadiense; Austríaco
        de: 'US / ABC', // Kanada, englisch; Australisch
        fr: 'Américain / ABC', // Anglais canadien; Australien
        it: 'USA / ABC', // Inglese canadese; Australiano
      },
    },
    abc_extended: {
      primaryLanguage: 'en',
      title: {
        en: 'ABC — Extended',
        ru: 'ABC — расширенная',
        es: 'ABC — Teclado Ampliado',
        de: 'ABC - Erweitert',
        fr: 'ABC - Étendu',
        it: 'ABC - Esteso',
      },
    },
    us_international_pc: {
      primaryLanguage: 'en',
      title: {
        en: 'US International — PC',
        ru: 'США международная — ПК',
        es: 'Estadounidense internacional – PC',
        de: 'US International - PC',
        fr: 'US International - PC',
        it: 'USA (internazionale) - PC',
      },
    },
    abc_india: {
      primaryLanguage: 'en',
      title: {
        en: 'ABC — India',
        ru: 'ABC — Индия',
        es: 'ABC - India',
        de: 'ABC - Indien',
        fr: 'ABC - Inde',
        it: 'ABC - India',
      },
    },
    british: {
      primaryLanguage: 'en',
      title: {
        en: 'British',
        ru: 'Британская',
        es: 'Británico',
        de: 'Englisch',
        fr: 'Britannique',
        it: 'Britannico',
      },
    },
    british_pc: {
      primaryLanguage: 'en',
      title: {
        en: 'British — PC',
        ru: 'Британская — ПК',
        es: 'Británico - PC',
        de: 'Englisch - PC',
        fr: 'Britannique - PC',
        it: 'Britannico - PC',
      },
    },
    irish: {
      primaryLanguage: 'en',
      title: {
        en: 'Irish',
        ru: 'Ирландская',
        es: 'Irlandés',
        de: 'Irisch',
        fr: 'Irlandais',
        it: 'Irlandese',
      },
    },
    irish_extended: {
      primaryLanguage: 'en',
      title: {
        en: 'Irish — Extended',
        ru: 'Ирландская — расширенная',
        es: 'Irlandés — Teclado ampliado',
        de: 'Irisch - Erweitert',
        fr: 'Irlandais - Étendu',
        it: 'Irlandese - Esteso',
      },
    },
    dvorak: {
      primaryLanguage: 'en',
      title: {
        en: 'Dvorak',
        ru: 'Дворак',
        es: 'Dvorak',
        de: 'Dvorak',
        fr: 'Dvorak',
        it: 'Dvorak',
      },
    },
    colemak: {
      primaryLanguage: 'en',
      title: {
        en: 'Colemak',
        ru: 'Colemak',
        es: 'Colemak',
        de: 'Colemak',
        fr: 'Colemak',
        it: 'Colemak',
      },
    },

    // spanish
    spanish: {
      primaryLanguage: 'es',
      title: {
        en: 'Spanish',
        ru: 'Испанская',
        es: 'Español',
        de: 'Spanisch',
        fr: 'Espagnol',
        it: 'Spagnolo',
      },
    },
    latin_american: {
      primaryLanguage: 'es',
      title: {
        en: 'Latin American',
        ru: 'Латинская Америка',
        es: 'Latinoamericano',
        de: 'Lateinamerikanisch',
        fr: 'Latino-Américain',
        it: 'Latino-americano',
      },
    },
    spanish_legacy: {
      primaryLanguage: 'es',
      title: {
        en: 'Spanish — Legacy',
        ru: 'Испанская — традиционная',
        es: 'Español - Teclado antiguo',
        de: 'Spanisch – Ältere Version',
        fr: 'Espagnol - Ancien clavier',
        it: 'Spagnolo - Tradizionale',
      },
    },

    // german
    // abc qwertz, austrian, german
    german: {
      primaryLanguage: 'de',
      shortcutsVariant: ShortcutVariant.German,
      title: {
        en: 'German', // ABC - QWERTZ; Austrian; German
        ru: 'Немецкая', // ABC - QWERTZ; Австрийская; Немецкая
        es: 'Alemán', // ABC - QWERTZ; Austriáco; Alemán
        de: 'Deutsch', // ABC - QWERTZ; Österreichisch; Deutsch
        fr: 'Allemand', // ABC - QWERTZ; Autrichien; Allemand
        it: 'Tedesco', // ABC - QWERTZ; Austriaco; Tedesco
      },
    },
    german_standard: {
      primaryLanguage: 'de',
      shortcutsVariant: ShortcutVariant.German,
      title: {
        en: 'German Standard',
        ru: 'Немецкая — стандартная',
        es: 'Alemán - Estándar',
        de: 'Deutsch - Standard',
        fr: 'Allemand - Standard',
        it: 'Tedesco standard',
      },
    },
    swiss_german: {
      primaryLanguage: 'de',
      shortcutsVariant: ShortcutVariant.German,
      title: {
        en: 'Swiss German',
        ru: 'Швейцарская немецкая',
        es: 'Alemán suizo',
        de: 'Schweiz, deutsch',
        fr: 'Suisse allemand',
        it: 'Tedesco svizzero',
      },
    },

    // russian
    russian: {
      primaryLanguage: 'ru',
      shortcutsLayout: 'standard',
      title: {
        en: 'Russian',
        ru: 'Русская',
        es: 'Ruso',
        de: 'Russisch',
        fr: 'Russe',
        it: 'Russo',
      },
    },
    russian_pc: {
      primaryLanguage: 'ru',
      shortcutsLayout: 'standard',
      title: {
        en: 'Russian — PC',
        ru: 'Русская — ПК',
        es: 'Ruso - PC',
        de: 'Russisch - PC',
        fr: 'Russe - PC',
        it: 'Russo - PC',
      },
    },
    russian_qwerty: {
      primaryLanguage: 'ru',
      shortcutsLayout: 'standard',
      title: {
        en: 'Russian — QWERTY',
        ru: 'Русская — QWERTY',
        es: 'Ruso - QWERTY',
        de: 'Russisch - QWERTY',
        fr: 'Russe - QWERTY',
        it: 'Russo - QWERTY',
      },
    },

    // french
    // same as abc_azerty
    french: {
      primaryLanguage: 'fr',
      title: {
        en: 'French',
        ru: 'Французская',
        es: 'Francés',
        de: 'Französisch',
        fr: 'Français',
        it: 'Francese',
      },
    },
    french_pc: {
      primaryLanguage: 'fr',
      title: {
        en: 'French — PC',
        ru: 'Французская — ПК',
        es: 'Francés - PC',
        de: 'Französisch - PC',
        fr: 'Français - PC',
        it: 'Francese - PC',
      },
    },
    french_numerical: {
      primaryLanguage: 'fr',
      title: {
        en: 'French — Numerical',
        ru: 'Французская — числовая',
        es: 'Francés - Teclado numérico',
        de: 'Französisch - Numerisch',
        fr: 'Français - Numérique',
        it: 'Francese - Numerico',
      },
    },
    swiss_french: {
      primaryLanguage: 'fr',
      title: {
        en: 'Swiss French',
        ru: 'Швейцарская французская',
        es: 'Francés suizo',
        de: 'Schweiz, französisch',
        fr: 'Suisse romand',
        it: 'Francese svizzero',
      },
    },
    canadian_csa: {
      primaryLanguage: 'fr',
      title: {
        en: 'Canadian French — CSA',
        ru: 'Канадская французская — CSA',
        es: 'Francés canadiense - CSA',
        de: 'Kanada, französisch - CSA',
        fr: 'Français canadien - CSA',
        it: 'Francese anadese - CSA',
      },
    },
    canadian_pc: {
      primaryLanguage: 'fr',
      title: {
        en: 'Canadian French — PC',
        ru: 'Канадская французская — ПК',
        es: 'Francés canadiense - PC',
        de: 'Kanada, französisch - PC',
        fr: 'Français canadien - PC',
        it: 'Francese canadese - PC',
      },
    },

    // portuguese
    portuguese: {
      primaryLanguage: 'pt',
      title: {
        en: 'Portuguese',
        ru: 'Португальская',
        es: 'Portugués',
        de: 'Portugiesisch',
        fr: 'Portugais',
        it: 'Portoghese',
      },
    },
    brazilian_abnt2: {
      primaryLanguage: 'pt',
      title: {
        en: 'Brazilian — ABNT2',
        ru: 'Бразильская — ABNT2',
        es: 'Brasileño - ABNT2',
        de: 'Brasilianisch - ABNT2',
        fr: 'Brésilien - ABNT2',
        it: 'Brasiliano - ABNT2',
      },
    },

    // italian
    italian: {
      primaryLanguage: 'it',
      title: {
        en: 'Italian',
        ru: 'Итальянская',
        es: 'Italiano',
        de: 'Italienisch',
        fr: 'Italien',
        it: 'Italiano',
      },
    },
    italian_qzerty: {
      primaryLanguage: 'it',
      title: {
        en: 'Italian — QZERTY',
        ru: 'Итальянская — QZERTY',
        es: 'Italiano - QZERTY',
        de: 'Italienisch - QZERTY',
        fr: 'Italien - QZERTY',
        it: 'Italiano - QZERTY',
      },
    },

    // polish
    polish: {
      primaryLanguage: 'pl',
      title: {
        en: 'Polish',
        ru: 'Польская',
        es: 'Polaco',
        de: 'Polnisch',
        fr: 'Polonais',
        it: 'Polacco',
      },
    },
    polish_qwertz: {
      primaryLanguage: 'pl',
      title: {
        en: 'Polish — QWERTZ',
        ru: 'Польская — QWERTZ',
        es: 'Polaco - QWERTZ',
        de: 'Polnisch - QWERTZ',
        fr: 'Polonais - QWERTZ',
        it: 'Polacco - QWERTZ',
      },
    },

    // ukrainian
    ukrainian: {
      primaryLanguage: 'uk',
      title: {
        en: 'Ukrainian',
        ru: 'Украинская',
        es: 'Ucraniano',
        de: 'Ukrainisch',
        fr: 'Ukrainien',
        it: 'Ucraino',
      },
    },
    ukrainian_qwerty: {
      primaryLanguage: 'uk',
      title: {
        en: 'Ukrainian — QWERTY',
        ru: 'Украинская — QWERTY',
        es: 'Ucraniano - QWERTY',
        de: 'Ukrainisch - QWERTY',
        fr: 'Ukrainien - QWERTY',
        it: 'Ucraino - QWERTY',
      },
    },
    ukrainian_legacy: {
      primaryLanguage: 'uk',
      title: {
        en: 'Ukrainian — Legacy',
        ru: 'Украинская — традиционная',
        es: 'Ucraniano - Teclado antiguo',
        de: 'Ukrainisch - Ältere Version',
        fr: 'Ukrainien - Ancien clavier',
        it: 'Ucraino - Tradizionale',
      },
    },

    // turkish
    turkish_f: {
      primaryLanguage: 'tr',
      title: {
        en: 'Turkish F',
        ru: 'Турецкая F',
        es: 'Turco F',
        de: 'Türkisch F',
        fr: 'F turc',
        it: 'Turco F',
      },
    },
    turkish_q: {
      primaryLanguage: 'tr',
      title: {
        en: 'Turkish Q',
        ru: 'Турецкая Q',
        es: 'Turco Q',
        de: 'Türkisch Q',
        fr: 'Q turc',
        it: 'Turco Q',
      },
    },
    turkish_f_legacy: {
      primaryLanguage: 'tr',
      title: {
        en: 'Turkish F — Legacy',
        ru: 'Турецкая F — традиционная',
        es: 'Turco F - Teclado antiguo',
        de: 'Türkisch F - Ältere Version',
        fr: 'F turc - Ancien clavier',
        it: 'Turco F - Tradizionale',
      },
    },
    turkish_q_legacy: {
      primaryLanguage: 'tr',
      title: {
        en: 'Turkish Q — Legacy',
        ru: 'Турецкая Q — традиционная',
        es: 'Turco Q - Teclado antiguo',
        de: 'Türkisch Q - Ältere Version',
        fr: 'Q turc - Ancien clavier',
        it: 'Turco Q - Tradizionale',
      },
    },

    // swedish
    swedish: {
      primaryLanguage: 'sv',
      title: {
        en: 'Swedish',
        ru: 'Шведская',
        es: 'Sueco',
        de: 'Schwedisch',
        fr: 'Suédois',
        it: 'Svedese',
      },
    },
    swedish_legacy: {
      primaryLanguage: 'sv',
      title: {
        en: 'Swedish — Legacy',
        ru: 'Шведская — традиционная',
        es: 'Sueco - Teclado antiguo',
        de: 'Schwedisch - Ältere Version',
        fr: 'Suédois - Ancien clavier',
        it: 'Svedese - Tradizionale',
      },
    },

    // czech
    czech: {
      primaryLanguage: 'cs',
      title: {
        en: 'Czech',
        ru: 'Чешская',
        es: 'Checo',
        de: 'Tschechisch',
        fr: 'Tchèque',
        it: 'Ceco',
      },
    },
    czech_qwerty: {
      primaryLanguage: 'cs',
      title: {
        en: 'Czech — QWERTY',
        ru: 'Чешская — QWERTY',
        es: 'Checo - QWERTY',
        de: 'Tschechisch - QWERTY',
        fr: 'Tchèque - QWERTY',
        it: 'Ceco - QWERTY',
      },
    },

    // danish
    danish: {
      primaryLanguage: 'da',
      title: {
        en: 'Danish',
        ru: 'Датская',
        es: 'Danés',
        de: 'Dänisch',
        fr: 'Danois',
        it: 'Danese',
      },
    },

    // dutch
    dutch: {
      primaryLanguage: 'nl',
      title: {
        en: 'Dutch',
        ru: 'Нидерландская',
        es: 'Neerlandés',
        de: 'Niederländisch',
        fr: 'Néerlandais',
        it: 'Olandese',
      },
    },
    belgian: {
      primaryLanguage: 'nl',
      title: {
        en: 'Belgian',
        ru: 'Бельгийская',
        es: 'Belga',
        de: 'Belgisch',
        fr: 'Belge',
        it: 'Belga',
      },
    },

    // hungarian
    hungarian: {
      primaryLanguage: 'hu',
      title: {
        en: 'Hungarian',
        ru: 'Венгерская',
        es: 'Húngaro',
        de: 'Ungarisch',
        fr: 'Hongrois',
        it: 'Ungherese',
      },
    },
    hungarian_qwerty: {
      primaryLanguage: 'hu',
      title: {
        en: 'Hungarian — QWERTY',
        ru: 'Венгерская — QWERTY',
        es: 'Húngaro - QWERTY',
        de: 'Ungarisch - QWERTY',
        fr: 'Hongrois - QWERTY',
        it: 'Ungherese - QWERTY',
      },
    },

    // romanian
    romanian: {
      primaryLanguage: 'ro',
      title: {
        en: 'Romanian',
        ru: 'Румынская',
        es: 'Rumano',
        de: 'Rumänisch',
        fr: 'Roumain',
        it: 'Rumeno',
      },
    },
    romanian_standard: {
      primaryLanguage: 'ro',
      title: {
        en: 'Romanian — Standard',
        ru: 'Румынская — стандартная',
        es: 'Rumano - Estándar',
        de: 'Rumänisch - Standard',
        fr: 'Roumain - Standard',
        it: 'Rumeno - Standard',
      },
    },
  },

  // Windows
  win: {
    // english
    us: {
      primaryLanguage: 'en',
      title: {
        en: 'US',
        ru: 'США',
        es: 'EEUU',
        de: 'US',
        fr: 'QWERTY US',
        it: 'USA',
      },
    },
    english_indian: {
      primaryLanguage: 'en',
      title: {
        en: 'English (India)',
        ru: 'Английский (Индия)',
        es: 'Inglés (India)',
        de: 'Englisch (Indien)',
        fr: 'Anglais (Inde)',
        it: 'Inglese (India)',
      },
    },
    english_uk: {
      primaryLanguage: 'en',
      title: {
        en: 'United Kingdom',
        ru: 'Великобритания',
        es: 'Reino Unido',
        de: 'Vereinigtes Königreich',
        fr: 'Royaume-Uni',
        it: 'Regno Unito',
      },
    },
    english_uk_extended: {
      primaryLanguage: 'en',
      title: {
        en: 'United Kingdom Extended',
        ru: 'Великобритания расширенная',
        es: 'Reino Unido extendido',
        de: 'Vereinigtes Königreich erweitert',
        fr: 'Royaume-Uni étendu',
        it: 'Regno Unito esteso',
      },
    },
    irish: {
      primaryLanguage: 'en',
      title: {
        en: 'Irish',
        ru: 'Ирландская',
        es: 'Irlandés',
        de: 'Irisch',
        fr: 'Irlandais',
        it: 'Irlandese',
      },
    },
    canadian_multilingual_standard: {
      primaryLanguage: 'en',
      shortcutsVariant: ShortcutVariant.German,
      title: {
        en: 'Canadian Multilingual Standard',
        ru: 'Канадская многоязычная стандартая',
        es: 'Canadiense multilingüe estándar',
        de: 'Kanadisch mehrsprachig Standard',
        fr: 'Canadien multilingue standard',
        it: 'Canadese multilingue standard',
      },
    },
    us_international: {
      primaryLanguage: 'en',
      title: {
        en: 'United States-International',
        ru: 'США международная',
        es: 'Estados Unidos-Internacional',
        de: 'USA-International',
        fr: 'États-Unis-International',
        it: 'Stati Uniti-Internazionale',
      },
    },
    us_dvorak: {
      primaryLanguage: 'en',
      title: {
        en: 'United States-Dvorak',
        ru: 'США-Дворак',
        es: 'Estados Unidos-Dvorak',
        de: 'USA-Dvorak',
        fr: 'États-Unis-Dvorak',
        it: 'Stati Uniti-Dvorak',
      },
    },

    // spanish
    spanish: {
      primaryLanguage: 'es',
      title: {
        en: 'Spanish',
        ru: 'Испанская',
        es: 'Español',
        de: 'Spanisch',
        fr: 'Espagnol',
        it: 'Spagnolo',
      },
    },
    spanish_variation: {
      primaryLanguage: 'es',
      title: {
        en: 'Spanish Variation',
        ru: 'Испанская — Вариант',
        es: 'Español variación',
        de: 'Spanisch Variation',
        fr: 'Espagnol variante',
        it: 'Spagnolo variante',
      },
    },
    latin_american: {
      primaryLanguage: 'es',
      // good for shortcut hint shortcut, but otherwise needs its own variant
      shortcutsVariant: ShortcutVariant.German,
      title: {
        en: 'Latin American',
        ru: 'Латиноамериканская',
        es: 'Latinoamericano',
        de: 'Lateinamerikanisch',
        fr: 'Amérique latine',
        it: 'America Latina',
      },
    },

    // german
    german: {
      primaryLanguage: 'de',
      shortcutsVariant: ShortcutVariant.German,
      title: {
        en: 'German',
        ru: 'Немецкая',
        es: 'Alemán',
        de: 'Deutsch',
        fr: 'Allemand',
        it: 'Tedesco',
      },
    },
    german_ibm: {
      primaryLanguage: 'de',
      shortcutsVariant: ShortcutVariant.German,
      title: {
        en: 'German (IBM)',
        ru: 'Немецкая (IBM)',
        es: 'Alemán (IBM)',
        de: 'Deutsch (IBM)',
        fr: 'Allemand (IBM)',
        it: 'Tedesco (IBM)',
      },
    },
    swiss_german: {
      primaryLanguage: 'de',
      shortcutsVariant: ShortcutVariant.German,
      title: {
        en: 'Swiss German',
        ru: 'Швейцарская немецкая',
        es: 'Alemán suizo',
        de: 'Schweizerdeutsch',
        fr: 'Suisse allemand',
        it: 'Tedesco svizzero',
      },
    },

    // russian
    russian: {
      primaryLanguage: 'ru',
      shortcutsLayout: 'us',
      title: {
        en: 'Russian',
        ru: 'Русская',
        es: 'Ruso',
        de: 'Russisch',
        fr: 'Russe',
        it: 'Russo',
      },
    },
    russian_mnemonic: {
      primaryLanguage: 'ru',
      shortcutsLayout: 'us',
      title: {
        en: 'Russian Mnemonic',
        ru: 'Русская мнемоническая',
        es: 'Ruso mnemotécnico',
        de: 'Russisch mnemotechnisch',
        fr: 'Russe mnémotechnique',
        it: 'Russo mnemonico',
      },
    },
    russian_typewriter: {
      primaryLanguage: 'ru',
      shortcutsLayout: 'us',
      title: {
        en: 'Russian Typewriter',
        ru: 'Русская пишущая машинка',
        es: 'Ruso máquina de escribir',
        de: 'Russisch Schreibmaschine',
        fr: 'Russe machine à écrire',
        it: 'Russo macchina da scrivere',
      },
    },

    // french
    french: {
      primaryLanguage: 'fr',
      title: {
        en: 'French',
        ru: 'Французская',
        es: 'Francés',
        de: 'Französisch',
        fr: 'Français',
        it: 'Francese',
      },
    },
    swiss_french: {
      primaryLanguage: 'fr',
      title: {
        en: 'Swiss French',
        ru: 'Швейцарская французская',
        es: 'Francés suizo',
        de: 'Schweizer Französisch',
        fr: 'Suisse français',
        it: 'Francese svizzero',
      },
    },
    canadian_french: {
      primaryLanguage: 'fr',
      title: {
        en: 'Canadian French',
        ru: 'Канадская французская',
        es: 'Francés canadiense',
        de: 'Kanadisches Französisch',
        fr: 'Français canadien',
        it: 'Francese canadese',
      },
    },
    canadian_french_legacy: {
      primaryLanguage: 'fr',
      title: {
        en: 'Canadian French (Legacy)',
        ru: 'Канадская французская (традиционная)',
        es: 'Francés canadiense (heredado)',
        de: 'Kanadisches Französisch (Legacy)',
        fr: 'Français canadien (hérité)',
        it: 'Francese canadese (legacy)',
      },
    },

    // portuguese
    portuguese: {
      primaryLanguage: 'pt',
      title: {
        en: 'Portuguese',
        ru: 'Португальская',
        es: 'Portugués',
        de: 'Portugiesisch',
        fr: 'Portugais',
        it: 'Portoghese',
      },
    },
    portuguese_brazil_abnt: {
      primaryLanguage: 'pt',
      title: {
        en: 'Portuguese (Brazil ABNT)',
        ru: 'Португальская (Бразилия ABNT)',
        es: 'Portugués (Brasil ABNT)',
        de: 'Portugiesisch (Brasilien ABNT)',
        fr: 'Portugais (Brésil ABNT)',
        it: 'Portoghese (Brasile ABNT)',
      },
    },

    // italian
    italian: {
      primaryLanguage: 'it',
      title: {
        en: 'Italian',
        ru: 'Итальянская',
        es: 'Italiano',
        de: 'Italienisch',
        fr: 'Italien',
        it: 'Italiano',
      },
    },
    italian_142: {
      primaryLanguage: 'it',
      title: {
        en: 'Italian (142)',
        ru: 'Итальянская (142)',
        es: 'Italiano (142)',
        de: 'Italienisch (142)',
        fr: 'Italien (142)',
        it: 'Italiano (142)',
      },
    },

    // polish
    polish_programmers: {
      primaryLanguage: 'pl',
      title: {
        en: 'Polish (Programmers)',
        ru: 'Польская (Программисты)',
        es: 'Polaco (Programadores)',
        de: 'Polnisch (Programmierer)',
        fr: 'Polonais (Programmeurs)',
        it: 'Polacco (Programmatori)',
      },
    },
    polish_214: {
      primaryLanguage: 'pl',
      title: {
        en: 'Polish (214)',
        ru: 'Польская (214)',
        es: 'Polaco (214)',
        de: 'Polnisch (214)',
        fr: 'Polonais (214)',
        it: 'Polacco (214)',
      },
    },

    // ukrainian
    ukrainian: {
      primaryLanguage: 'uk',
      title: {
        en: 'Ukrainian',
        ru: 'Украинская',
        es: 'Ucraniano',
        de: 'Ukrainisch',
        fr: 'Ukrainien',
        it: 'Ucraino',
      },
    },
    ukrainian_enhanced: {
      primaryLanguage: 'uk',
      title: {
        en: 'Ukrainian (Enhanced)',
        ru: 'Украинская (Расширенная)',
        es: 'Ucraniano (Mejorado)',
        de: 'Ukrainisch (Erweitert)',
        fr: 'Ukrainien (Amélioré)',
        it: 'Ucraino (Migliorato)',
      },
    },

    // turkish
    turkish_q: {
      primaryLanguage: 'tr',
      title: {
        en: 'Turkish Q',
        ru: 'Турецкая Q',
        es: 'Turco Q',
        de: 'Türkisch Q',
        fr: 'Turc Q',
        it: 'Turco Q',
      },
    },
    turkish_f: {
      primaryLanguage: 'tr',
      title: {
        en: 'Turkish F',
        ru: 'Турецкая F',
        es: 'Turco F',
        de: 'Türkisch F',
        fr: 'Turc F',
        it: 'Turco F',
      },
    },

    // dutch
    dutch: {
      primaryLanguage: 'nl',
      title: {
        en: 'Dutch',
        ru: 'Голландская',
        es: 'Neerlandés',
        de: 'Niederländisch',
        fr: 'Néerlandais',
        it: 'Olandese',
      },
    },
    belgian: {
      primaryLanguage: 'nl',
      title: {
        en: 'Belgian',
        ru: 'Бельгийская',
        es: 'Belga',
        de: 'Belgisch',
        fr: 'Belge',
        it: 'Belga',
      },
    },

    // swedish
    swedish: {
      primaryLanguage: 'sv',
      title: {
        en: 'Swedish',
        ru: 'Шведская',
        es: 'Sueco',
        de: 'Schwedisch',
        fr: 'Suédois',
        it: 'Svedese',
      },
    },

    // hungarian
    hungarian: {
      primaryLanguage: 'hu',
      title: {
        en: 'Hungarian',
        ru: 'Венгерская',
        es: 'Húngaro',
        de: 'Ungarisch',
        fr: 'Hongrois',
        it: 'Ungherese',
      },
    },
    hungarian_101_key: {
      primaryLanguage: 'hu',
      title: {
        en: 'Hungarian 101-key',
        ru: 'Венгерская 101-клавиша',
        es: 'Húngaro 101 teclas',
        de: 'Ungarisch 101-Tasten',
        fr: 'Hongrois 101 touches',
        it: 'Ungherese 101 tasti',
      },
    },

    // czech
    czech: {
      primaryLanguage: 'cs',
      title: {
        en: 'Czech',
        ru: 'Чешская',
        es: 'Checo',
        de: 'Tschechisch',
        fr: 'Tchèque',
        it: 'Ceco',
      },
    },
    czech_qwerty: {
      primaryLanguage: 'cs',
      title: {
        en: 'Czech (QWERTY)',
        ru: 'Чешская (QWERTY)',
        es: 'Checo (QWERTY)',
        de: 'Tschechisch (QWERTY)',
        fr: 'Tchèque (QWERTY)',
        it: 'Ceco (QWERTY)',
      },
    },
    czech_programmers: {
      primaryLanguage: 'cs',
      title: {
        en: 'Czech Programmers',
        ru: 'Чешская (Программисты)',
        es: 'Checo para programadores',
        de: 'Tschechisch für Programmierer',
        fr: 'Tchèque pour programmeurs',
        it: 'Ceco per programmatori',
      },
    },

    // danish
    danish: {
      primaryLanguage: 'da',
      title: {
        en: 'Danish',
        ru: 'Датская',
        es: 'Danés',
        de: 'Dänisch',
        fr: 'Danois',
        it: 'Danese',
      },
    },

    // romanian
    romanian_standard: {
      primaryLanguage: 'ro',
      title: {
        en: 'Romanian (Standard)',
        ru: 'Румынская (Стандартная)',
        es: 'Rumano (Estándar)',
        de: 'Rumänisch (Standard)',
        fr: 'Roumain (Standard)',
        it: 'Rumeno (Standard)',
      },
    },
    romanian_programmers: {
      primaryLanguage: 'ro',
      title: {
        en: 'Romanian (Programmers)',
        ru: 'Румынская (Программисты)',
        es: 'Rumano (Programadores)',
        de: 'Rumänisch (Programmierer)',
        fr: 'Roumain (Programmeurs)',
        it: 'Rumeno (Programmatori)',
      },
    },
    romanian_legacy: {
      primaryLanguage: 'ro',
      title: {
        en: 'Romanian (Legacy)',
        ru: 'Румынская (наследие)',
        es: 'Rumano (Heredado)',
        de: 'Rumänisch (Legacy)',
        fr: 'Roumain (Hérité)',
        it: 'Rumeno (Legacy)',
      },
    },
  },

  // Linux
  lnx: {
    // english
    english_us: {
      primaryLanguage: 'en',
      title: {
        en: 'English (US)',
        ru: 'Английская (США)',
        es: 'Inglés (EE. UU.)',
        de: 'Englisch (USA)',
        fr: 'Anglais (États-Unis)',
        it: 'Inglese (Stati Uniti)',
      },
    },
    english_uk: {
      primaryLanguage: 'en',
      title: {
        en: 'English (UK)',
        ru: 'Английская (Великобритания)',
        es: 'Inglés (Reino Unido)',
        de: 'Englisch (Großbritannien)',
        fr: 'Anglais (Royaume-Uni)',
        it: 'Inglese (Regno Unito)',
      },
    },
    // russian
    russian: {
      primaryLanguage: 'ru',
      shortcutsLayout: 'english_us',
      title: {
        en: 'Russian',
        ru: 'Русская',
        es: 'Ruso',
        de: 'Russisch',
        fr: 'Russe',
        it: 'Russo',
      },
    },
    // german
    german: {
      primaryLanguage: 'de',
      shortcutsVariant: ShortcutVariant.German,
      title: {
        en: 'German',
        ru: 'Немецкая',
        es: 'Alemán',
        de: 'Deutsch',
        fr: 'Allemand',
        it: 'Tedesco',
      },
    },
  },
}

type LayoutMetadata = {
  layoutId: string
  title: string
  shortcutsLayout?: string
  shortcutsVariant?: ShortcutVariant
}

export const getLayoutsMetadata = (os: OS, locale: string = 'en') => {
  const targetOsLayouts = layoutsConfig[os]

  return Object.fromEntries(
    Object.entries(targetOsLayouts).map(([layoutId, layoutMetadata]) => {
      return [
        layoutId,
        {
          layoutId,
          title: layoutMetadata.title[locale as UserLocale] ?? layoutMetadata.title.en,
          shortcutsLayout: layoutMetadata.shortcutsLayout,
          shortcutsVariant: layoutMetadata.shortcutsVariant,
        } as LayoutMetadata,
      ]
    }),
  )
}

// export const getLayoutTitle = (os: OS, layoutId: string, locale: LanguageCode) => layoutsConfig[os][layoutId].title[locale]
