<script setup lang="ts">
import { logAnalyticsEvent } from '@/helpers/analytics'
import { useRouter } from 'vue-router'

const router = useRouter()

const finishPayment = async () => {
  logAnalyticsEvent('payment_cancel', {})
  router.replace({ name: 'home' })
}

finishPayment()
</script>

<template>
  <main class="container"></main>
</template>

<style lang="scss" scoped>
.container {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
