import { PunctuationMarkType, type LanguageMetadata } from '../languages-config'
import words10k from './hu_10k.json'
import ngrams from './hu_ngrams.json'

// prettier-ignore
// (printWidth)
export const huMetadata: LanguageMetadata = {
  code: 'hu',
  title: 'Hungarian',
  emoji: '🇭🇺',

  lowerLetters: 'aábcdeéfghiíjklmnoóöőpqrstuúüűvwxyz',
  upperLetters: 'AÁBCDEÉFGHIÍJKLMNOÓÖŐPQRSTUÚÜŰVWXYZ',
  nonEssentialLetters: '',

  punctuation: [
    {
      char: '-',
      type: PunctuationMarkType.Hyphen,
    },
    // {
    //   char: '—',
    //   type: PunctuationMarkType.Dash,
    // },
    {
      char: ',',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '.',
      type: PunctuationMarkType.End,
    },
    {
      char: '?',
      type: PunctuationMarkType.End,
    },
    {
      char: '!',
      type: PunctuationMarkType.End,
    },
    {
      char: ':',
      type: PunctuationMarkType.Comma,
    },
    {
      char: ';',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '()',
      type: PunctuationMarkType.Bracket,
    },
    {
      char: '\'',
      type: PunctuationMarkType.Bracket,
    },
    {
      char: '"',
      type: PunctuationMarkType.Bracket,
    },
  ],
  
  dicts: {
    words10k,
    ngrams,
  }
}
