// Import the functions you need from the SDKs you need
import { getAnalytics, isSupported } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

// TODO Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAFVPNqLHuLEXLh44lrkb5idjbCRWm1YKs',
  authDomain: 'typing.school',
  projectId: 'typing-school-app',
  storageBucket: 'typing-school-app.appspot.com',
  messagingSenderId: '859128345357',
  appId: '1:859128345357:web:e532e2caf21df653b03644',
  measurementId: 'G-L9QP7NGY7N',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
const analytics = isSupported().then((yes) => (yes ? getAnalytics(app) : null))
