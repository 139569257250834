<script setup lang="ts">
import { ACC_COLORS, greenAccuracyIsMoreThan, yellowAccuracyIsEqualOrMoreThan } from '@/helpers/metric-color-scales'

type Props = {
  value: number
}
defineProps<Props>()

const [red, yellow, green] = ACC_COLORS()
</script>

<template>
  <div class="colors">
    <div class="color-label" :class="{ current: value < yellowAccuracyIsEqualOrMoreThan }">
      <div class="color red"></div>
      <span>&lt;{{ yellowAccuracyIsEqualOrMoreThan }}%</span>
    </div>
    <div class="color-label" :class="{ current: value >= yellowAccuracyIsEqualOrMoreThan && value <= greenAccuracyIsMoreThan }">
      <div class="color yellow"></div>
      <span>{{ yellowAccuracyIsEqualOrMoreThan }}-{{ greenAccuracyIsMoreThan }}%</span>
    </div>
    <div class="color-label" :class="{ current: value > greenAccuracyIsMoreThan }">
      <div class="color green"></div>
      <span>&gt;{{ greenAccuracyIsMoreThan }}%</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.colors {
  display: flex;
  gap: 0.8em;

  .color-label {
    border-radius: 0.4em;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 0.4em;
    padding: 0.1em 0.4em;
    margin: -0.1em -0.4em;

    &.current {
      background-color: var(--c-secondary-active);
      span {
        color: var(--c-text-secondary);
      }
    }

    span {
      color: var(--c-text-tertiary);
    }
  }

  .color {
    width: 0.8em;
    height: 0.8em;
    border-radius: 100%;
  }

  .red {
    background-color: v-bind(red);
  }
  .yellow {
    background-color: v-bind(yellow);
  }
  .green {
    background-color: v-bind(green);
  }
}
</style>
