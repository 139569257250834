<script setup lang="ts">
import { logAnalyticsEvent } from '@/helpers/analytics'
import { cloudFunctionUrl } from '@/helpers/main-utils'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const appStore = useAppStore()

const finishPayment = async () => {
  appStore.showLoader('finishPaymentRequest', t('Home.updatingYourAccess'), true)

  const paymentId = route.params.paymentId
  if (!paymentId || !userStore.authData) {
    router.replace({ name: 'home' })
  }

  try {
    logAnalyticsEvent('payment_success', {}, { send_immediately: true })
    await axios.post(cloudFunctionUrl('paymentSuccess'), { email: userStore.authData!.email, paymentId })
    await axios.post(cloudFunctionUrl('notifyAboutPayment'), {
      email: userStore.authData?.email,
    })
  } catch (e) {}

  appStore.hideLoader('finishPaymentRequest')

  router.replace({ name: 'home' }).then(() => {
    window.location.reload()
  })
}

finishPayment()
</script>

<template></template>

<style lang="scss" scoped></style>
