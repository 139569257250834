import { Char, KeyChar } from '@/helpers/keyboards/KeyChar'
import type { TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import type { KeyboardFormat } from '@/helpers/keyboards/KeyboardLayout'
import type { LayersMap } from '@/helpers/keyboards/Layer'
import { Layer } from '@/helpers/keyboards/Layer'
import type { OS } from '@/types/main-types'
import { cloneDeep } from 'lodash-es'
import allLayoutsConfig from './all-layouts-config.json'

type KeyConfig = Partial<Record<KeyboardFormat, LayersMap<KeyChar>>>
export type LayoutConfig = Partial<Record<TypeableKeyCode, KeyConfig>>

const keycodeAbbreviations: Record<string, TypeableKeyCode> = {
  BQ: 'Backquote',
  D1: 'Digit1',
  D2: 'Digit2',
  D3: 'Digit3',
  D4: 'Digit4',
  D5: 'Digit5',
  D6: 'Digit6',
  D7: 'Digit7',
  D8: 'Digit8',
  D9: 'Digit9',
  D0: 'Digit0',
  MS: 'Minus',
  EQ: 'Equal',
  KQ: 'KeyQ',
  KW: 'KeyW',
  KE: 'KeyE',
  KR: 'KeyR',
  KT: 'KeyT',
  KY: 'KeyY',
  KU: 'KeyU',
  KI: 'KeyI',
  KO: 'KeyO',
  KP: 'KeyP',
  BL: 'BracketLeft',
  BR: 'BracketRight',
  BS: 'Backslash',
  KA: 'KeyA',
  KS: 'KeyS',
  KD: 'KeyD',
  KF: 'KeyF',
  KG: 'KeyG',
  KH: 'KeyH',
  KJ: 'KeyJ',
  KK: 'KeyK',
  KL: 'KeyL',
  SC: 'Semicolon',
  QT: 'Quote',
  IB: 'IntlBackslash',
  KZ: 'KeyZ',
  KX: 'KeyX',
  KC: 'KeyC',
  KV: 'KeyV',
  KB: 'KeyB',
  KN: 'KeyN',
  KM: 'KeyM',
  CM: 'Comma',
  PR: 'Period',
  SL: 'Slash',
}

const getChar = (raw: string) => {
  if (raw.startsWith('(') && raw.endsWith(')')) {
    return Char.ligature(raw.slice(1, -1))
  }
  return new Char(raw)
}

const parseFormatConfig = (strDefault: string, strCaps?: string): LayersMap<KeyChar> => {
  strDefault = strDefault.trim()
  strCaps = strCaps?.trim()

  const parseDefinitions = (stateDefinitions: string | undefined): KeyChar[] => {
    if (stateDefinitions === undefined) {
      return [KeyChar.Nothing(), KeyChar.Nothing(), KeyChar.Nothing(), KeyChar.Nothing()]
    }
    let result: KeyChar[] = stateDefinitions
      .split(' ')
      .map((v) => v.trim())
      .map((v) => {
        if (v === 'null') return KeyChar.Nothing()
        if (v === 'space') return KeyChar.Space()
        if (v.startsWith('[') && v.endsWith(']')) return new KeyChar(new Char(v), [[], []]) // to replace later
        return new KeyChar(getChar(v))
      })
    while (result.length < 4) result.push(KeyChar.Nothing())
    return result
  }

  let definitions = [...parseDefinitions(strDefault)]
  if (strCaps === undefined) {
    definitions.push(...cloneDeep(definitions))
  } else {
    definitions.push(...parseDefinitions(strCaps))
  }

  return {
    [Layer.Default]: definitions[0],
    [Layer.Shift]: definitions[1],
    [Layer.Option]: definitions[2],
    [Layer.ShiftOption]: definitions[3],
    [Layer.CapsDefault]: definitions[4],
    [Layer.CapsShift]: definitions[5],
    [Layer.CapsOption]: definitions[6],
    [Layer.CapsShiftOption]: definitions[7],
  }
}

export const parseTxtLayoutConfig = (os: OS, layoutId: string): LayoutConfig => {
  // @ts-expect-error
  const txt = allLayoutsConfig[os][layoutId]
  const rows = txt.split('\n')

  let keymap: LayoutConfig = {}
  let deadKeys: Record<string, { from: Char[]; to: Char[] }> = {}

  let rowIndex = 1
  while (rowIndex < rows.length) {
    let row = rows[rowIndex]

    if (!row.trim() || row.startsWith(' ')) {
      rowIndex++
      continue
    }

    row = row.trim()

    if (row.startsWith('[')) {
      // dead key mapping definition
      const from = rows[rowIndex + 1].trim().split(' ').map(getChar)
      const to = rows[rowIndex + 2].trim().split(' ').map(getChar)

      if (to.length > from.length) {
        // space transformation
        from.push(new Char(' '))
      }

      deadKeys[row.trim()] = {
        // value: deadValue,
        from,
        to,
      }

      rowIndex += 3
      continue
    }

    // stage 1 - Key codes

    let parsedKeyConfig: Record<string, any> = {}

    const keycodeAbbr = row.slice(0, 2)
    const formatConfigsDefault = row.slice(2).split('ISO')

    const formatConfigsCaps = rows[rowIndex + 1]?.startsWith(' ') ? rows[rowIndex + 1].split('ISO') : null

    const defaultFormatConfig = formatConfigsDefault[0].trim()
    parsedKeyConfig.ans = parseFormatConfig(defaultFormatConfig, formatConfigsCaps?.[0])
    if (formatConfigsDefault[1]) {
      parsedKeyConfig.iso = parseFormatConfig(formatConfigsDefault[1].trim(), formatConfigsCaps?.[1])
    }

    // @ts-expect-error
    keymap[keycodeAbbr] = parsedKeyConfig
    rowIndex++
  }

  // TODO: propagate dead key modfifcations

  for (const keycode in keymap) {
    // @ts-expect-error
    for (const format in keymap[keycode]) {
      // @ts-expect-error
      for (const layer in keymap[keycode][format]) {
        // @ts-expect-error
        const keyChar = keymap[keycode][format][layer] as KeyChar
        if (keyChar.isDead()) {
          if (deadKeys[keyChar.value]) {
            keyChar.deadModifications = [deadKeys[keyChar.value].from, deadKeys[keyChar.value].to]
          }
          keyChar.char = getChar(keyChar.value.slice(1, -1)) // unwrap [?] to ?; could also be ligature
        }
      }
    }
  }

  const result = Object.fromEntries(Object.entries(keymap).map(([abbr, value]) => [keycodeAbbreviations[abbr], value]))
  return result
}

export const getByKeyboardFormat = (map: Partial<Record<KeyboardFormat, any>>, format: KeyboardFormat) => {
  // NOTE: current logic for config
  // - if no data for 'ans' -> ans doesn't have this key
  // - if no data for 'iso' -> it uses ans config

  if (format in map) {
    return map[format]
  } else if ('ans' in map) {
    return map.ans
  }
  return null
}
