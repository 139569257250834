import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRouter, type RouteLocationRaw } from 'vue-router'

export const useRouterStore = defineStore('router', () => {
  const router = useRouter()

  const nextRoute = ref<RouteLocationRaw | null>(null)

  const goNextOr = (defaultRoute: RouteLocationRaw) => {
    let route = nextRoute.value || defaultRoute
    if (nextRoute.value) {
      nextRoute.value = null
    }
    router.push(route)
  }

  const saveCurrentRouteAndGo = (route: RouteLocationRaw) => {
    nextRoute.value = { ...router.currentRoute.value }
    router.push(route)
  }

  return {
    nextRoute,
    goNextOr,
    saveCurrentRouteAndGo,
  }
})
