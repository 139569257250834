<script setup lang="ts">
import { cloudFunctionUrl } from '@/helpers/main-utils'
import { addFeedback, type Feedback } from '@/plugins/firebase/firestore'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'
import axios from 'axios'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from './Button.vue'

const { t, locale } = useI18n()
const appStore = useAppStore()
const userStore = useUserStore()

const emit = defineEmits(['close'])

const feedback = ref<Feedback>({
  rating: 0,
  age: '',
  occupation: '',
  good: '',
  bad: '',
  videoSession: false,
  contact: userStore.authData?.email ?? '',
  currentLocale: locale.value,
})

const ageRanges = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+']

const isLoading = ref(false)

const hideForm = () => {
  emit('close')
}

const submitForm = async () => {
  addFeedback(feedback.value)

  isLoading.value = true
  await axios.post(cloudFunctionUrl('feedback'), {
    feedback: feedback.value,
  })
  isLoading.value = false

  appStore.showToast('top', { text: t('FeedbackForm.thankYouToast'), emoji: 'raising-hands' })
  hideForm()
}

const isInvalid = computed(() => {
  return feedback.value.rating === 0 || !feedback.value.age || (feedback.value.videoSession && !feedback.value.contact)
})
</script>

<template>
  <div class="wrapper">
    <button class="close" @click="hideForm">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1.5L11 11.5M11 1.5L1 11.5" stroke="var(--c-text-secondary)" stroke-width="1.2" stroke-linecap="round" />
      </svg>
    </button>
    <div class="form">
      <div class="title">
        <svg class="icon">
          <use href="#ts-favicon"></use>
        </svg>
        <span>{{ t('FeedbackForm.title') }}</span>
      </div>

      <div class="field rating">
        <label class="required" for="form-rating">{{ t('FeedbackForm.ratingLabel') }}</label>
        <div ref="radios" class="radios">
          <label v-for="value of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :data-value="value" :class="feedback.rating == value ? 'checked' : ''">
            <input type="radio" name="rating" @input="feedback.rating = value" />
          </label>
        </div>
      </div>

      <div class="field age">
        <label class="required" for="form-age">{{ t('FeedbackForm.yourAge') }}</label>
        <div class="chips">
          <div class="chip" v-for="value of ageRanges" :class="feedback.age === value ? 'checked' : ''" @click="feedback.age = value">
            {{ value }}
          </div>
        </div>
      </div>

      <div class="field profession">
        <label for="form-profession">{{ t('FeedbackForm.occupation') }}</label>
        <input
          v-model="feedback.occupation"
          type="text"
          name="profession"
          id="form-profession"
          :placeholder="t('FeedbackForm.occupationPlaceholder')"
        />
      </div>

      <div class="field textarea">
        <label for="form-good">{{ t('FeedbackForm.goodLabel') }}</label>
        <textarea
          @keydown.stop
          rows="3"
          name="good"
          id="form-good"
          :placeholder="t('FeedbackForm.goodPlaceholder')"
          @input="(e) => (feedback.good = (<HTMLTextAreaElement>e.target).value)"
        ></textarea>
      </div>

      <div class="field textarea">
        <label for="form-bad">{{ t('FeedbackForm.badLabel') }}</label>
        <textarea
          @keydown.stop
          rows="3"
          name="bad"
          id="form-bad"
          :placeholder="t('FeedbackForm.badPlaceholder')"
          @input="(e) => (feedback.bad = (<HTMLTextAreaElement>e.target).value)"
        ></textarea>
      </div>

      <div class="field">
        <div class="title-video-session">
          {{ t('FeedbackForm.videoSessionTitle') }}
        </div>

        <div class="checkbox">
          <input
            type="checkbox"
            name="video-session"
            id="form-video-session"
            @change="(e) => (feedback.videoSession = (<HTMLInputElement>e.target).checked)"
          />
          <label for="form-video-session">{{ t('FeedbackForm.videoSessionLabel') }}</label>
        </div>

        <div v-if="feedback.videoSession" class="field contacts">
          <label for="form-contacts">{{ t('FeedbackForm.contactsLabel') }}</label>
          <input v-model="feedback.contact" type="text" name="email" id="form-contacts" :placeholder="t('FeedbackForm.contactsPlaceholder')" />
        </div>
      </div>

      <Button @click="submitForm" :loading="isLoading" :disabled="isInvalid">{{ t('FeedbackForm.submitButton') }}</Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dark .wrapper {
  background-image: url('/src/assets/img/keyboard-glassy-half/keyboard-glassy-half_dark.svg');
}
.wrapper {
  position: relative;
  width: 100%;
  padding: var(--s-xl);
  border-radius: var(--br-xl);
  border: 1px solid var(--c-divider);
  background-color: var(--c-surface);
  background-image: url('/src/assets/img/keyboard-glassy-half/keyboard-glassy-half_light.svg');
  background-position: right 150px;
  background-repeat: no-repeat;
  font-weight: 400;

  button.close {
    outline: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    background: none;
    border: none;
    cursor: pointer;
    &:hover,
    &:focus-visible {
      path {
        stroke: var(--c-danger-text);
      }
    }
  }

  .title {
    position: relative;
    font-size: var(--fz-md);
    padding-block: 5px;
    margin-bottom: var(--s-md);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: var(--s-md);

    span {
      display: inline-block;
      margin-top: 2px;
    }

    .icon {
      width: 32px;
      height: 32px;
      color: var(--c-primary);
      --custom-text-color: var(--c-text-on-primary);
    }
  }

  .form {
    --max-width: 440px;
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    gap: var(--s-lg);
    font-size: var(--fz-sm);

    .field {
      label {
        display: block;
        margin-bottom: var(--s-sm);
        &.required::after {
          content: ' *';
          color: var(--c-danger-text);
        }
      }
    }

    .rating,
    .contacts {
      display: flex;
      flex-direction: column;
    }

    .contacts {
      padding-left: 30px;
    }

    .textarea {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;

      textarea {
        min-height: 80px;
      }

      &::after {
        content: '';
        width: 7px;
        height: 7px;
        position: absolute;
        bottom: 3px;
        right: 3px;
        border-bottom-right-radius: 6px;
        border-right: 1px solid var(--c-secondary-border);
        border-bottom: 1px solid var(--c-secondary-border);
        pointer-events: none;
      }
    }

    .title-video-session {
      margin-bottom: var(--s-md);
    }

    .checkbox {
      display: flex;

      input[type='checkbox'] {
        appearance: none;
        -webkit-appearance: none;

        &:focus-visible + label::before {
          border-color: var(--c-primary-border);
        }
      }

      label {
        padding-left: 28px;
        padding-block: 1px;
        position: relative;
        cursor: pointer;
        font-weight: 500;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          border-radius: var(--br-sm);
          border: 1px solid var(--c-secondary-border);
          background-color: var(--c-background);
          background-position: center;
          background-repeat: no-repeat;
          transition: 200ms;
        }
        &:hover::before {
          border-color: var(--c-secondary-border-darker);
        }
      }
      input[type='checkbox']:checked:focus-visible + label::before {
        border-color: var(--c-text-primary);
      }
      input[type='checkbox']:checked + label {
        &::before {
          border: 1px solid var(--c-primary-border);
          background-color: var(--c-primary);
          background-image: url('/src/assets/img/check.svg');
        }
        &:hover::before {
          background-color: var(--c-primary-hover);
        }
      }
    }

    .radios {
      display: flex;
      border-radius: var(--br-lg);
      border: 1px solid transparent;

      &:has(input[type='radio']:focus-visible) {
        border-color: var(--c-primary-border);
      }
      label {
        margin: unset;
        position: relative;
        width: calc(var(--max-width) / 10);
        padding: var(--s-sm) 0;
        background-color: var(--c-background);
        display: block;
        border-right: 1px solid var(--c-divider);
        border-top: 1px solid var(--c-divider);
        border-bottom: 1px solid var(--c-divider);
        cursor: pointer;
        transition: 200ms;
        &::before {
          content: attr(data-value);
          position: absolute;
          left: 50%;
          top: 50%;
          translate: -50% -50%;
        }
        &:first-child {
          border-radius: var(--br-lg) 0 0 var(--br-lg);
          border-left: 1px solid var(--c-divider);
        }
        &:last-child {
          border-radius: 0 var(--br-lg) var(--br-lg) 0;
        }
        &:hover {
          background-color: var(--c-surface);
        }
        &.checked {
          background-color: var(--c-primary);
          color: var(--c-text-on-primary);
        }
      }
      input[type='radio'] {
        appearance: none;
        -webkit-appearance: none;
      }
    }

    .chips {
      display: flex;
      flex-wrap: wrap;
      gap: var(--s-xs);

      .chip {
        border: 1px solid var(--c-divider);
        border-radius: var(--br-lg);
        padding: var(--s-sm) 0;
        flex: 1;
        text-align: center;
        background-color: var(--c-background);
        cursor: pointer;
        &:hover {
          background-color: var(--c-surface);
        }
        &.checked {
          background-color: var(--c-primary);
          color: var(--c-text-on-primary);
        }
      }
    }

    textarea,
    input[type='text'] {
      width: 100%;
      outline: none;
      border: 1px solid var(--c-secondary-border);
      border-radius: var(--br-lg);
      background-color: var(--c-background);
      padding: var(--s-md);
      color: var(--c-text-primary);
      font-size: inherit;
      font-family: inherit;
      transition: border-color 200ms;
      &::placeholder {
        opacity: 50%;
      }
      &:hover {
        border-color: var(--c-secondary-border-darker);
      }
      &:focus-visible {
        border-color: var(--c-primary-border);
      }
    }

    textarea {
      resize: vertical;
      &::-webkit-resizer {
        appearance: none;
      }
    }

    button {
      margin-top: var(--s-md);
      border-radius: var(--br-lg);
      height: 42px;
    }
    button:focus-visible {
      border-color: var(--c-text-primary);
    }
  }
}
</style>
