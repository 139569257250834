import type { LocalizedText } from '@/plugins/i18n'

export type TipData = {
  text: LocalizedText
  emoji: string[]
}

export const tipToTextMapping = {
  // home row
  doNotLook: {
    text: {
      en: "For effective training, it's crucial to avoid looking at your physical keyboard. If you find this challenging, try covering it with something.",
      ru: 'Чтобы тренировки давали эффект, очень важно не смотреть на физическую клавиатуру. Если это сложно, попробуйте чем-нибудь её накрыть.',
      es: 'Para que el entrenamiento sea efectivo, es crucial evitar mirar el teclado físico. Si te resulta difícil, intenta cubrirlo con algo.',
      de: 'Für ein effektives Training ist es entscheidend, nicht auf die physische Tastatur zu schauen. Wenn dir das schwerfällt, versuche sie mit etwas abzudecken.',
      fr: "Pour un entraînement efficace, il est crucial d'éviter de regarder votre clavier physique. Si c'est difficile, essayez de le couvrir avec quelque chose.",
      it: 'Per un allenamento efficace, è fondamentale evitare di guardare la tastiera fisica. Se lo trovi difficile, prova a coprirla con qualcosa.',
    },
    emoji: ['monkey-closed-eyes'],
  },
  sleep: {
    text: {
      en: 'Quick progress hack: practice touch typing right before bed to boost muscle memory retention.',
      ru: 'Лайфхак для быстрого прогресса: тренируйте слепую печать сразу перед сном, это облегчит работу мышечной памяти.',
      es: 'Truco para progresar rápido: practica la mecanografía justo antes de dormir para potenciar la memoria muscular.',
      de: 'Fortschritts-Tipp: Übe das Blindschreiben direkt vor dem Schlafengehen, um das Muskelgedächtnis zu fördern.',
      fr: 'Astuce pour progresser rapidement : entraînez-vous à la frappe au clavier juste avant de dormir pour renforcer la mémoire musculaire.',
      it: 'Trucco per progredire velocemente: esercitati nella digitazione alla cieca prima di dormire per potenziare la memoria muscolare.',
    },
    emoji: ['person-in-bed'],
  },
  slowerBetter: {
    text: {
      en: 'The slower you type at the beginning, the better. Focus all your attention on accuracy, as repeated mistake patterns can stick in muscle memory and become harder to fix later.',
      ru: 'Чем медленнее вы печатаете вначале, тем лучше. Сосредоточьтесь полностью на точности ввода, поскольку повторяющиеся ошибки закрепятся в мышечной памяти, и исправить их потом будет сложнее.',
      es: 'Al principio, cuanto más lento escribas, mejor. Concéntrate totalmente en la precisión, ya que los errores repetitivos pueden arraigar en la memoria muscular y ser más difíciles de corregir después.',
      de: 'Je langsamer du anfangs tippst, desto besser. Konzentriere dich voll auf die Genauigkeit, da sich wiederholende Fehler im Muskelgedächtnis verankern und später schwerer zu korrigieren sein können.',
      fr: "Au début, plus vous tapez lentement, mieux c'est. Concentrez-vous entièrement sur la précision, car les erreurs répétées peuvent s'ancrer dans la mémoire musculaire et devenir plus difficiles à corriger par la suite.",
      it: "All'inizio, più scrivi lentamente, meglio è. Concentrati interamente sulla precisione, perché gli errori ripetuti possono fissarsi nella memoria muscolare e diventare più difficili da correggere in seguito.",
    },
    emoji: ['snail'],
  },
  returnFingers: {
    text: {
      en: 'In this lesson, your fingers will start moving away from the home row. Remember to return them after each keystroke.',
      ru: 'В этом уроке ваши пальцы начнут двигаться за пределы исходной позиции. Не забывайте возвращать их обратно после каждого нажатия клавиши.',
      es: 'En esta lección, tus dedos empezarán a moverse fuera de la fila inicial. Recuerda devolverlos a su posición después de cada pulsación.',
      de: 'In dieser Lektion werden sich deine Finger von der Grundstellung entfernen. Denk daran, sie nach jedem Anschlag zurückzuführen.',
      fr: "Dans cette leçon, vos doigts commenceront à s'éloigner de la ligne de base. N'oubliez pas de les y ramener après chaque frappe.",
      it: 'In questa lezione, le tue dita inizieranno a muoversi dalla posizione di partenza. Ricorda di riportarle in posizione dopo ogni battuta.',
    },
    emoji: ['palm-down-hand'],
  },

  // rest of the course
  relax: {
    text: {
      en: "As you progress, you'll need to memorize more keys. Don't worry if your speed drops; that's normal. The most important thing is accuracy.",
      ru: 'По мере продвижения вам придётся запоминать всё больше клавиш. Не беспокойтесь, если скорость печати снижается, это нормально. Главное — точность.',
      es: 'A medida que avances, tendrás que memorizar más teclas. No te preocupes si tu velocidad disminuye; es normal. Lo más importante es la precisión.',
      de: 'Mit deinem Fortschritt wirst du mehr Tasten auswendig lernen müssen. Keine Sorge, wenn deine Geschwindigkeit dabei sinkt – das ist normal. Am wichtigsten ist die Genauigkeit.',
      fr: "Au fur et à mesure de votre progression, vous devrez mémoriser plus de touches. Ne vous inquiétez pas si votre vitesse diminue, c'est normal. L'essentiel est la précision.",
      it: 'Progredendo, dovrai memorizzare sempre più tasti. Non preoccuparti se la tua velocità diminuisce: è normale. La cosa più importante è la precisione.',
    },
    emoji: ['woman-in-lotus-position'],
  },
  startUseNewSkill: {
    text: {
      en: "If you haven't started yet, it's time to incorporate touch typing into your daily routine. It may be slow at first, and you'll be tempted to revert to old habits. Don't give up — your efforts will pay off!",
      ru: 'Если вы ещё не начали, самое время внедрить слепую печать в свою повседневную жизнь. Поначалу может быть медленно, и появится соблазн вернуться к старым привычкам. Но не сдавайтесь — ваши усилия обязательно окупятся!',
      es: 'Si aún no has empezado, es momento de incorporar la mecanografía al tacto en tu rutina diaria. Al principio puede ser lento y tendrás la tentación de volver a los viejos hábitos. ¡No te rindas, tus esfuerzos darán fruto!',
      de: 'Falls du noch nicht begonnen hast, ist es Zeit, das Zehnfingersystem in deinen Alltag zu integrieren. Anfangs mag es langsam gehen, und du wirst versucht sein, zu alten Gewohnheiten zurückzukehren. Gib nicht auf – deine Bemühungen werden sich auszahlen!',
      fr: "Si vous n'avez pas encore commencé, il est temps d'intégrer la frappe au clavier dans votre routine quotidienne. Au début, cela peut sembler lent et vous serez tenté de revenir à vos anciennes habitudes. Ne baissez pas les bras – vos efforts seront récompensés !",
      it: "Se non hai ancora iniziato, è il momento di integrare la dattilografia nella tua routine quotidiana. All'inizio potrebbe sembrare lento e potresti essere tentato di tornare alle vecchie abitudini. Non arrenderti: i tuoi sforzi saranno ripagati!",
    },
    emoji: ['man-technologist'],
  },
  optimalSchedule: {
    text: {
      en: "The optimal training schedule is 10-20 minutes daily. Don't rush through all the lessons at once — your brain needs time to build new neural pathways.",
      ru: 'Оптимальный режим тренировок — 10-20 минут каждый день. Не стремитесь пройти все уроки сразу: вашему мозгу нужно время, чтобы сформировать новые нейронные связи.',
      es: 'El horario óptimo de entrenamiento es de 10 a 20 minutos diarios. No te apresures a completar todas las lecciones de una vez; tu cerebro necesita tiempo para formar nuevas conexiones neuronales.',
      de: 'Der optimale Trainingsplan umfasst täglich 10-20 Minuten. Gehe die Lektionen nicht alle auf einmal durch – dein Gehirn braucht Zeit, um neue neuronale Verbindungen aufzubauen.',
      fr: "Le programme d'entraînement optimal est de 10 à 20 minutes par jour. N'essayez pas de faire tous les cours d'un coup ; votre cerveau a besoin de temps pour créer de nouvelles connexions neuronales.",
      it: 'Il programma di allenamento ottimale prevede 10-20 minuti al giorno. Non cercare di completare tutte le lezioni in una volta sola: il tuo cervello ha bisogno di tempo per costruire nuovi percorsi neurali.',
    },
    emoji: ['spiral-calendar'],
  },
  shift: {
    text: {
      en: 'Use the pinky finger of the opposite hand to press the Shift key, as shown in the keyboard hint. Once this becomes a habit, it will give you a boost in speed.',
      ru: 'Для нажатия клавиши Shift используйте мизинец противоположной руки, как показано на клавиатурной подсказке. Когда войдёт в привычку, это даст дополнительный прирост в скорости.',
      es: 'Usa el meñique de la mano opuesta para pulsar la tecla Shift, como se muestra en la indicación del teclado. Una vez que se convierta en hábito, te dará un impulso en la velocidad.',
      de: 'Benutze den kleinen Finger der anderen Hand, um die Umschalttaste zu drücken, wie im Tastaturhinweis gezeigt. Sobald dies zur Gewohnheit wird, gibt es dir einen Geschwindigkeitsschub.',
      fr: 'Utilisez l’auriculaire de la main opposée pour appuyer sur la touche Majuscule, comme indiqué dans l’astuce du clavier. Une fois que cela devient une habitude, vous gagnerez en vitesse.',
      it: 'Usa il mignolo della mano opposta per premere il tasto Maiusc, come mostrato nel suggerimento della tastiera. Quando diventerà un’abitudine, ti darà una spinta nella velocità.',
    },
    emoji: ['up-arrow'],
  },
} satisfies Record<string, TipData>

export type LessonTip = keyof typeof tipToTextMapping

export const tipToLessonMapping: Record<LessonTip, string> = {
  // home row
  doNotLook: 'HomeRow-FocusOnNewLetters-KeyDKeyK',
  slowerBetter: 'HomeRow-FocusOnNewLetters-KeySKeyL',
  sleep: 'HomeRow-FocusOnNewLetters-KeyASemicolon',
  returnFingers: 'HomeRow-NewLetters-KeyGKeyH',

  // top row
  optimalSchedule: 'TopRow-FocusOnNewLetters-KeyRKeyU',
  relax: 'TopRow-FocusOnNewLetters-KeyEKeyI',

  // bottom row NO
  // finger strength NO
  // bigrams NO

  // common words
  startUseNewSkill: 'FrequentWords-PopularLowerWords-0',

  // shift
  shift: 'Shift-ShiftLettersRow-Home',

  // punctuation NO
  // full review NO
  // numbers NO
}
