<script setup lang="ts">
import Keyboard from '@/components/Keyboard.vue'
import { isTypeable, type KeyCode } from '@/helpers/keyboards/KeyCode'
import { Layer } from '@/helpers/keyboards/Layer'
import { accuracyTone, pressesColor, speedColor } from '@/helpers/metric-color-scales'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { hslToString } from '@/types/color-types'
import { Metric, Speed } from '@/types/metric-types'
import Color from 'colorjs.io'
import { computed, ref, watchEffect, type StyleValue } from 'vue'
import { useI18n } from 'vue-i18n'
import type { TippyOptions } from 'vue-tippy'

type Props = {
  metric: Metric
  handlePresses?: boolean | 'layer-only'
  layer?: Layer
  lastTraining?: boolean
}
const props = withDefaults(defineProps<Props>(), { lastTraining: false, handlePresses: 'layer-only' })

const { t } = useI18n()
const courseStore = useCourseStore()
const userStore = useUserStore()

const userLayout = courseStore.current

if (!userLayout) {
  throw new Error('No user layout, nothing to highight')
}

// - current layout
// - typingData (props)

// split typing data by LAYER for curr layout
// - data per key code
// - min, max, mid range

// curr layouer model with range and colors

const data = computed(() => {
  return props.lastTraining ? courseStore.current.stats.lastTraining : courseStore.current.stats.highlight
})

const pressesPerKey = computed(() => {
  return data.value.perKeycode(Metric.Presses)
})
const typosPerKey = computed(() => {
  return data.value.perKeycode('typosCount')
})

const dataPerKey = computed(() => {
  return data.value.perKeycode(props.metric)
})

// single num value or [min, mid, max]
const rangePerLayer = computed<Partial<Record<Layer, number[]>>>(() => {
  return Object.fromEntries(
    Object.entries(dataPerKey.value.perLayer()).map(([k, v]) => {
      const values = v.values()
      const min = Math.min(...values)
      const max = Math.max(...values)
      return [k, [min, (min + max) / 2, max]]
    }),
  )
})

const currentLayer = ref(Layer.Default)

const calcColor = (value: number, range: number[]) => {
  const [min, , max] = range

  switch (props.metric) {
    case Metric.Speed:
      return speedColor(value, min, max)
    case Metric.Accuracy:
      return hslToString(accuracyTone(value, min, max))
    case Metric.Presses:
      return pressesColor(value, min, max)
    default:
      throw new Error('Unknown metric to calc color')
  }
}

const currentLayerRange = computed(() => {
  return rangePerLayer.value[currentLayer.value]
})

const currentLayerRangeValuesModel = defineModel('rangeValues')
const currentLayerRangeColorsModel = defineModel('rangeColors')

watchEffect(() => {
  currentLayerRangeValuesModel.value = currentLayerRange.value
  currentLayerRangeColorsModel.value = currentLayerRange.value ? currentLayerRange.value.map((v, i, arr) => calcColor(v, arr)) : null
})

const keyStyleFunc = (code: KeyCode, value: string, keyboardState: Layer): StyleValue => {
  if (!isTypeable(code)) {
    return {
      color: 'transparent',
      '--c-icon': 'transparent',
    }
  }

  let resultStyle: StyleValue = {
    fontSize: 'calc(var(--keyboard-size-unit) * 4)',
  }

  const charData = dataPerKey.value.get(new LayeredKeyCode(code, keyboardState))
  if (!charData) {
    return resultStyle
  }

  const range = rangePerLayer.value[keyboardState]
  if (!range) {
    throw new Error('No data for whole layer')
  }

  const color = calcColor(charData, range)

  const borderColor = new Color(color)
  borderColor.alpha = borderColor.alpha > 0.3 ? borderColor.alpha / 2 : borderColor.alpha

  resultStyle['--background-color'] = color
  resultStyle['--border-color'] = 'transparent'
  // resultStyle['--border-color'] = props.metric === Metric.Accuracy ? color : borderColor.toString()
  resultStyle['--text-color'] = props.metric === Metric.Accuracy ? '#000' : 'var(--c-text-primary)'

  return {
    ...resultStyle,
  }
}

const keyTooltipFunc = (code: KeyCode, keyboardState: Layer): TippyOptions | null => {
  const layeredKeyCode = new LayeredKeyCode(code, keyboardState)

  const charData = dataPerKey.value.get(layeredKeyCode)
  const typosCount = typosPerKey.value.get(layeredKeyCode)!

  if (!charData) {
    return null
  }

  let content
  if (props.metric === Metric.Accuracy) {
    const accuracyValue = `${charData.toFixed(1)}%`
    content = typosCount ? t('KeyboardHighlight.accuracyTooltipLong', [accuracyValue, t('typosCount', typosCount)]) : accuracyValue
  } else if (props.metric === Metric.Speed) {
    const pressSpeed = new Speed(charData * 5)
    content = pressSpeed.format({ unit: userStore.settings.speedUnit })
  } else {
    content = t('pressCount', charData)
  }

  return {
    content,
    placement: 'top',
  }
}
</script>

<template>
  <Keyboard
    :keyStyleFunc="keyStyleFunc"
    :keyTooltipFunc="keyTooltipFunc"
    outlined
    :handlePresses="handlePresses"
    unbordered
    :layer="layer"
    v-model:layoutState="currentLayer"
  />
</template>

<style lang="scss" scoped></style>
