import { calcAccuracy, calcSpeed } from '@/helpers/main-utils'
import { Accuracy, Speed } from '../metric-types'

export class TypingResult {
  constructor(
    public validPresses: number,
    public validPressesTimeMs: number,
    public validTypingTimeMs: number,
    public invalidPresses: number,
    public invalidTypingTimeMs: number,
    public typos: number,
  ) {}

  get avgValidPressTimeMs(): number | undefined {
    if (!this.validPresses) {
      return undefined
    }
    return this.validPressesTimeMs / this.validPresses
  }

  get presses(): number {
    return this.validPresses + this.invalidPresses
  }

  get typingTimeMs(): number {
    return this.validTypingTimeMs + this.invalidTypingTimeMs
  }

  get speed(): Speed {
    return calcSpeed(this.validTypingTimeMs, this.validPresses)
  }

  get pressSpeed(): Speed {
    return calcSpeed(this.validPressesTimeMs, this.validPresses)
  }

  get accuracy(): Accuracy {
    return calcAccuracy(this.presses, this.typos)
  }

  static summarize(typings: TypingResult[]): TypingResult {
    const result = new TypingResult(0, 0, 0, 0, 0, 0)

    typings.forEach((typing) => {
      result.validPresses += typing.validPresses
      result.validPressesTimeMs += typing.validPressesTimeMs
      result.validTypingTimeMs += typing.validTypingTimeMs
      result.invalidPresses += typing.invalidPresses
      result.invalidTypingTimeMs += typing.invalidTypingTimeMs
      result.typos += typing.typos
    })

    return result
  }

  static fromJSON(parsed: any): TypingResult {
    return new TypingResult(
      parsed.validPresses,
      parsed.validPressesTimeMs,
      parsed.validTypingTimeMs,
      parsed.invalidPresses,
      parsed.invalidTypingTimeMs,
      parsed.typos,
    )
  }

  toJSON(): any {
    return this
  }

  static template(): TypingResult {
    return new TypingResult(0, 0, 0, 0, 0, 0)
  }

  isEmpty(): boolean {
    return (
      this.validPresses === 0 &&
      this.validPressesTimeMs === 0 &&
      this.validTypingTimeMs === 0 &&
      this.invalidPresses === 0 &&
      this.invalidTypingTimeMs === 0 &&
      this.typos === 0
    )
  }
}
