<script setup lang="ts">
import Button from '@/components/Button.vue'
import { tipToTextMapping, type LessonTip, type TipData } from '@/course/course-tips'
import type { UserLocale } from '@/plugins/i18n'
import type { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Emoji from './Emoji.vue'

type Props = {
  tip: LessonTip
  shortcut: KeyboardShortcut
}
const props = defineProps<Props>()

const { t, locale } = useI18n()

const tipData = computed<TipData>(() => tipToTextMapping[props.tip])

const emit = defineEmits(['dismiss'])
</script>

<template>
  <Teleport to="body">
    <div class="overlay">
      <div class="modal">
        <div class="content">
          <div class="illustration">
            <Emoji v-for="emoji in tipData.emoji" :key="emoji" :name="emoji" />
          </div>
          <p class="text">{{ tipData.text[locale as UserLocale] }}</p>
          <div class="buttons action">
            <Button size="md" variant="primary" @click="emit('dismiss')" shortcut="Enter">{{ t('continue') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--c-modal-backdrop);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-25vh);
  z-index: 1;

  .content {
    background-color: var(--c-surface);
    border-radius: var(--br-xl);
    border: 1px solid var(--c-secondary-border);
    padding: var(--s-lg);
    width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .illustration {
      display: flex;
      gap: var(--s-md);

      :deep(img) {
        width: 46px;
        height: 46px;
      }
    }

    .text {
      margin-top: var(--s-lg);
      margin-bottom: var(--s-xl);
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: var(--s-md);

      .shortcut-hint {
        font-size: var(--fz-sm);
      }
    }
  }
}
</style>
