import { isEqual } from 'lodash-es'

export class Char {
  isBasic: boolean = true
  isLigature: boolean = false
  isCombining: boolean = false

  // TODO: refactor to have single char
  constructor(public value: string) {}

  isEqual(other: Char) {
    return this.value === other.value
    // no need to compare other fields
  }

  get printLength() {
    if (this.isLigature) {
      return 1
    }
    return this.value.length
  }

  static basic(value: string): Char {
    return new Char(value)
  }

  static ligature(value: string): Char {
    const ligatureChar = new Char(value)
    ligatureChar.isBasic = false
    ligatureChar.isLigature = true
    return ligatureChar
  }

  static combining(value: string): Char {
    const combiningChar = new Char(value)
    combiningChar.isBasic = false
    combiningChar.isCombining = true
    return combiningChar
  }
}

export class KeyChar {
  isTemplate = false

  constructor(
    public char: Char,
    public deadModifications?: [Char[], Char[]],
  ) {}

  get value() {
    return this.char.value
  }

  setValue(value: string) {
    // assuming it's basic by default
    this.char = new Char(value)
    this.isTemplate = false
  }

  isEqual(other: KeyChar): boolean {
    return this.char.isEqual(other.char) && isEqual(this.deadModifications, other.deadModifications)
  }

  isDead(): boolean {
    return this.deadModifications !== undefined
  }

  getDeadTransformation(char: Char) {
    if (!this.deadModifications) return null

    if (!this.deadModifications[0].length && char.value === ' ') {
      // special case for non-mapped Deads — for now we assume they all have Space mapping
      return this.char
    }

    const index = this.deadModifications[0].findIndex((m) => m.isEqual(char))
    return this.deadModifications[1][index] ?? null
  }

  static template(): KeyChar {
    const result = new KeyChar(new Char(''))
    result.isTemplate = true
    return result
  }

  static Nothing(): KeyChar {
    return new KeyChar(new Char(''))
  }

  static Space(): KeyChar {
    return new KeyChar(new Char(' '))
  }
}
