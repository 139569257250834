// AUTH

import { logAnalyticsEvent, resetUserInMixpanel } from '@/helpers/analytics'
import { safeUnwrap } from '@/helpers/main-utils'
import router from '@/router'
import { useTrainingSessionStore } from '@/stores/trainingSessionStore'
import { useUserStore } from '@/stores/userStore'
import { GoogleAuthProvider, getAuth, onAuthStateChanged, signInWithPopup } from 'firebase/auth'

export const auth = getAuth()

export const signInWithGoogle = async () => {
  const [error, creds] = await safeUnwrap(signInWithPopup(auth, new GoogleAuthProvider()))
  if (!creds) {
    return
  }

  const creationTime = creds.user.metadata.creationTime
  const lastSignInTime = creds.user.metadata.lastSignInTime
  if (creationTime && lastSignInTime) {
    logAnalyticsEvent(creationTime === lastSignInTime ? 'register' : 'login', {}, { send_immediately: true })
  }

  await router.replace({ name: 'home' })
}

export const logout = async () => {
  const userStore = useUserStore()
  const trainingSessionStore = useTrainingSessionStore()
  await auth.signOut()
  trainingSessionStore.clear()
  userStore.reset()
  resetUserInMixpanel()
}

onAuthStateChanged(auth, (user) => {
  const userStore = useUserStore()
  const trainingSessionStore = useTrainingSessionStore()

  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    userStore.init({ email: user.email as string, name: user.displayName as string, id: user.uid, registered: user.metadata.creationTime })

    if (router.currentRoute.value.name === 'landing' && trainingSessionStore.isActive) {
      router.push({ name: 'home' })
    }
  } else {
    userStore.init()
  }

  // // prevent flickering
  // userStore.initialized = true
})
