<script setup lang="ts">
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import Button from '@/components/Button.vue'
import CourseProgress from '@/components/CourseProgress.vue'
import Emoji from '@/components/Emoji.vue'
import FeedbackForm from '@/components/FeedbackForm.vue'
import HomeStatsKeyHighlight from '@/components/HomeStatsKeyHighlight.vue'
import { shortcutsConfig } from '@/configs/shortcuts-config'
import { buildCourse } from '@/course/course-builder'
import { getChapterTitle, getLessonTitle } from '@/course/course-lesson-titles'
import { Chapter, type LessonCoords } from '@/course/course-types'
import { logAnalyticsEvent } from '@/helpers/analytics'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { cloudFunctionUrl } from '@/helpers/main-utils'
import { signInWithGoogle } from '@/plugins/firebase/auth'
import { useAppStore } from '@/stores/appStore'
import { useCourseStore } from '@/stores/courseStore'
import { useTrainingSessionStore } from '@/stores/trainingSessionStore'
import { useUserStore } from '@/stores/userStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { Metric } from '@/types/metric-types'
import axios from 'axios'
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import HomeStatsCalendar from '../components/HomeStatsCalendar.vue'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const appStore = useAppStore()
const courseStore = useCourseStore()
const userStore = useUserStore()
const userLayout = computed(() => courseStore.current)
const layout = computed(() => userLayout.value.layout)
const trainingSessionStore = useTrainingSessionStore()

trainingSessionStore.start()

const course = computed(() => buildCourse(layout.value))
const chapters = computed(() => (course.value ? course.value.content : null))

const lessonData = (chapter: Chapter, index: number) => {
  const coords: LessonCoords = { chapter, index }
  return chapters.value ? chapters.value[coords.chapter][coords.index] : null
}

const isLessonLocked = (chapter: Chapter, index: number) => {
  return !lessonData(chapter, index)?.availableOnTrial && !userStore.hasFullAccess
}

// status
const completionStats = computed(() => courseStore.current.stats.completion)
const todayStats = computed(() => courseStore.current.stats.today)
const nextLesson = computed(() => completionStats.value.nextLesson())
const expandedChapters = ref<Set<Chapter>>(new Set([]))

const showFeedback = computed(
  () => !userStore.settings.isFeedbackFormClosed && (nextLesson.value.chapter !== Chapter.HomeRow || nextLesson.value.index > 1),
)

watch(
  nextLesson,
  (to) => {
    expandedChapters.value.clear()
    expandedChapters.value.add(to.chapter)
  },
  { immediate: true },
)

const onLessonClick = (chapter: Chapter, index: number) => {
  const lessonCoords: LessonCoords = { chapter, index }
  if (isLessonLocked(chapter, index)) {
    return
  }
  userStore.currentLesson = lessonCoords
  const firstLesson = lessonData(Chapter.HomeRow, 0)!
  if (lessonCoords.chapter === Chapter.HomeRow && lessonCoords.index === 0 && !completionStats.value.isLessonCompleted(firstLesson.uniqueId)) {
    router.push({ name: 'intro' })
  } else {
    router.push({ name: 'typing' })
  }
}

const loadingButton = ref<'aside' | 'main' | null>(null)

const onBuyClick = async (button: 'aside' | 'main') => {
  if (loadingButton.value !== null) {
    return
  }

  logAnalyticsEvent('click_buy', {}, { send_immediately: true })
  loadingButton.value = button
  if (!userStore.authData) {
    await signInWithGoogle()
  }

  if (!userStore.authData) {
    // sign in cancelled
    loadingButton.value = null
    return
  }

  if (userStore.hasFullAccess) {
    loadingButton.value = null
    console.log('Already have full access, do not redirect to payment')
    return
  }

  appStore.showLoader('startPaymentRequest', t('Home.talkingToStripe'))
  logAnalyticsEvent('checkout_start', {}, { send_immediately: true })
  const response = await axios.post(cloudFunctionUrl('checkout'), { email: userStore.authData.email })
  loadingButton.value = null
  appStore.hideLoader('startPaymentRequest')

  window.location.replace(response.data)
}

const onStart = () => {
  if (isLessonLocked(nextLesson.value.chapter, nextLesson.value.index)) {
    onBuyClick('main')
    return
  }
  onLessonClick(nextLesson.value.chapter, nextLesson.value.index)
}

// shortcuts
const shortcuts = {
  start: new KeyboardShortcut(shortcutsConfig.continue, () => {
    onStart()
  }),
}
useKeyboardShortcuts(Object.values(shortcuts))
</script>

<template>
  <teleport defer to="#app-header">
    <AppHeader divided>
      <template #middle>
        <div class="header-panel"></div>
      </template>
    </AppHeader>
  </teleport>

  <div class="container">
    <div class="inner-container">
      <aside>
        <CourseProgress />
        <div v-if="!userStore.hasFullAccess" class="unlock-widget">
          <h4 class="title">{{ t('Home.paymentTitle') }}&nbsp;<Emoji name="sparkles" /></h4>
          <p class="description">{{ t('Home.paymentDescription') }}</p>
          <Button style="width: 100%" variant="outlined" @click="onBuyClick('aside')" :loading="loadingButton === 'aside'">{{
            t('Home.buyFor', ['$9'])
          }}</Button>
        </div>
      </aside>
      <main>
        <div class="header">
          <div class="widget start" :class="{ dark: appStore.isDarkTheme }">
            <i class="fi fi-bs-angle-double-right"></i>

            <template v-if="isLessonLocked(nextLesson.chapter, nextLesson.index)">
              <div class="chapter">{{ t('Home.paymentTitle') }}</div>
              <div class="lesson">
                {{ t('Home.paymentDescription') }}
              </div>
            </template>
            <template v-else>
              <div class="chapter">{{ t('Home.nextLesson') }}</div>
              <div class="lesson">
                {{ getChapterTitle(nextLesson.chapter) }}.
                {{ getLessonTitle(lessonData(nextLesson.chapter, nextLesson.index)) }}
              </div>
            </template>

            <div class="button-wrapper">
              <Button shortcut="Enter" size="md" :loading="loadingButton === 'main'" @click="onStart">
                <template v-if="!isLessonLocked(nextLesson.chapter, nextLesson.index)">{{ t('Home.startLesson') }}</template>
                <template v-else>{{ t('Home.buyFor', ['$9']) }}</template>
              </Button>
            </div>
          </div>
        </div>

        <div class="stats">
          <HomeStatsKeyHighlight :title="t('Home.keyboardCoverageTitle')" :subtitle="t('Home.keyboardCoverageSubtitle')" :metric="Metric.Presses" />
          <HomeStatsKeyHighlight :title="t('Home.accuracyPerKeyTitle')" :subtitle="t('Home.accuracyPerKeySubtitle')" :metric="Metric.Accuracy" />
          <HomeStatsKeyHighlight :title="t('Home.pressSpeedPerKeyTitle')" :subtitle="t('Home.pressSpeedPerKeySubtitle')" :metric="Metric.Speed" />
          <HomeStatsCalendar />
        </div>

        <FeedbackForm v-if="showFeedback" @close="userStore.saveSettings({ isFeedbackFormClosed: true })" />

        <div class="footer">
          <AppFooter />
          <div class="footer-links">
            <div class="copy">Typing School, Inc. © {{ dayjs().format('YYYY') }}</div>
            <div class="links" v-if="route.name === 'home'">
              <a href="/privacy" class="link" target="_blank">{{ t('Home.privacyLink') }}</a>
              <a href="/terms" class="link" target="_blank">{{ t('Home.termsLink') }}</a>
              <a href="mailto:hello@typing.school" class="link">{{ t('Home.supportLink') }}</a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
}

.inner-container {
  display: flex;

  --main-width: 785.6px;
  --main-padding: var(--s-lg);
  --main-padding-right: var(--s-md);
  --aside-width: calc(var(--min-viewport-inner-width) - var(--main-width) - var(--main-padding) - var(--main-padding-right));
}

.widget {
  border-radius: var(--br-xl);
  padding: var(--s-md);
  border: 1px solid var(--c-divider);
  background-color: var(--c-surface);
}

aside {
  width: var(--aside-width);
  border-right: 1px solid var(--c-divider);
  position: fixed;
  height: calc(100% - var(--header-height, 3.75rem));
  // padding: var(--s-sm) var(--s-xs);
  // padding-left: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .unlock-widget {
    padding: var(--s-md) var(--s-md);
    font-size: var(--fz-sm);
    border: 1px solid var(--c-divider);
    position: sticky;
    background-color: var(--c-surface);
    bottom: 8px;
    margin: 8px;
    margin-left: 0;
    border-radius: var(--br-md);
    margin-top: auto;

    .title {
      font-weight: 500;
    }

    .description {
      color: var(--c-text-secondary);
      margin-top: var(--s-sm);
      margin-bottom: var(--s-md);
    }
  }
}

main {
  width: var(--main-width);
  box-sizing: content-box;
  margin-left: var(--aside-width);
  padding: var(--main-padding);
  padding-right: var(--main-padding-right);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: var(--s-xl);

  * {
    box-sizing: border-box;
  }

  .header {
    display: flex;
    min-height: 260px;
    gap: var(--s-lg);
    margin-bottom: var(--s-xl);

    .widget {
      flex: 1;
      &.start {
        flex: 2;
      }
    }
  }

  .widget.start {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--s-xs);
    position: relative;
    overflow: hidden;
    background: var(--c-surface);
    padding: 0 40px;
    // align-items: flex-start;

    i {
      position: absolute;
      top: 20px;
      left: 3%;
      opacity: 0.05;
      z-index: 0;
    }
    i:before {
      font-size: 4rem;
    }

    .chapter {
      opacity: 0.5;
      display: flex;
      align-items: center;
      gap: var(--s-sm);
    }

    .lesson {
      margin-bottom: var(--s-lg);
      font-size: var(--fz-lg);
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--s-md);
    }

    &.dark {
      background: var(--c-background);
      &:before {
        content: url('');
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100px;
        z-index: 0;
        background: linear-gradient(
          90deg,
          #020111 0%,
          #3a3a52 7.14%,
          #7072ab 14.29%,
          #cd82a0 21.43%,
          #eab0d1 28.19%,
          #94c5f8 35.22%,
          #57c1eb 44.88%,
          #62c2ee 55.43%,
          #8f8ddb 63.65%,
          #8583be 66.34%,
          #c8627b 70.32%,
          #ee512f 75.19%,
          #da4314 79.4%,
          #b7490f 83.42%,
          #59230b 90.49%,
          #2f1107 94.99%,
          #150800 100%
        );
        opacity: 0.15;
        filter: blur(64px);
      }
    }
  }

  .stats {
    display: flex;
    flex-direction: column;
    gap: var(--s-xxl);
  }
}

.footer {
  margin: 0 calc(-1 * var(--grid-cell)) 0;

  .footer-links {
    padding: var(--grid-cell);
    padding-top: var(--s-lg);
    display: flex;
    gap: var(--s-md);
    font-size: var(--fz-sm);
    color: var(--c-text-secondary);
  }

  .copy {
    color: var(--c-text-tertiary);
  }

  .links {
    display: flex;
    gap: var(--s-md);

    .link {
      color: var(--c-text-secondary);
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: var(--c-text-primary);
      }
    }
  }
}
</style>
