import { cloneDeep, sum } from 'lodash-es'

export class DigitsData<T> {
  private value: T[]

  // value - exact mapping: index = digit
  constructor(value: T[]) {
    if (value.length !== 10) {
      throw new Error('DigitsData must be initialized with exactly 10 values')
    }
    this.value = value
  }

  static init<U>(value: U): DigitsData<U> {
    const values = []
    for (let i = 0; i < 10; i++) {
      values.push(cloneDeep(value))
    }
    return new DigitsData(values)
  }

  map<U>(callbackfn: (value: T, index: number, array: T[]) => U): DigitsData<U> {
    return new DigitsData(this.rawValue.map(callbackfn))
  }

  // global getters

  public get mappedValue() {
    return [...this.rawValue.slice(1), this.rawValue[0]]
  }

  public get rawValue() {
    return this.value
  }

  // single key getters/setters

  set(key: string | number, value: T) {
    this.validateKeyName(key)
    key = Number(key)
    this.rawValue[key] = value
  }

  add(key: string | number, value: number) {
    this.validateKeyName(key)
    key = Number(key)

    if (typeof this.rawValue[0] !== 'number') {
      throw new Error('add() can only be used with number data types')
    }

    // @ts-expect-error
    this.rawValue[key] = this.rawValue[key] + value
  }

  get(key: string | number): T {
    this.validateKeyName(key)
    key = Number(key)
    return this.rawValue[key]
  }

  get zero(): T {
    return this.rawValue[0]
  }
  get one(): T {
    return this.rawValue[1]
  }
  get two(): T {
    return this.rawValue[2]
  }
  get three(): T {
    return this.rawValue[3]
  }
  get four(): T {
    return this.rawValue[4]
  }
  get five(): T {
    return this.rawValue[5]
  }
  get six(): T {
    return this.rawValue[6]
  }
  get seven(): T {
    return this.rawValue[7]
  }
  get eight(): T {
    return this.rawValue[8]
  }
  get nine(): T {
    return this.rawValue[9]
  }

  getMax(): { key: number; value: number } {
    if (typeof this.rawValue[0] !== 'number') {
      throw new Error('getMax can only be used with number data types')
    }

    let values = this.rawValue as number[]
    let max = values[0]
    let maxKey = 0
    for (let i = 0; i < 10; i++) {
      if (values[i] > max) {
        max = values[i]
        maxKey = i
      }
    }

    return { key: maxKey, value: max }
  }

  getMin(): { key: number; value: number } {
    if (typeof this.rawValue[0] !== 'number') {
      throw new Error('getMin can only be used with number data types')
    }

    let values = this.rawValue as number[]
    let min = values[0]
    let minKey = 0
    for (let i = 0; i < 10; i++) {
      if (values[i] < min) {
        min = values[i]
        minKey = i
      }
    }

    return { key: minKey, value: min }
  }

  getSum() {
    if (typeof this.rawValue[0] !== 'number') {
      throw new Error('getMin can only be used with number data types')
    }

    let values = this.rawValue as number[]
    return sum(values)
  }

  private validateKeyName(key: number | string) {
    if (
      (typeof key === 'string' && !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key)) ||
      (typeof key === 'number' && (key < 0 || key > 9))
    ) {
      throw new Error('NumbersData key must be between 0 and 9')
    }
  }

  forEach(callbackfn: (value: T, key: number) => void) {
    this.rawValue.forEach((value, index) => callbackfn(value, index))
  }

  static fromJSON<U>(value: Record<number, U>): DigitsData<U> {
    return new DigitsData(
      Object.entries(value)
        .sort((a, b) => Number(a[0]) - Number(b[0]))
        .map((entry) => entry[1]) as U[],
    )
  }

  toJSON() {
    return Object.fromEntries(this.rawValue.entries())
  }
}
