import { KeyboardFormat } from '@/helpers/keyboards/KeyboardLayout'
import { allLangConfig } from '@/languages/all-lang-config'
import { type LanguageCode } from '@/languages/languages-config'
import { getLayoutsMetadata } from '@/layouts/layouts-config'
import { i18n } from '@/plugins/i18n'
import { OS } from './main-types'

export class LayoutDefinition {
  private static divider = '__'

  constructor(
    public os: OS,
    public format: KeyboardFormat,
    public layoutId: string,
    public languageCode: LanguageCode,
  ) {}

  equal(other: LayoutDefinition) {
    return this.toStringId() === other.toStringId()
  }

  get title() {
    return getLayoutsMetadata(this.os, i18n.global.locale.value)[this.layoutId]?.title
  }

  get languageTitle() {
    if (!this.languageCode) return ''
    return allLangConfig[this.languageCode].title
  }

  toStringId() {
    return [this.os, this.format, this.layoutId, this.languageCode].join(LayoutDefinition.divider)
    // mac__ans__russian_pc__ru
  }

  static fromStringId(stringId: string) {
    let [os, format, layoutId, languageCode] = stringId.split(LayoutDefinition.divider)

    // data migration to more general layouts (from Jun 8, 2024)
    if (os === OS.mac && ['us', 'abc', 'canadian', 'australian'].includes(layoutId)) {
      layoutId = 'standard'
    } else if (os === OS.mac && ['abc_qwertz', 'austrian'].includes(layoutId)) {
      layoutId = 'german'
    }

    return new LayoutDefinition(os as OS, format as KeyboardFormat, layoutId, languageCode as LanguageCode)
  }

  static template() {
    return new LayoutDefinition(OS.mac, KeyboardFormat.ANSI, 'standard', 'en')
  }
}
