import { LayeredKeyCode } from '../LayeredKeycode'
import { TypingResult } from './TypingResult'

export class ExtendedTypingResult extends TypingResult {
  typosList: LayeredKeyCode[]

  constructor(
    validPresses: number,
    validPressesTimeMs: number,
    validTypingTimeMs: number,
    invalidPresses: number,
    invalidTypingTimeMs: number,
    typos: number,
    typosList: LayeredKeyCode[],
  ) {
    super(validPresses, validPressesTimeMs, validTypingTimeMs, invalidPresses, invalidTypingTimeMs, typos)
    this.typosList = typosList
  }

  get typosByPopularity(): Record<number, number> {
    return this.typosList.reduce(
      (acc, curr) => {
        const typed = curr.serialize()
        acc[typed] = acc[typed] ? acc[typed] + 1 : 1
        return acc
      },
      {} as Record<string, number>,
    )
  }

  static summarize(typings: ExtendedTypingResult[]): ExtendedTypingResult {
    const result = this.fromTypingResult(super.summarize(typings))

    typings.forEach((typing) => {
      if (typing.typosList.length) {
        result.typosList = result.typosList.concat(typing.typosList)
      }
    })

    return result
  }

  static fromTypingResult(typing: TypingResult): ExtendedTypingResult {
    return new ExtendedTypingResult(
      typing.validPresses,
      typing.validPressesTimeMs,
      typing.validTypingTimeMs,
      typing.invalidPresses,
      typing.invalidTypingTimeMs,
      typing.typos,
      [],
    )
  }

  static fromJSON(parsed: any): ExtendedTypingResult {
    const result = ExtendedTypingResult.fromTypingResult(TypingResult.fromJSON(parsed))
    result.typosList = Object.values(parsed.typosList ?? []).map((v: any) => LayeredKeyCode.parse(v))
    return result
  }

  toJSON(): any {
    return {
      ...super.toJSON(),
      typosList: this.typosList.map((t) => t.toJSON()),
    }
  }

  static template(): ExtendedTypingResult {
    return new ExtendedTypingResult(0, 0, 0, 0, 0, 0, [])
  }

  isEmpty(): boolean {
    return super.isEmpty() && this.typosList.length === 0
  }
}

// type Typo = Map<string, string> // toType, typed
type Typo = {
  toType: string
  typed: string
}
