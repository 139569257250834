export enum Layer {
  Default = 1,
  Shift,
  Option, // alt+ctrl or altGr on windows
  ShiftOption, // shift+alt+ctrl or shift+altGr on windows

  CapsDefault,
  CapsShift,
  CapsOption,
  CapsShiftOption,
}

export const allLayersByPriority: Layer[] = [
  Layer.Default,
  Layer.Shift,
  Layer.Option,
  Layer.ShiftOption,
  Layer.CapsDefault,
  Layer.CapsShift,
  Layer.CapsOption,
  Layer.CapsShiftOption,
]

export const isShiftLayer = (layer: Layer) => [Layer.Shift, Layer.ShiftOption, Layer.CapsShift, Layer.CapsShiftOption].includes(layer)
export const isOptionLayer = (layer: Layer) => [Layer.Option, Layer.ShiftOption, Layer.CapsOption, Layer.CapsShiftOption].includes(layer)
export const isCapsLayer = (layer: Layer) => [Layer.CapsDefault, Layer.CapsShift, Layer.CapsOption, Layer.CapsShiftOption].includes(layer)

export type LayersMap<T> = {
  [key in Layer]: T
}

export const buildLayerFromModifiers = (obj: { caps: boolean; shift: boolean; option: boolean }): Layer => {
  const { caps, shift, option } = obj
  // @ts-expect-error
  return Layer[`${caps ? 'Caps' : ''}${!shift && !option ? 'Default' : ''}${shift ? 'Shift' : ''}${option ? 'Option' : ''}`] as Layer
}

export const getLayerFromKeyboardEvent = (event: KeyboardEvent, capsLockOn: boolean) => {
  if (event.shiftKey) {
    if (event.altKey) {
      return capsLockOn ? Layer.CapsShiftOption : Layer.ShiftOption
    } else {
      return capsLockOn ? Layer.CapsShift : Layer.Shift
    }
  } else {
    if (event.altKey) {
      return capsLockOn ? Layer.CapsOption : Layer.Option
    } else {
      return capsLockOn ? Layer.CapsDefault : Layer.Default
    }
  }
}
