import { sum } from 'lodash-es'
import { Char, KeyChar } from './keyboards/KeyChar'
import { typeableKeyCodes, type KeyCode, type TypeableKeyCode } from './keyboards/KeyCode'
import { KeyboardFormat, type KeyboardLayout } from './keyboards/KeyboardLayout'
import { type Layer } from './keyboards/Layer'
import { deadKeysBehaviorConfig } from './main-config'
import { userSystemSwapsIntBackslashAndBackquote } from './user-agent-utils'

export type KeyPress = {
  keyCode: KeyCode // consistent IntlBackslash on Mac
  layer: Layer
  cursorMove: number

  // typeable
  print: Char[]
  printModified: boolean // prev was Dead
  deadPreview?: Char
  keyChar?: KeyChar
}

export class KeyPressHelper {
  private prevPress?: KeyPress

  constructor(public layout: KeyboardLayout) {}

  produceStandartizedKeyPress(event: KeyboardEvent, currentLayer: Layer): KeyPress {
    const keyCode = this.getConsistentKeyCode(event)
    const deadBehavior = deadKeysBehaviorConfig[this.layout.os]
    const isTypeable = typeableKeyCodes.includes(keyCode as TypeableKeyCode)

    let resultPressObject: KeyPress = {
      keyCode,
      layer: currentLayer,
      cursorMove: 0,
      print: [],
      printModified: false,
    }

    const prevDead = !!this.prevPress?.deadPreview
    const prevKeyChar = this.prevPress?.keyChar

    if (!isTypeable) {
      // Backspace or other functional keys
      if (keyCode === 'Backspace') {
        this.prevPress = undefined // cancel possible waiting Dead
        return {
          ...resultPressObject,
          cursorMove: prevDead && deadBehavior.consumesBackspace ? 0 : -1,
        }
      }
      return resultPressObject
    }

    // at this point press is typeable

    const keyChar = this.layout.getSymbol(keyCode as TypeableKeyCode, currentLayer) ?? KeyChar.Space()
    resultPressObject.keyChar = keyChar

    const currDead = keyChar.isDead()

    // 1. basic, basic
    // 2. Dead, basic
    // 3. basic, Dead
    // 4. Dead, Dead

    if (!prevDead && !currDead) {
      // 1/4: basic, basic
      resultPressObject.print = [keyChar.char]
    } else if (prevDead && !currDead) {
      // 2/4: Dead, basic — apply transformation or type both, if not found

      const transformation = prevKeyChar!.getDeadTransformation(keyChar.char)
      if (transformation === null) {
        // since we do not define all possible dead mappings, here as a last resort we try to use system transformation
        // as of May 31, 2024 this won't work on MacOS, but should on Windows
        if (keyChar.char.value !== event.key) {
          resultPressObject.print = [new Char(event.key)]
          resultPressObject.printModified = true
        } else {
          resultPressObject.print = [this.prevPress!.deadPreview!, keyChar.char]
        }
      } else {
        resultPressObject.print = [transformation]
        resultPressObject.printModified = true
      }
    } else if (!prevDead && currDead) {
      // 3/4: basic, Dead — just show preview, don't move cursor
      resultPressObject.deadPreview = keyChar.char
    } else {
      // 4/4: Dead, Dead — special logic, depending on OS

      const logic = prevKeyChar!.isEqual(keyChar) ? deadBehavior.secondDeadSame : deadBehavior.secondDeadOther
      switch (logic) {
        case 'exec':
          resultPressObject.print = [prevKeyChar!.char]
          break
        case 'exec-new':
          resultPressObject.print = [prevKeyChar!.char]
          resultPressObject.deadPreview = keyChar.char
          break
        case 'exec-new-exec':
          resultPressObject.print = [prevKeyChar!.char, keyChar.char]
          break
      }

      resultPressObject.printModified = true
    }

    // calc total cursor move
    resultPressObject.cursorMove = sum(resultPressObject.print.map((char) => char.printLength))

    // handle Russian — PC layout Ë bug
    if (this.layout.layoutId === 'russian_pc') {
      resultPressObject.print.forEach((char, i) => {
        if (char.value === 'Ë') char.value = 'Ё' // transform "Latin Capital Letter E With Diaeresis" to "Cyrillic Capital Letter Io"
      })
    }

    this.prevPress = resultPressObject
    return resultPressObject
  }

  getConsistentKeyCode(event: KeyboardEvent): KeyCode {
    if (this.layout.format === KeyboardFormat.ISO && userSystemSwapsIntBackslashAndBackquote) {
      if (event.code === 'IntlBackslash') {
        return 'Backquote'
      } else if (event.code === 'Backquote') {
        return 'IntlBackslash'
      }
    }
    return event.code as KeyCode
  }

  reset() {
    this.prevPress = undefined
  }
}

// NOTE: good test data

// private index = 0
//   private testPresses: KeyPress[] = [
// mac
// {
//   print: '',
//   preview: '¨',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 0,
//   symbol: new Symbol('Dead', 'trema'),
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: '¨',
//   preview: '¨',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 1,
//   symbol: new Symbol('Dead', 'trema'),
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: '¨',
//   preview: '¨',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 1,
//   symbol: new Symbol('Dead', 'trema'),
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: '',
//   preview: '',
//   keyCode: 'Backspace',
//   cursorMove: 0,
//   symbol: new Symbol('Backspace'),
//   system: {
//     key: 'Backspace',
//     code: 'Backspace',
//   },
// },
// {
//   print: '',
//   preview: '',
//   keyCode: 'Backspace',
//   cursorMove: -1,
//   symbol: new Symbol('Backspace'),
//   system: {
//     key: 'Backspace',
//     code: 'Backspace',
//   },
// },
// {
//   print: '',
//   preview: '',
//   keyCode: 'Backspace',
//   cursorMove: -1,
//   symbol: new Symbol('Backspace'),
//   system: {
//     key: 'Backspace',
//     code: 'Backspace',
//   },
// },
// {
//   print: 'a',
//   preview: '',
//   keyCode: 'KeyA',
//   layeredKeyCode: new LayeredKeyCode('KeyA', Layer.Default),
//   cursorMove: 1,
//   symbol: new Symbol('a'),
//   system: {
//     key: 'a',
//     code: 'KeyA',
//   },
// },
// {
//   print: '',
//   preview: '',
//   keyCode: 'Backspace',
//   cursorMove: -1,
//   symbol: new Symbol('Backspace'),
//   system: {
//     key: 'Backspace',
//     code: 'Backspace',
//   },
// },
// {
//   print: 'ü',
//   preview: '',
//   keyCode: 'BracketLeft',
//   layeredKeyCode: new LayeredKeyCode('BracketLeft', Layer.Default),
//   cursorMove: 1,
//   symbol: new Symbol('ü'),
//   system: {
//     key: 'ü',
//     code: 'BracketLeft',
//   },
// },
// {
//   print: '',
//   preview: '¨',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 0,
//   symbol: new Symbol('Dead', 'trema'),
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: 'ü',
//   preview: '',
//   keyCode: 'KeyU',
//   layeredKeyCode: new LayeredKeyCode('KeyU', Layer.Default),
//   cursorMove: 1,
//   symbol: new Symbol('u'),
//   system: {
//     key: 'ü',
//     code: 'KeyU',
//   },
// },
// win
// {
//   print: '',
//   preview: '¨',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 0,
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: '¨¨',
//   preview: '',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 2,
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: '',
//   preview: '¨',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 0,
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: '',
//   preview: '',
//   keyCode: 'Backspace',
//   cursorMove: -1,
//   system: {
//     key: 'Backspace',
//     code: 'Backspace',
//   },
// },
// {
//   print: '',
//   preview: '',
//   keyCode: 'Backspace',
//   cursorMove: -1,
//   system: {
//     key: 'Backspace',
//     code: 'Backspace',
//   },
// },
// lnx
// {
//   print: '',
//   preview: '¨',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 0,
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: '¨',
//   preview: '',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 1,
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: '',
//   preview: '¨',
//   keyCode: 'BracketRight',
//   layeredKeyCode: new LayeredKeyCode('BracketRight', Layer.Default),
//   cursorMove: 0,
//   system: {
//     key: 'Dead',
//     code: 'BracketRight',
//   },
// },
// {
//   print: '',
//   preview: '',
//   keyCode: 'Backspace',
//   cursorMove: 0,
//   system: {
//     key: 'Backspace',
//     code: 'Backspace',
//   },
// },
// {
//   print: '',
//   preview: '',
//   keyCode: 'Backspace',
//   cursorMove: -1,
//   system: {
//     key: 'Backspace',
//     code: 'Backspace',
//   },
// },
