import { getUserLanguage } from '@/helpers/user-agent-utils'
import de from '@/i18n/de.json'
import en from '@/i18n/en.json'
import es from '@/i18n/es.json'
import fr from '@/i18n/fr.json'
import it from '@/i18n/it.json'
import ru from '@/i18n/ru.json'
import dayjs from 'dayjs'
import { createI18n } from 'vue-i18n'

export enum UserLocale {
  en = 'en',
  es = 'es',
  de = 'de',
  ru = 'ru',
  fr = 'fr',
  it = 'it',
}

export const explicitUserLocales: UserLocale[] = Object.values(UserLocale).filter((l) => l !== 'en')

export const localizedLandingRoute = (userLocale: UserLocale) => {
  const localeParam = userLocale === 'en' ? '' : userLocale
  return '/' + localeParam
}

export const navigateToLanding = (locale: UserLocale) => {
  window.location.assign(localizedLandingRoute(locale))
}

/** ADD NEW LANG: CHECKLIST
 * [ ] add global translations (messages)
 * [ ] wpm/cpm default speed metric logic — localizedSpeedUnit()
 * [ ] dayjs
 * [ ] all the LocalizedText-type values
 * [ ] all <lang>.json files
 * [ ] vite.config
 */

export type LocalizedText = Record<UserLocale, string>

// en-GB -> en
// ru -> ru
// en -> en
// fr -> en
export const resolveToSupportedLocale = (userLocale: string): UserLocale => {
  const allSupportedLocales = Object.values(UserLocale)

  if (allSupportedLocales.includes(userLocale as UserLocale)) {
    return userLocale as UserLocale
  }

  const baseLocale = userLocale.split('-')?.[0] as UserLocale
  if (allSupportedLocales.includes(baseLocale)) {
    return baseLocale
  }

  // special cases
  // pt -> es (Portuguese speakers generally understand Spanish fairly well)
  if (userLocale.startsWith('pt')) {
    return UserLocale.es
  }
  // uk -> ru (same for Ukrainians)
  if (userLocale.startsWith('uk')) {
    return UserLocale.ru
  }

  return UserLocale.en
}

const customRussianPluralRule = (choice: number, choicesLength: number) => {
  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return 0
}

export const i18n = createI18n({
  legacy: false,
  locale: getUserLanguage(),
  fallbackLocale: 'en',
  missingWarn: true,
  fallbackWarn: true,
  pluralRules: {
    ru: customRussianPluralRule,
  },

  messages: {
    en,
    ru,
    es,
    de,
    fr,
    it,
  },
})

export const switchAppLocale = (to: UserLocale) => {
  i18n.global.locale.value = to
  dayjs.locale(to)
}

// Language title utils

const localesWithLangAlwaysCapitalized = ['en', 'de']
const localesWithSpecialGenitiveLanguageForm = ['ru']

const capitalizeLangTitleIfNeeded = (title: string, locale: UserLocale, lower: boolean) => {
  if (lower && !localesWithLangAlwaysCapitalized.includes(locale)) {
    return title.toLocaleLowerCase()
  }
  return title
}

export const getLangTitle = (code: string, lower = false) =>
  capitalizeLangTitleIfNeeded(i18n.global.t(`Language.${code}`), i18n.global.locale.value as UserLocale, lower)

// export const getGenitiveLangTitle = (code: string, lower = false) => {
//   const key = localesWithSpecialGenitiveLanguageForm.includes(i18n.global.locale.value) ? 'LanguageGenitive' : 'Language'
//   return capitalizeLangTitleIfNeeded(i18n.global.t(`${key}.${code}`), i18n.global.locale.value as UserLocale, lower)
// }

export const getNativeLangTitle = (code: string, lower = false) =>
  capitalizeLangTitleIfNeeded(i18n.global.messages.value[code as UserLocale].Language[code as UserLocale], code as UserLocale, lower)

export const localizedSpeedUnit = (locale: UserLocale): 'wpm' | 'cpm' => {
  const cpmByDefault: UserLocale[] = [UserLocale.de, UserLocale.ru]
  if (cpmByDefault.includes(locale)) {
    return 'cpm'
  }
  return 'wpm'
}
