import type { Chapter } from '@/course/course-types'
import type { LanguageCode } from '@/languages/languages-config'
import type { UserSettings } from '@/stores/userStore'
import Hotjar from '@hotjar/browser'
import mixpanel, { type Callback, type RequestOptions } from 'mixpanel-browser'
import { getCookieSettingsFromLocalStorage } from '../stores/cookieStore'
import type { KeyboardFormat } from './keyboards/KeyboardLayout'

export type AnalyticsEvents = {
  click_get_started: { from: 'hero' | 'nav' | 'pricing'; continue: boolean }

  course_setup_language: { langCode: LanguageCode }
  course_setup_format: { format: KeyboardFormat }
  course_setup_layout: { layoutId: string; isAuto: boolean }
  course_setup_layout_unsupported: {}

  lesson_complete: { chapter: Chapter; lessonId: string; globalIndex: number; cpm: number; accuracy: number }

  click_buy: {}
  checkout_start: {}
  payment_success: {}
  payment_cancel: {}

  login: {}
  register: {}
  click_business_edition_form: {}
}

type EventName = keyof AnalyticsEvents

export const logAnalyticsEvent = <E extends EventName>(event: E, params: AnalyticsEvents[E], optionsOrCallback?: RequestOptions | Callback) => {
  const analyticsAllowed = getCookieSettingsFromLocalStorage().isAnalyticsAllowed
  if (!analyticsAllowed) return

  mixpanel.track(event, params, optionsOrCallback)
}

export const logPageViewEvent = () => {
  const analyticsAllowed = getCookieSettingsFromLocalStorage().isAnalyticsAllowed
  if (!analyticsAllowed) return

  mixpanel.track_pageview()
}

// install

export const installHotjar = () => {
  if (import.meta.env.DEV) {
    console.log('Mock::installHotjar()')
  } else {
    const siteId = 5025462
    const hotjarVersion = 6
    Hotjar.init(siteId, hotjarVersion)
  }
}

export const installMixpanel = () => {
  const token = import.meta.env.DEV ? '89c0ad03eea0bab2de5cc1e12c2ae282' : 'd68da4fa18c9e428f23d83fb542960b1'
  mixpanel.init(token, {
    debug: import.meta.env.DEV,
    api_host: 'https://api-eu.mixpanel.com',
    persistence: 'localStorage',
    ignore_dnt: true,
  })
}

export type RegisteredUserData = UserSettings & {
  $name: string
  $email: string
  joinDate: string
  regDate: string
  hasFullAccess: boolean
}

export const identifyUserInMixpanel = (data: RegisteredUserData, id: string) => {
  const analyticsAllowed = getCookieSettingsFromLocalStorage().isAnalyticsAllowed
  if (!analyticsAllowed) return

  mixpanel.identify(id)
  mixpanel.people.set(data)
}

export const resetUserInMixpanel = () => {
  const analyticsAllowed = getCookieSettingsFromLocalStorage().isAnalyticsAllowed
  if (!analyticsAllowed) return

  mixpanel.reset()
}
