import { generateRandomPseudoWord } from '@/helpers/generator-utils'
import { Finger, Hand, homeRowMapping, logicalMapping, Row, threeRowsMapping, threeRowsMappingPerRow } from '@/helpers/keyboards/FingerMapping'
import type { TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { Layer } from '@/helpers/keyboards/Layer'
import { allLangConfig } from '@/languages/all-lang-config'
import { PunctuationMarkType, type PunctuationMetadata } from '@/languages/languages-config'
import { capitalize, random, sample, shuffle } from 'lodash-es'
import { LOWER_WORDS_LESSONS_COUNT } from './course-builder'
import { Chapter, LessonType, TrainerView, type Course } from './course-types'
import type { NumTrainingType } from './numbers/course-numbers-config'
import { generateNumTraining } from './numbers/course-numbers-lesson-texts'

const MAX_WORD_LEN = 6

const findNextWordIndex = (words: string[], lessonsPassed: number, lessonLength: number) => {
  const LESSON_LEN_VARIATION = 5
  const lessonStartIndex = (lessonLength + LESSON_LEN_VARIATION) * lessonsPassed
  let counter = 0
  let i = 0
  while (counter < lessonStartIndex) {
    counter += words[i].length + 1
    i++
  }
  return i
}

export const generateLesson = (course: Course, chapter: Chapter, lessonIndex: number) => {
  const chapters = course.content
  const layout = course.layout
  const lesson = chapters[chapter][lessonIndex]
  const language = allLangConfig[layout.languageCode]
  const dicts = language.dicts

  if (lesson.view === TrainerView.Info) {
    return ''
  }

  // length, remove possible - and '
  const words10kDict = dicts.words10k.filter(
    (w) => w.length <= MAX_WORD_LEN && w.split('').every((l) => language.lowerLetters.includes(l) || language.upperLetters.includes(l)),
  )
  const words1kDict = words10kDict.slice(0, 1000)

  let LESSON_LEN = lesson.view === TrainerView.RunningLine ? 60 : 150
  if (chapter === Chapter.HomeRow && lesson.view !== TrainerView.RunningLine) {
    LESSON_LEN = 100
  }

  if (lesson.type === LessonType.NewLetters) {
    let [firstChar, secondChar] = lesson.metadata.chars
    if (!secondChar) {
      const firstKeyCode = lesson.metadata.keyCodes[0] as TypeableKeyCode
      const finger = logicalMapping.getFinger(firstKeyCode)!
      const homeRowKeyCode = homeRowMapping.getKeys(finger.hand, finger.finger)![0]
      secondChar = layout.getChar(homeRowKeyCode as TypeableKeyCode, Layer.Default)
    }

    const patterns = ['fj jf', 'fjf jfj', 'ffj jjf', 'ffjj jjff', 'fjjf jffj', 'fjfj jfjf']
    return shuffle(patterns).join(' ').replaceAll('f', '[0]').replaceAll('j', '[1]').replaceAll('[0]', firstChar).replaceAll('[1]', secondChar)
  }

  if (lesson.type === LessonType.FocusOnNewLetters) {
    // need to use previously learned letters as well
    // try to use real words if possible
    // focus on current keys
    // 1 - take filtered words with maximum concentration of new letters
    // 2 - fill gaps with generated sequences

    const chars = lesson.metadata.chars as string[]

    let pastChars: string[] = []
    for (const ch in chapters) {
      let pastLessons = chapters[ch as Chapter]
      if ((ch as Chapter) === chapter) {
        pastLessons = pastLessons.slice(0, lessonIndex - 1)
      }

      pastChars = pastChars.concat(
        pastLessons
          .filter((l) => l.type === LessonType.NewLetters)
          .map((l) => l.metadata.chars)
          .flat(),
      )

      if ((ch as Chapter) === chapter) {
        break
      }
    }

    const knownChars = pastChars.concat(chars)

    const wordsWithKnownChars = words10kDict.filter((w) => {
      return w.length <= MAX_WORD_LEN && w.split('').every((char) => knownChars.includes(char))
    })
    const wordsWithChars = chars.map((c) => wordsWithKnownChars.filter((w) => w.includes(c)))
    // remove duplicated words
    for (let i = 1; i < wordsWithChars.length; i++) {
      const prevWords = wordsWithChars.slice(0, i).flat()
      wordsWithChars[i] = wordsWithChars[i].filter((w) => !prevWords.includes(w))
    }
    const flatWordsWithChar = wordsWithChars.flat()

    const generations = chars.map(() => '')
    for (let i = 0; i < chars.length; i++) {
      const neededLen = Math.round(LESSON_LEN / chars.length)
      while (generations[i].length < neededLen) {
        if (wordsWithChars[i].length) {
          // we can take real words
          const index = random(0, wordsWithChars[i].length - 1)
          const word = wordsWithChars[i][index]
          wordsWithChars[i].splice(index, 1)
          generations[i] += word + ' '
        } else {
          // need to generate to fill the gaps
          generations[i] += generateRandomPseudoWord(3, 5, pastChars, chars[i]) + ' '
        }
      }
    }

    // NOTE: the idea here is to
    // 1 - put real words first
    // 2 - alternate learning letters

    const generationWordArrays: string[][] = []

    for (let i = 0; i < generations.length; i++) {
      generationWordArrays.push(
        shuffle(generations[i].trim().split(' ')).toSorted((a, b) => Number(flatWordsWithChar.includes(b)) - Number(flatWordsWithChar.includes(a))),
      )
    }

    const result: string[] = []

    for (let i = 0; i < generationWordArrays[0].length; i++) {
      for (let j = 0; j < generationWordArrays.length; j++) {
        result.push(generationWordArrays[j][i])
      }
    }

    return result.filter((w) => !!w).join(' ')
  }

  if (lesson.type === LessonType.SingleFinger) {
    const keyCodes = threeRowsMapping.getKeys(lesson.metadata.hand, lesson.metadata.finger)
    const chars = keyCodes.map((k) => layout.getChar(k as TypeableKeyCode, Layer.Default)) as string[]
    let result = ''
    while (result.length < LESSON_LEN) {
      result += shuffle(chars).join('') + ' '
    }
    return result.trim()
  }

  if (lesson.type === LessonType.Ngram) {
    const bigram = lesson.metadata.ngram as string
    const content = [
      bigram,
      ...dicts.ngrams[3].filter((n) => n.includes(bigram)),
      ...dicts.ngrams[4].filter((n) => n.includes(bigram)),
      ...words10kDict.filter((w) => w.includes(bigram)),
    ]
    let result = ''
    let i = 0
    while (result.length < LESSON_LEN) {
      result += content[i] + ' '
      i++
    }
    return result.trim()
  }

  if (lesson.type === LessonType.PopularLowerWords) {
    let i = findNextWordIndex(words10kDict, lesson.metadata.index, LESSON_LEN)
    let result = ''
    while (result.length < LESSON_LEN) {
      result += words10kDict[i] + ' '
      i++
    }
    return result.trim()
  }

  if (lesson.type === LessonType.ShiftLettersRow) {
    const row = lesson.metadata.row as Row
    let result = ''
    for (const keyCode of threeRowsMappingPerRow[row]) {
      const char = layout.getChar(keyCode, Layer.Default)!
      const charShift = layout.getChar(keyCode, Layer.Shift)!
      result += `${char}${charShift} `
    }
    return result.trim()
  }

  if (lesson.type === LessonType.ShiftPerFinger) {
    let result = ''
    for (const finger of [Finger.Little, Finger.Ring, Finger.Middle, Finger.Index]) {
      for (const hand of [Hand.Left, Hand.Right]) {
        const keys = threeRowsMapping.getKeys(hand, finger)
        result +=
          shuffle(keys)
            .map((k) => layout.getChar(k as TypeableKeyCode, Layer.Shift))
            .join('') + ' '
      }
    }
    return result.trim()
  }

  if (lesson.type === LessonType.ShiftLettersRandom) {
    let i = findNextWordIndex(words10kDict, lesson.metadata.index + LOWER_WORDS_LESSONS_COUNT, LESSON_LEN)
    let result = ''
    while (result.length < LESSON_LEN) {
      result += capitalize(words10kDict[i]) + ' '
      i++
    }
    return result.trim()
  }

  if (lesson.type === LessonType.PunctuationMark) {
    const char = lesson.metadata.char as string
    const left = char[0]
    const right = char[1] ?? left
    const markType = lesson.metadata.type as PunctuationMarkType

    let result = ''
    let fn: () => string
    switch (markType) {
      case PunctuationMarkType.Bracket:
      case PunctuationMarkType.EndBracket:
        fn = () => `${left}${sample(words1kDict)}${right} `
        break
      case PunctuationMarkType.BracketSpaced:
        fn = () => `${left} ${sample(words1kDict)} ${right} `
        break
      case PunctuationMarkType.Comma:
      case PunctuationMarkType.End:
        fn = () => `${sample(words1kDict)}${char} `
        break
      case PunctuationMarkType.CommaSpaced:
      case PunctuationMarkType.EndSpaced:
      case PunctuationMarkType.Dash:
        fn = () => `${sample(words1kDict)} ${char} `
        break
      case PunctuationMarkType.Hyphen:
        fn = () => `${sample(words1kDict)}${char}${sample(words1kDict)} `
        break
    }
    while (result.length < LESSON_LEN) {
      result += fn()
    }
    return result.trim()
  }

  if (lesson.type === LessonType.AllPunctuation) {
    const [MIN_SENTENCE_LEN, MAX_SENTENCE_LEN] = [4, 8]
    const endPunctuation = lesson.metadata.punctuation.filter(
      (p: PunctuationMetadata) =>
        p.type === PunctuationMarkType.End || p.type === PunctuationMarkType.EndSpaced || p.type === PunctuationMarkType.EndBracket,
    )
    const mainPunctuation = lesson.metadata.punctuation.filter(
      (p: PunctuationMetadata) =>
        p.type !== PunctuationMarkType.End && p.type !== PunctuationMarkType.EndSpaced && p.type !== PunctuationMarkType.EndBracket,
    )

    const selectNRandomWords = (n: number) => {
      let result: string[] = []
      while (result.length < n) {
        result.push(sample(words1kDict)!)
      }
      return result
    }

    let result = ''
    let prevRandomMiddle = ''
    while (result.length < LESSON_LEN) {
      const randomSentenceLength = random(MIN_SENTENCE_LEN, MAX_SENTENCE_LEN)
      let words = selectNRandomWords(randomSentenceLength)
      words[0] = capitalize(words[0])

      let randomMiddle = sample(mainPunctuation)!
      if (prevRandomMiddle === randomMiddle.char) {
        randomMiddle = sample(mainPunctuation)!
      }
      prevRandomMiddle = randomMiddle.char

      const char = randomMiddle.char
      const left = char[0]
      const right = char[1] ?? left

      const wordIndex = random(words.length - 2)
      const word = words[wordIndex]

      // if (randomMiddle.type === PunctuationMarkType.Apostrophe) {
      //   words[wordIndex] = word.endsWith('s') ? word + "'" : word + "'s"
      // } else
      if (randomMiddle.type === PunctuationMarkType.Hyphen) {
        words.splice(wordIndex, 2, words[wordIndex] + char + words[wordIndex + 1])
      } else if (randomMiddle.type === PunctuationMarkType.Bracket) {
        const endWordIndex = random(wordIndex, words.length - 1)
        const count = endWordIndex - wordIndex + 1
        words.splice(wordIndex, count, left + words.slice(wordIndex, endWordIndex + 1).join(' ') + right)
      } else if (randomMiddle.type === PunctuationMarkType.BracketSpaced) {
        const endWordIndex = random(wordIndex, words.length - 1)
        const count = endWordIndex - wordIndex + 1
        words.splice(wordIndex, count, left + ' ' + words.slice(wordIndex, endWordIndex + 1).join(' ') + ' ' + right)
      } else if (randomMiddle.type === PunctuationMarkType.Comma) {
        words[wordIndex] = words[wordIndex] + char
      } else if (randomMiddle.type === PunctuationMarkType.CommaSpaced || randomMiddle.type === PunctuationMarkType.Dash) {
        words[wordIndex] = words[wordIndex] + ' ' + char
      }

      const randomEnding = sample(endPunctuation)!
      if (randomEnding.type === PunctuationMarkType.End) {
        words[words.length - 1] = words[words.length - 1] + randomEnding.char
      } else if (randomEnding.type === PunctuationMarkType.EndSpaced) {
        words[words.length - 1] = words[words.length - 1] + ' ' + randomEnding.char
      } else if (randomEnding.type === PunctuationMarkType.EndBracket) {
        const char = randomEnding.char
        const left = char[0]
        const right = char[1] ?? left
        words[0] = left + words[0]
        words[words.length - 1] = words[words.length - 1] + right
      }

      result += words.join(' ') + ' '
    }
    return result.trim()
  }

  if (lesson.type === LessonType.Numbers) {
    return generateNumTraining(lesson.metadata.trainingType as NumTrainingType, words1kDict, LESSON_LEN)
  }

  // if (lesson.type === LessonType.Problems) {
  //   // TODO: needs state access...
  //   let result = ''
  //   for (let i = 0; i < LESSON_LEN; i++) result += 'l'
  //   return result.trim()
  // }

  throw new Error('Unknown lesson type')
}
