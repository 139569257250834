<script setup lang="ts">
import { useMagicKeys } from '@vueuse/core'
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'

type Props = {
  warnBeforeClose?: boolean
  text?: string
}
const props = withDefaults(defineProps<Props>(), {
  warnBeforeClose: false,
})

const { KeyA, KeyS, KeyD, KeyF } = useMagicKeys()

const highlightedKey = ref(-1)

const keyAnimationTimeout = ref<ReturnType<typeof setTimeout> | undefined>(undefined)

const animationFn = (delay?: number) => {
  let nextDelay = 100
  if (highlightedKey.value === -2) {
    // animation restart after manual presses highlight
    nextDelay = 1000
  } else if (highlightedKey.value === -1) {
    // start cycle
    nextDelay = 400
  } else if (highlightedKey.value === 3) {
    // hodling all keys
    nextDelay = 400
  }

  keyAnimationTimeout.value = setTimeout(() => {
    const newVal = highlightedKey.value === 3 ? -1 : Math.max(0, highlightedKey.value + 1)
    highlightedKey.value = newVal
    animationFn()
  }, delay ?? nextDelay)
}

watchEffect(() => {
  if (KeyA.value || KeyS.value || KeyD.value || KeyF.value) {
    highlightedKey.value = -2
    clearTimeout(keyAnimationTimeout.value)
    keyAnimationTimeout.value = undefined
  } else if (!keyAnimationTimeout.value) {
    animationFn()
  }
})

const onBeforeUnload = (event: Event) => {
  event.preventDefault()
}

onMounted(() => {
  if (props.warnBeforeClose) {
    window.addEventListener('beforeunload', onBeforeUnload)
  }
})

onUnmounted(() => {
  clearTimeout(keyAnimationTimeout.value)
  if (props.warnBeforeClose) {
    window.removeEventListener('beforeunload', onBeforeUnload)
  }
})
</script>

<template>
  <div class="loading-view">
    <div class="illustration">
      <div class="key kinda-mono" :class="{ highlighted: KeyA || highlightedKey >= 0 }">A</div>
      <div class="key kinda-mono" :class="{ highlighted: KeyS || highlightedKey >= 1 }">S</div>
      <div class="key kinda-mono" :class="{ highlighted: KeyD || highlightedKey >= 2 }">D</div>
      <div class="key kinda-mono KeyF" :class="{ highlighted: KeyF || highlightedKey >= 3 }">F</div>
    </div>
    <div class="text" v-if="text">{{ text }}</div>
  </div>
</template>

<style lang="scss" scoped>
.loading-view {
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: var(--c-background);
  padding: var(--s-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--s-lg);
}

.illustration {
  display: flex;
  align-items: flex-end;
  height: 40px;
  gap: var(--s-xs);

  .key {
    width: 40px;
    height: 40px;
    display: inline-grid;
    place-items: center;
    border: 1px solid var(--c-divider);
    border-radius: var(--br-md);
    font-weight: 500;
    box-shadow: inset 0 -2px var(--c-divider);
    position: relative;

    &.KeyF:after {
      content: url('');
      position: absolute;
      width: 12px;
      height: 2px;
      background-color: var(--c-divider);
      bottom: 6px;
      border-radius: 10px;
      left: 0;
      right: 0;
      margin: auto;
    }

    &.highlighted {
      height: 38px;
      background: var(--c-surface);
    }
  }
}

.text {
  font-size: var(--fz-lg);
  max-width: 400px;
  text-align: center;
}
</style>
