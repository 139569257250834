<script setup lang="ts">
import type { LessonCompletionMetadata } from '@/types/CourseCompletionStats'
import { useI18n } from 'vue-i18n'
import AccuracyColorsBreakdown from './AccuracyColorsBreakdown.vue'

type Data = LessonCompletionMetadata & { accuracy: string; speed: string; date: string; color: string }
type Props = {
  data: Data
}
defineProps<Props>()

const { t } = useI18n()
</script>

<template>
  <div class="completion-tooltip">
    <div class="date">
      <svg>
        <use href="#icon-calendar"></use>
      </svg>
      <span>{{ data.date }}</span>
    </div>
    <div class="metric">
      <div class="value" :style="{ color: data.color }">{{ data.accuracy }}</div>
      <div class="label">{{ t('accuracy') }}</div>
    </div>
    <div class="metric">
      <div class="value">{{ data.speed }}</div>
      <div class="label">{{ t('speedLower') }}</div>
    </div>
    <AccuracyColorsBreakdown style="font-size: 10px" :value="data.accuracyPct" />
  </div>
</template>

<style lang="scss" scoped>
.completion-tooltip {
  font-size: 12px;
  font-weight: 500;

  .date {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-bottom: 6px;
    margin-bottom: 6px;
    border-bottom: 1px solid var(--c-divider);

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .metric {
    display: flex;
    gap: 4px;
    margin-bottom: 2px;
    .label {
      color: var(--c-text-tertiary);
    }
  }
}
</style>
