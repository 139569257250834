import type { LessonCoords } from '@/course/course-types'
import { identifyUserInMixpanel } from '@/helpers/analytics'
import { fullacc, genstat } from '@/helpers/generate-test-stats'
import { safeLocalStorage } from '@/helpers/local-storage'
import { localStorageKey } from '@/helpers/main-config'
import { ENV } from '@/helpers/main-utils'
import { getUserLocale } from '@/helpers/user-agent-utils'
import { debouncedUpdateUserData, fetchUserData, setUserData } from '@/plugins/firebase/firestore'
import { localizedSpeedUnit, resolveToSupportedLocale, switchAppLocale, UserLocale } from '@/plugins/i18n'
import { type SerializedCourseStats, type StringifiedSerializedCourseStats } from '@/types/CourseStats'
import { useLocalStorage } from '@vueuse/core'
import dayjs, { Dayjs } from 'dayjs'
import { cloneDeep, merge } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAppStore } from './appStore'
import { useCourseStore } from './courseStore'

export type FingerMappingId = 'optimized' | 'logical'

export type AuthData = {
  id: string
  email: string
  name: string
  registered: string | undefined
}

export type UserSettings = {
  // name: string
  isKeyboardHintShown: boolean
  isFingerHintShown: boolean
  speedUnit: 'wpm' | 'cpm'
  fingerMapping: FingerMappingId
  isFingerMappingHighlighted: boolean
  currentCourseIndex: number
  isFeedbackFormClosed: boolean
  locale: UserLocale
}

export const defaultUserSettings: UserSettings = {
  isKeyboardHintShown: true,
  isFingerHintShown: true,
  fingerMapping: 'logical',
  isFingerMappingHighlighted: true,
  currentCourseIndex: -1,
  isFeedbackFormClosed: false,
  locale: resolveToSupportedLocale(getUserLocale()),
  speedUnit: localizedSpeedUnit(resolveToSupportedLocale(getUserLocale())),
}

type UserData = {
  joined: string // dayjs
  name?: string
  hasFullAccess: boolean
  settings: UserSettings
  courses?: Record<
    // layout definition string id
    string,
    StringifiedSerializedCourseStats | SerializedCourseStats
  >
}
const defaultUserData: UserData = {
  joined: dayjs().toISOString(),
  hasFullAccess: false,
  settings: defaultUserSettings,
}

export const useUserStore = defineStore('user', () => {
  const { t } = useI18n()
  const courseStore = useCourseStore()
  const appStore = useAppStore()

  const initialized = ref(false)
  const allUserData = ref<UserData>(cloneDeep(defaultUserData))

  const joined = computed<Dayjs>(() => dayjs(allUserData.value.joined))
  const name = computed<string>(() => allUserData.value.name ?? authData.value?.name ?? '')
  const hasFullAccess = computed(() => allUserData.value.hasFullAccess)
  const settings = computed<UserSettings>(() => allUserData.value.settings)

  const authData = ref<null | AuthData>(null)
  const currentLesson = useLocalStorage<LessonCoords | null>(localStorageKey.currentLesson, null, {
    serializer: {
      read: (v: any) => (v ? JSON.parse(v) : null),
      write: (v: any) => JSON.stringify(v),
    },
  })

  // NOTE: come up with something better; now it will depreciate stats value
  // const demoData = computed(() => {
  //   return layoutStore.current.stats.calendar.isEmpty() && layoutStore.layouts.length < 2
  // })
  const demoData = false

  const statsExists = computed(() => {
    return !courseStore.current.stats.calendar.isEmpty() || courseStore.courses.length >= 2
  })

  // fetching from server, parsing

  const init = async (data?: AuthData) => {
    initialized.value = false
    authData.value = data ?? null

    let userData: UserData
    const localUserData = JSON.parse(safeLocalStorage.get(localStorageKey.userData) ?? '{}')

    // check for incompatible legacy data (before Sep 24 release)
    // this is only for unregistered users, no need to push changes to Firestore
    if (!!localUserData.layouts) {
      reset()
      init()
      return
    }

    if (authData.value) {
      // authorized, try to fetch up to date data from Firebase

      appStore.showLoader('authData', t('fetchingDataLoadingText'))
      const cloudUserData = await fetchUserData(authData.value.email)
      appStore.hideLoader('authData')
      if (cloudUserData) {
        // just update from Firebase
        merge(allUserData.value, cloudUserData)
      } else {
        // first registration — migrate local data to Firebase, if any
        merge(allUserData.value, localUserData)
        allUserData.value.name = authData.value.name
        await setUserData(authData.value.email, allUserData.value)
        safeLocalStorage.remove(localStorageKey.userData)
      }
    } else {
      merge(allUserData.value, localUserData)
    }

    if (data) {
      identifyUserInMixpanel(
        {
          $name: allUserData.value.name ?? data.name,
          $email: data.email,
          hasFullAccess: hasFullAccess.value,
          joinDate: allUserData.value.joined,
          regDate: dayjs(data.registered).toISOString(),
          ...settings.value,
        },
        data.id,
      )
    }

    // init user courses
    const userCourses = allUserData.value.courses
    // debugger
    if (userCourses) {
      courseStore.init(Object.entries(userCourses), allUserData.value.settings.currentCourseIndex)
    }

    switchAppLocale(settings.value.locale)
    initialized.value = true

    // ADMIN-ONLY: ability to generate stats
    if (ENV.DEV || (authData.value && ENV.VITE_ADMIN_ACCOUNTS.split(',').includes(authData.value.email))) {
      // @ts-expect-error hacky by design
      window.fullacc = fullacc
      // @ts-expect-error hacky by design
      window.genstat = genstat
    }
  }

  const reset = () => {
    // on logout
    safeLocalStorage.remove(localStorageKey.userData)
    courseStore.reset()
    allUserData.value = cloneDeep(defaultUserData)
  }

  const waitForInit = (callback: () => void) => {
    if (initialized.value) {
      callback()
      return
    }
    const unwatch = watch(initialized, (value) => {
      if (value) {
        callback()
        unwatch()
      }
    })
  }

  // serialization, pushing to server
  const saveData = (data: Partial<UserData>) => {
    allUserData.value = Object.assign(allUserData.value, data)

    if (authData.value) {
      // console.log('☁️ save data to cloud')
      debouncedUpdateUserData(authData.value.email, data)
    } else {
      // console.log('💾 save data to local storage')
      safeLocalStorage.set(localStorageKey.userData, JSON.stringify(allUserData.value))
    }
  }

  const saveSettings = (newSettings: Partial<UserSettings>) => {
    if (!initialized.value) {
      waitForInit(() => saveSettings(newSettings))
      return
    }

    const mergedSettings = Object.assign(allUserData.value.settings, newSettings)
    saveData({ settings: mergedSettings })
  }

  const saveCourseStats = (courseId: string, data: StringifiedSerializedCourseStats) => {
    const mergedCourses = Object.assign(allUserData.value.courses ?? {}, { [courseId]: data })
    saveData({ courses: mergedCourses })
  }

  return {
    allUserData,
    joined,
    name,
    saveData,
    saveSettings,
    saveCourseStats,
    demoData,
    statsExists,
    init,
    reset,
    waitForInit,
    initialized,
    authData,
    settings,
    hasFullAccess,
    currentLesson,
  }
})
