<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'
import Keyboard from '@/components/Keyboard.vue'
import ShortcutButton from '@/components/ShortcutButton.vue'
import TipModal from '@/components/TipModal.vue'
import { shortcutsConfig } from '@/configs/shortcuts-config'
import { buildCourse } from '@/course/course-builder'
import { generateLesson } from '@/course/course-lesson-texts'
import { getChapterTitle, getLessonTitle } from '@/course/course-lesson-titles'
import { tipToLessonMapping, type LessonTip } from '@/course/course-tips'
import { Chapter, TrainerView } from '@/course/course-types'
import { logAnalyticsEvent } from '@/helpers/analytics'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { Char } from '@/helpers/keyboards/KeyChar'
import { toFixed } from '@/helpers/main-utils'
import { ACC_COLORS, accuracyColor } from '@/helpers/metric-color-scales'
import type { KeyPress } from '@/helpers/press-helper'
import {
  LimitType,
  Trainer,
  TrainingPhase,
  typingTextFontSizePx,
  typingTextRowLength,
  typingTextWidthPx,
  type CharState,
  type FullTypingResult,
} from '@/helpers/Trainer'
import { useCourseStore } from '@/stores/courseStore'
import { useTrainingStore } from '@/stores/trainingStore'
import { useUserStore } from '@/stores/userStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { CharTypingResult } from '@/types/typing-result/CharTypingResult'
import { useDocumentVisibility } from '@vueuse/core'
import { computed, onBeforeMount, onUnmounted, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

onBeforeMount(() => {
  document.body.classList.add('typing')
  document.documentElement.scrollTo(0, 0)
})
onUnmounted(() => {
  document.body.classList.remove('typing')
})

const { t } = useI18n()
const router = useRouter()
const userStore = useUserStore()
const trainingStore = useTrainingStore()
const courseStore = useCourseStore()
const lessonInfo = userStore.currentLesson!

const trainer = ref<Trainer>(new Trainer(courseStore.current.layout))
const course = buildCourse(courseStore.current.layout)!
const lesson = course.content[lessonInfo.chapter][lessonInfo.index]

if (!userStore.hasFullAccess && !lesson.availableOnTrial) {
  router.push({ name: 'home' })
}

// text.value = 'übc'
let text = ref(generateLesson(course, lessonInfo.chapter, lessonInfo.index))

// TODO
// const trainingDuration = 1 * 5 * 1000
// const trainingDuration = userStore.settings.trainingDurationMs

trainer.value.init({
  text: text.value,
  limitType: LimitType.Unlimited,
  limit: 0,
  lessonCoords: lessonInfo,
  view: lesson.view,
  onFinish: (result: FullTypingResult) => {
    const typingResult = CharTypingResult.fromFullTypingResult(result, courseStore.current.layout.os)
    trainingStore.lastTypingResult = result

    courseStore.saveTraining(result, typingResult)

    const chapterIndex = Object.values(Chapter).indexOf(lessonInfo.chapter)
    const lessonIndex = lessonInfo.index
    const globalIndex = chapterIndex * 100 + lessonIndex
    logAnalyticsEvent('lesson_complete', {
      chapter: lessonInfo.chapter,
      lessonId: lesson.uniqueId,
      globalIndex,
      cpm: toFixed(typingResult.speed.cpm, 2),
      accuracy: toFixed(typingResult.accuracy.percentage, 2),
    })

    router.push({ name: 'result' })
  },
})

// auto pause if user switches out of TS website view
const visibility = useDocumentVisibility()
watchEffect(() => {
  if (visibility.value === 'hidden' && trainer.value.phase === TrainingPhase.Running) {
    trainer.value.pause()
  }
})

// PRO TIPS
const completionStats = computed(() => courseStore.current.stats.completion)
const lessonTip = computed(() => Object.entries(tipToLessonMapping).find(([k, v]) => v === lesson.uniqueId)?.[0] as LessonTip | undefined)
const showTip = ref(!!lessonTip.value && !completionStats.value.isLessonCompleted(lesson.uniqueId))

if (showTip.value) {
  trainer.value.pause()
} else {
  trainer.value.start()
}

const onTipDismiss = () => {
  showTip.value = false
  trainer.value.start()
}

// Shortcuts

const showShortcutHint = ref(false)

const executeIfPaused = (func: Function, closeShortcutHint = true) => {
  if (showTip.value) {
    return
  }

  if (trainer.value.phase === TrainingPhase.Paused) {
    func()
    if (closeShortcutHint) {
      showShortcutHint.value = false
    }
  }
}

const shortcuts = {
  dismissTip: new KeyboardShortcut(shortcutsConfig.continue, () => {
    if (showTip.value) {
      onTipDismiss()
    }
  }),

  pause: new KeyboardShortcut(shortcutsConfig.discard, () => {
    trainer.value.pause()
  }),

  resume: new KeyboardShortcut(shortcutsConfig.resume, () => {
    executeIfPaused(() => trainer.value.resume())
  }),

  restart: new KeyboardShortcut(shortcutsConfig.restart, () => {
    trainer.value.restart(generateLesson(course, lessonInfo.chapter, lessonInfo.index))
  }),
}

useKeyboardShortcuts(Object.values(shortcuts))

// Misc

onUnmounted(() => {
  trainer.value.clearInterval()
})

const firstWrongIndex = ref(-1)

// Handle press events from Keyboard
const press = (keyPress: KeyPress) => {
  trainer.value.registerPress(keyPress)
  firstWrongIndex.value = trainer.value.firstWrongIndex()
}

const progress = computed(() => {
  if (trainer.value.phase === TrainingPhase.Finished) {
    return 100
  }
  if (!trainer.value) {
    return 0
  }

  // if (training.limitType === LimitType.Duration) {
  // return formatDuration(trainingDuration / 1000 - trainer.value.elapsedTime('s'))
  // } else {
  const totalChars = trainer.value.textLength()
  const cursor = trainer.value!.correctCursorIndex
  return totalChars ? (cursor / totalChars) * 100 : 0
  // }
})

// curr acc
const currAccPct = computed(() => {
  return trainer.value.accuracy()
})

const minVisibleAccValue = 85

const currAccTranslate = computed(() => {
  // hardcoded pixels, good until keyboard size & typing text width is fixed
  const min = -9
  const max = 289

  if (currAccPct.value <= minVisibleAccValue) {
    return max
  }
  const barCurrAccPct = 1 - (currAccPct.value - minVisibleAccValue) / (100 - minVisibleAccValue)
  return (max - min) * barCurrAccPct + min
})
const currAccColor = computed(() => {
  return accuracyColor(currAccPct.value)
})
const [red, yellow, green] = ACC_COLORS()

// for running line
const emptyCharState: CharState = {
  index: 0,
  toType: new Char(' '),
  typed: null,
  typedDead: false,
}

const charState = computed(() => {
  if (lesson.view === TrainerView.ThreeLines) {
    return trainer.value.getTextState(3)
  }

  const state = trainer.value.getTextState(1).flat()
  const correctCursor = trainer.value.correctCursorIndex
  const halfCharCount = typingTextRowLength[TrainerView.RunningLine] / 2
  const toFillCount = Math.max(halfCharCount - correctCursor, 0)

  const fillingBefore = [...Array(toFillCount)].map(() => ({ ...emptyCharState }))
  const fillingAfter = [...Array(halfCharCount)].map(() => ({ ...emptyCharState }))

  const sliceFrom = Math.max(correctCursor - halfCharCount, 0)
  return [[...fillingBefore, ...state, ...fillingAfter].slice(sliceFrom, sliceFrom + typingTextRowLength[TrainerView.RunningLine])]
})

// css
const textFontSize = computed(() => typingTextFontSizePx[lesson.view])
const textRowLength = computed(() => typingTextRowLength[lesson.view])

// auto-pause on keyboard settings open
const isKeyboardDropdownOpen = ref(false)
watchEffect(() => {
  if (isKeyboardDropdownOpen.value) {
    trainer.value.pause()
  }
})
</script>

<template>
  <div class="training-title">{{ getChapterTitle(lessonInfo.chapter) }}. {{ getLessonTitle(lesson) }}</div>
  <section class="trainer" :class="{ paused: trainer.isPaused() }">
    <teleport to="#app-header">
      <AppHeader>
        <template #middle>
          <div class="shortcuts">
            <ShortcutButton :disabled="trainer.isPaused()" :text="t('pause')" :shortcut="shortcuts.pause" />
            <ShortcutButton :text="t('restart')" :shortcut="shortcuts.restart" />
            <DropdownMenu variant="keyboardSettings" v-model="isKeyboardDropdownOpen" />
          </div>
        </template>
      </AppHeader>
      <div class="progress">
        <div class="inner" :style="{ width: `${progress}%` }"></div>
      </div>
    </teleport>

    <div class="text-wrapper" :class="{ 'running-line': lesson.view === TrainerView.RunningLine }">
      <div v-for="(row, i) in charState" :key="i" class="row">
        <div
          v-for="(charState, j) in row"
          :key="j"
          class="cell"
          :class="{
            correct: charState.typed && charState.typed.isEqual(charState.toType),
            wrong: charState.typed && firstWrongIndex !== -1 && firstWrongIndex <= charState.index,
            cursor: trainer.cursorIndex === charState.index && !trainer.isPaused(),
            'last-typed': trainer.cursorIndex - 1 === charState.index,
            'last-in-row': row.length - 1 === j,
            'typed-dead': charState.typedDead,
            'left-side': j < textRowLength / 2,
          }"
          :style="{ flex: `0 0 ${100 / textRowLength}%` }"
        >
          <div class="inner">
            {{ charState.typed?.value || charState.toType.value }}
          </div>
        </div>
      </div>

      <div v-show="trainer.isPaused()" class="pause-view">
        <div v-if="!showTip" class="title">
          {{ t('Typing.pressSpaceToResume') }}
        </div>
      </div>
    </div>
    <TipModal v-if="showTip && lessonTip" :shortcut="shortcuts.dismissTip" :tip="lessonTip" @dismiss="onTipDismiss" />

    <div class="keyboard-wrapper">
      <Keyboard
        :keyToPress="trainer.keyToPress()"
        :handle-presses="!trainer.isPaused()"
        :show-hands="userStore.settings.isFingerHintShown"
        :show-keyboard="true"
        @press="press"
        :show-finger-mapping="userStore.settings.isFingerMappingHighlighted"
      />

      <div class="accuracy-indicator">
        <div
          class="indicator"
          v-tippy="{ content: t('accuracy'), placement: 'left' }"
          :style="{ transform: `translateY(${currAccTranslate}px)`, color: currAccColor }"
        >
          <div class="value">{{ currAccPct.toFixed(1) }}%</div>
          <div class="circle"></div>
        </div>
        <div class="bar" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
:global(body.typing) {
  overflow: hidden;
}

.progress {
  top: 0;
  position: fixed;
  width: 100%;
  .inner {
    height: 6px;
    background: var(--c-primary);
    transition: width 0.2s ease;
  }
}

.shortcuts {
  display: flex;
  align-items: center;
  gap: var(--s-lg);

  .left,
  .right {
    flex: 1;
  }
  .left {
    text-align: right;
  }

  .divider {
    height: 50%;
    width: 1px;
    background-color: var(--c-divider);
    margin: 0 var(--s-lg);
    display: none;
  }
}

.training-title {
  line-height: 1;
  font-size: var(--fz-sm);
  position: absolute;
  bottom: var(--grid-cell);
  left: var(--grid-cell);
  color: var(--c-text-tertiary);
}

.trainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: v-bind(typingTextWidthPx);
  justify-content: center;
  position: relative;

  .text-wrapper {
    margin-top: auto;
    margin-bottom: var(--s-xl);
    display: flex;
    flex-direction: column;
    background-color: var(--c-background);
    font-size: v-bind(textFontSize);
    position: relative;

    .row {
      flex: 1;
      display: flex;
      justify-content: flex-start;

      .cell {
        color: var(--c-text-tertiary);

        display: flex;
        flex-direction: column;
        padding: 0.2em 0;

        .inner {
          height: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 1em;
          font-family: var(--ff-mono);
          font-weight: 400;
        }

        &.correct {
          .inner {
            color: var(--c-text-primary);
          }
        }

        &.wrong {
          background-color: var(--c-danger-bg);
          .inner {
            color: var(--c-danger-text);
          }
        }

        &.typed-dead {
          .inner {
            color: var(--c-primary);
          }
        }

        &.cursor {
          position: relative;

          &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: calc(100% - 2px);
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 10px;
            background: var(--c-primary);
          }
        }
      }
    }

    .pause-view {
      background: rgb(var(--c-background-rgb) / 0.7);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
      height: calc(100% + 32px);
      top: -16px;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      z-index: 11;
      font-size: var(--fz-lg);
    }

    &.running-line {
      .row {
        border-top: 1px solid var(--c-divider);
        border-bottom: 1px solid var(--c-divider);

        .cell {
          position: relative;
          &.left-side {
            background-color: var(--c-surface);
          }
          &.cursor {
            &:after {
              display: none;
            }
          }
          &:first-of-type,
          &:last-of-type {
            &:before {
              content: url('');
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 300%;
              margin: -1px;
              z-index: 10;
            }
          }
          &:first-of-type {
            &:before {
              background: linear-gradient(-90deg, rgb(var(--c-background-rgb) / 0) 0%, rgb(var(--c-background-rgb) / 100) 100%);
            }
          }
          &:last-of-type {
            &:before {
              right: 0;
              left: unset;
              background: linear-gradient(90deg, rgb(var(--c-background-rgb) / 0) 0%, rgb(var(--c-background-rgb) / 100) 100%);
            }
          }
        }
      }
    }
  }

  &.paused {
    .text-wrapper {
      .pause-view {
        display: flex;
      }
    }
  }

  .keyboard-wrapper {
    position: relative;
    margin-bottom: auto;
    --vertical-padding: 4px;
    --bar-height: calc(100% - var(--vertical-padding) * 2 - var(--keyboard-size-unit) * 10);

    .accuracy-indicator {
      position: absolute;
      top: var(--vertical-padding);
      height: var(--bar-height);
      right: calc(100% + var(--s-md));
      width: auto;
      display: flex;
      align-items: flex-start;

      .indicator {
        display: flex;
        width: auto;
        align-items: center;
        gap: var(--s-xs);
        margin-right: -5px;
        transition: transform 0.2s ease;

        .value {
          font-weight: 700;
          font-size: var(--fz-sm);
          color: inherit;
        }

        .circle {
          width: 7px;
          height: 7px;
          background: currentColor;
          border-radius: 100%;
        }
      }

      .bar {
        width: 3px;
        height: 100%;
        border-radius: 100px;
        background: linear-gradient(
          0deg,
          v-bind(red) 0%,
          v-bind(red) 30%,
          v-bind(yellow) 36%,
          v-bind(yellow) 64%,
          v-bind(green) 70%,
          v-bind(green) 100%
        );
      }
    }
  }
}
</style>
