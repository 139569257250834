import type { ShortcutConfig } from '@/configs/shortcuts-config'
import { isMacOS } from '@/helpers/user-agent-utils'
import { ShortcutVariant, getLayoutsMetadata } from '@/layouts/layouts-config'
import { useCourseStore } from '@/stores/courseStore'

export type ModifierKey = 'Meta' | 'Shift' | 'Alt'

export class KeyboardShortcut {
  public value: Record<string, ShortcutConfig>

  constructor(
    shortcutConfig: ShortcutConfig | Record<string, ShortcutConfig>,
    public action: (event: KeyboardEvent | MouseEvent) => void,
    public modalId: string | null = null,
  ) {
    if (!shortcutConfig.char) {
      // record
      this.value = shortcutConfig as Record<string, ShortcutConfig>
    } else {
      // single default variant
      this.value = {
        [ShortcutVariant.Default]: shortcutConfig as ShortcutConfig,
      }
    }
  }

  get char(): string {
    const courseStore = useCourseStore()
    const meta = getLayoutsMetadata(courseStore.current.definition.os)[courseStore.current.definition.layoutId]
    if (meta.shortcutsVariant && meta.shortcutsVariant in this.value) {
      return this.value[meta.shortcutsVariant].char
    }
    return this.value[ShortcutVariant.Default].char
  }

  get modifiers(): ModifierKey[] | 'any' {
    const courseStore = useCourseStore()
    const meta = getLayoutsMetadata(courseStore.current.definition.os)[courseStore.current.definition.layoutId]
    if (meta.shortcutsVariant && meta.shortcutsVariant in this.value) {
      return this.value[meta.shortcutsVariant].modifiers ?? []
    }
    return this.value[ShortcutVariant.Default].modifiers ?? []
  }

  get modifiersList() {
    return Array.isArray(this.modifiers) ? this.modifiers : []
  }

  get acceptAnyModifiers() {
    return this.modifiers === 'any'
  }

  print() {
    return `${this.printModifiers()} ${this.printChar()}`.trim()
  }

  printModifiers() {
    const modifiers = this.modifiersList
      .map((k) => {
        const macOS = isMacOS()
        switch (k) {
          case 'Meta':
            return macOS ? '⌘' : 'Ctrl'
          case 'Shift':
            return macOS ? '⇧' : 'Shift'
          case 'Alt':
            return macOS ? '⌥' : 'Alt'
        }
      })
      .join(' ')
    return modifiers
  }

  printChar() {
    const macOS = isMacOS()
    let charToPrint = this.char

    switch (this.char) {
      case 'Enter':
        charToPrint = macOS ? 'Return' : 'Enter'
        break
      case 'Escape':
        charToPrint = 'Esc'
        break
      case ' ':
        charToPrint = 'Space'
    }

    return charToPrint.length === 1 ? charToPrint.toUpperCase() : charToPrint
  }
}
