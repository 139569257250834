import type { FullTypingResult } from '@/helpers/Trainer'
import type { LayoutData } from '@/stores/courseStore'
import dayjs, { Dayjs } from 'dayjs'
import { CourseCalendarStats, type CourseCalendarStatsSzd } from './CourseCalendarStats'
import { CourseCompletionStats, type CourseCompletionStatsSzd } from './CourseCompletionStats'
import { CourseHighlightStats, type SerializedHighlightStats } from './CourseHighlightStats'
import { CourseMispressesStats, type SerializedCourseMispressesStats } from './CourseMispressesStats'
import { CourseTodayStats, type CourseTodayStatsSzd } from './CourseTodayStats'
import { CharTypingResult } from './typing-result/CharTypingResult'

export class CourseStats {
  constructor(
    public added: Dayjs,
    //home
    public highlight: CourseHighlightStats,
    public mispresses: CourseMispressesStats,
    public calendar: CourseCalendarStats,
    public today: CourseTodayStats,
    public completion: CourseCompletionStats,
    // trainings
    public lastTraining: CourseHighlightStats, // not persistent, only for result screen
  ) {}

  logTraining(fullResult: FullTypingResult, typingResult: CharTypingResult, layoutData: LayoutData) {
    this.highlight.update(fullResult, typingResult)
    this.mispresses.update(fullResult, typingResult)
    this.calendar.update(fullResult, typingResult)
    this.today.update(fullResult, typingResult)
    this.completion.update(fullResult, typingResult, layoutData)

    this.lastTraining = CourseHighlightStats.template()
    this.lastTraining.update(fullResult, typingResult)
  }

  logInfoLesson(lessonId: string) {
    this.completion.logInfoLesson(lessonId)
  }

  // to Firebase or localStorage object
  serialize(): StringifiedSerializedCourseStats {
    let serializedResult: StringifiedSerializedCourseStats = {
      added: this.added.toJSON(),

      highlight: JSON.stringify(this.highlight.serialize()),
      mispresses: JSON.stringify(this.mispresses.serialize()),
      calendar: JSON.stringify(this.calendar.serialize()),
      today: JSON.stringify(this.today.serialize()),
      completion: JSON.stringify(this.completion.serialize()),
    }
    return serializedResult
  }

  // from Firebase or localStorage object
  static parse(stats: StringifiedSerializedCourseStats | SerializedCourseStats): CourseStats {
    // parse strings first
    const fieldNames: (keyof typeof stats)[] = ['highlight', 'mispresses', 'calendar', 'today', 'completion']
    for (const fieldName of fieldNames) {
      if (typeof stats[fieldName] === 'string') {
        stats[fieldName] = JSON.parse(stats[fieldName] as string)
      }
    }
    stats = stats as SerializedCourseStats

    return new CourseStats(
      dayjs(stats.added),
      // home
      CourseHighlightStats.parse(stats.highlight),
      CourseMispressesStats.parse(stats.mispresses),
      CourseCalendarStats.parse(stats.calendar),
      CourseTodayStats.parse(stats.today),
      CourseCompletionStats.parse(stats.completion),
      // trainings
      CourseHighlightStats.template(),
    )
  }

  static template(): CourseStats {
    return new CourseStats(
      dayjs(),
      // home
      CourseHighlightStats.template(),
      CourseMispressesStats.template(),
      CourseCalendarStats.template(),
      CourseTodayStats.template(),
      CourseCompletionStats.template(),
      // trainings
      CourseHighlightStats.template(),
    )
  }
}

export type StringifiedSerializedCourseStats = {
  added: string // dayjs
  highlight: string // SerializedHighlightStats
  mispresses: string // SerializedCourseMispressesStats
  calendar: string // CourseCalendarStatsSzd
  completion: string // CourseCompletionStats
  today: string // SerializedTodayStats
}

export type SerializedCourseStats = {
  added: string // dayjs
  highlight: SerializedHighlightStats
  mispresses: SerializedCourseMispressesStats
  calendar: CourseCalendarStatsSzd
  completion: CourseCompletionStatsSzd
  today: CourseTodayStatsSzd
}
