import { calcAccuracy, calcSpeed, toISODateString } from '@/helpers/main-utils'
import type { FullTypingResult } from '@/helpers/Trainer'
import dayjs from 'dayjs'
import { CharTypingResult } from './typing-result/CharTypingResult'

export class CourseTodayStats {
  constructor(public value: TodayStats) {}

  metrics() {
    const data = this.value
    return {
      speed: calcSpeed(data.totalTypeTimeMs, data.typeCount),
      accuracy: calcAccuracy(data.typeCount, data.typoCount),
      typingTimeMs: data.totalTypeTimeMs,
      presses: data.typeCount,
    }
  }

  // coursePct(course: Course) {
  //   const totalLessons = Object.values(course).flat().length
  //   return this.value.trainingCount / totalLessons
  // }

  update(fullResult: FullTypingResult, typingResult: CharTypingResult) {
    const todayStartDate = dayjs().startOf('day')

    // check date
    if (dayjs(this.value.date).startOf('day').isBefore(todayStartDate)) {
      this.value = { ...todayStatsTemplate }
    }

    this.value.typeCount += fullResult.charLogs.length
    this.value.trainingCount += 1
    this.value.totalTypeTimeMs += typingResult.typingTimeMs
    this.value.typoCount += typingResult.typos
  }

  // to Firebase or localStorage object
  serialize(): CourseTodayStatsSzd {
    return {
      date: this.value.date,
      tc: this.value.typeCount,
      tsc: this.value.typoCount,
      tt: this.value.totalTypeTimeMs,
      trc: this.value.trainingCount,
    }
  }

  // from Firebase or localStorage object
  static parse(serialized: CourseTodayStatsSzd): CourseTodayStats {
    const result = CourseTodayStats.template()

    // rm outdated
    const today = dayjs()
    const serializedCopy = {
      date: serialized.date,
      typeCount: serialized.tc,
      typoCount: serialized.tsc,
      totalTypeTimeMs: serialized.tt,
      trainingCount: serialized.trc,
    }

    if (!dayjs(serializedCopy.date).startOf('day').isBefore(today.startOf('day'))) {
      result.value = serializedCopy
    }

    return result
  }

  static template() {
    return new CourseTodayStats({ ...todayStatsTemplate })
  }
}

export type TodayStats = {
  date: string
  typeCount: number
  typoCount: number
  totalTypeTimeMs: number
  trainingCount: number
}
export type CourseTodayStatsSzd = {
  date: string
  tc: number
  tsc: number
  tt: number
  trc: number
}

const todayStatsTemplate: TodayStats = {
  date: toISODateString(dayjs()),
  typeCount: 0,
  typoCount: 0,
  totalTypeTimeMs: 0,
  trainingCount: 0,
}
