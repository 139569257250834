<script setup lang="ts">
import Button from '@/components/Button.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import { useCookieStore } from '@/stores/cookieStore'
import { useElementBounding } from '@vueuse/core'
import { computed, onMounted, onUnmounted, ref, useTemplateRef } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const cookieStore = useCookieStore()

const modalRef = useTemplateRef('modal')
const { height } = useElementBounding(modalRef)
const minHeight = computed(() => `${height.value}px`)

const isSetupShown = ref(false)
const isAllowedAnalytics = ref(cookieStore.isAnalyticsAllowed)
const isModalOpen = ref(true)

const handleAcceptAll = () => {
  cookieStore.setAnalyticsAllowed(true)
  isModalOpen.value = false
}

const handleSave = () => {
  cookieStore.setAnalyticsAllowed(isAllowedAnalytics.value)
  isModalOpen.value = false
}

onMounted(() => {
  document.body.style.overflow = 'hidden'
  document.body.style.height = '100%'
})

onUnmounted(() => {
  document.body.style.overflow = ''
  document.body.style.height = ''
})
</script>

<template>
  <Teleport to="body">
    <div v-if="isModalOpen" class="overlay">
      <div class="modal" ref="modal" :style="{ minHeight: minHeight }">
        <img class="cookie" src="/src/assets/img/cookie.png" alt="Cookie" />

        <div v-if="!isSetupShown" class="content">
          <p class="title">{{ t('Cookie.title') }}</p>
          <p class="text">{{ t('Cookie.cookieInfo') }}</p>
          <div class="buttons">
            <Button variant="secondary" @click="isSetupShown = true">{{ t('Cookie.customize') }}</Button>
            <Button type="primary" @click="handleAcceptAll">{{ t('Cookie.acceptAll') }}</Button>
          </div>
        </div>

        <div v-else class="content">
          <p class="title">{{ t('Cookie.customizeTitle') }}</p>
          <div class="controls">
            <ToggleButton
              :checked="isAllowedAnalytics"
              @checked="(checked) => (isAllowedAnalytics = checked)"
              :label="t('Cookie.analyticsCookies')"
            />
          </div>
          <div class="buttons">
            <Button variant="secondary" @click="isSetupShown = false">{{ t('back') }}</Button>
            <Button type="primary" @click="handleSave">{{ t('save') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--c-modal-backdrop);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: var(--c-surface);
  border-radius: var(--br-xl);
  border: 1px solid var(--c-secondary-border);
  padding: var(--s-lg);
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img.cookie {
    height: 80px;
    width: auto;
  }

  .content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: var(--s-lg);

    .title {
      font-weight: 700;
      font-size: var(--fz-lg);
      margin-bottom: var(--s-sm);
    }

    .text {
      font-size: var(--fz-md);
    }

    .controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: var(--s-xs);
    }

    .buttons {
      margin-top: auto;
      padding-top: var(--s-xl);
      display: flex;
      gap: var(--s-md);

      .btn {
        flex: 1;
        min-width: unset;
      }

      @media (max-width: 768px) {
        flex-direction: column;

        .btn {
          flex: unset;
        }
      }
    }
  }
}
</style>
