<script setup lang="ts">
import { useAppStore } from '@/stores/appStore'
import Emoji from './Emoji.vue'

type Props = {
  show: boolean
  text: string
  emoji: string
}
const props = defineProps<Props>()

const appStore = useAppStore()
</script>

<template>
  <Transition>
    <div v-if="show" class="toast-wrapper" @click="appStore.dismissToast('top')">
      <Emoji :name="emoji" type="separate" class="emoji-component" />
      <p>{{ text }}</p>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.toast-wrapper {
  position: fixed;
  top: var(--grid-cell);
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--c-divider);
  color: var(--c-text-primary);
  padding: var(--s-md) var(--s-lg);
  border-radius: 100px;
  display: flex;
  gap: var(--s-sm);
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  font-size: var(--fz-sm);
  font-weight: 500;

  transition: all 0.3s ease-in;

  .emoji-component {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.v-enter-from,
.v-leave-to {
  top: -30px;
  opacity: 0.5;
}

.v-enter-to,
.v-leave-from {
  top: 20px;
  opacity: 1;
}
</style>
