import { Chapter, TrainerView, type LessonCoords } from '@/course/course-types'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { PressLog, type CharLog } from '@/types/PressLog'
import dayjs, { type Dayjs } from 'dayjs'
import { sum } from 'lodash-es'
import { Char } from './keyboards/KeyChar'
import { typeableKeyCodes, type TypeableKeyCode } from './keyboards/KeyCode'
import { KeyboardLayout } from './keyboards/KeyboardLayout'
import { Layer } from './keyboards/Layer'
import { toFixed } from './main-utils'
import type { KeyPress } from './press-helper'

// trainer styles

export const typingTextWidthPx = '864px'

export const typingTextFontSizePx = {
  [TrainerView.Info]: '0px',
  [TrainerView.ThreeLines]: '20px',
  [TrainerView.RunningLine]: '36px',
}

export const typingTextLetterWidthPx = (fontSize: number) => toFixed(fontSize / 1.666, 2)

export const typingTextRowLength = {
  [TrainerView.Info]: 0,
  [TrainerView.ThreeLines]: Math.floor(parseInt(typingTextWidthPx) / typingTextLetterWidthPx(parseInt(typingTextFontSizePx[TrainerView.ThreeLines]))),
  [TrainerView.RunningLine]:
    Math.floor(parseInt(typingTextWidthPx) / typingTextLetterWidthPx(parseInt(typingTextFontSizePx[TrainerView.RunningLine])) / 2) * 2,
}

const MAX_TYPOS_IN_ROW = 3
const AUTO_PAUSE_AFTER_MS = 10_000

export enum TrainingPhase {
  Instantiated = 'Instantiated',
  Initialized = 'Initialized',
  Running = 'Running',
  Paused = 'Paused',
  Finished = 'Finished',
}

export type CharState = {
  index: number
  toType: Char
  typed: Char | null
  typedDead: boolean
}

export enum LimitType {
  Duration = 'Duration', // limit in MS
  Length = 'Length', // limit in chars
  Unlimited = 'Unlimited',
}

type TrainingInitProps = {
  text: string
  limitType: LimitType
  limit: number
  onFinish: (training: FullTypingResult) => void
  lessonCoords: LessonCoords
  view: TrainerView
  rowsCount?: number
}

// stats
export type FullTypingResult = {
  startedAt: Dayjs
  finishedAt: Dayjs
  lessonCoords: LessonCoords
  charLogs: CharLog[]
}

export class Trainer {
  public phase: TrainingPhase
  private limitType: LimitType = LimitType.Duration
  private limit: number = 2 * 60 * 1000

  private layout: KeyboardLayout
  private startedAt?: Dayjs
  private onFinish: (training: FullTypingResult) => void = () => {}
  private intervalId?: number
  private elapsedTotal: number = 0 // ms
  private elapsedInSession: number = 0 // ms

  private text: string = ''
  public textState: CharState[][] = []
  private typosCount: number = 0
  public cursorIndex: number = 0
  public correctCursorIndex: number = 0

  private pressLogs: PressLog[] = [] // current
  private firtTyposPoints: number[] = []
  private charLogs: CharLog[] = []

  private lessonCoords = { chapter: Chapter.HomeRow, index: 0 }
  private view?: TrainerView
  // private usedPresses: PressLog[] = []
  // private extraPresses: PressLog[] = []
  // private firstExtraPresses: PressLog[] = []

  private lastPressTimestamp?: number

  constructor(layout: KeyboardLayout) {
    this.layout = layout
    this.phase = TrainingPhase.Instantiated
  }

  public init(props: TrainingInitProps) {
    this.phase = TrainingPhase.Initialized
    this.limitType = props.limitType
    this.limit = props.limit
    this.onFinish = props.onFinish
    this.text = props.text
    this.lessonCoords = props.lessonCoords
    this.view = props.view
    this.initTextState(this.text)
  }

  public clearInterval() {
    clearInterval(this.intervalId)
  }

  private initTextState(text: string) {
    // split text to lines
    let lines = []

    if (this.view === TrainerView.ThreeLines) {
      let line = ''
      text.split(' ').forEach((word) => {
        if (line.length + word.length + 1 <= typingTextRowLength[TrainerView.ThreeLines]) {
          line += word + ' '
        } else {
          lines.push(line)
          line = word + ' '
        }
      })
      lines.push(line.trim())
    } else {
      lines.push(text.trim())
    }

    // fill textState
    this.textState = []
    let charIndex = 0
    lines.forEach((row, i) => {
      const rowState: CharState[] = []
      for (let j = 0; j < row.length; j++) {
        rowState.push({
          index: charIndex,
          toType: new Char(row[j]),
          typed: null,
          typedDead: false,
        })
        charIndex++
      }
      this.textState.push(rowState)
    })
  }

  public start(): void {
    this.phase = TrainingPhase.Running
    this.startedAt = dayjs()
    this.lastPressTimestamp = performance.now()
    this.startInterval()
  }

  public finish() {
    if (this.phase !== TrainingPhase.Running) {
      return
    }

    this.phase = TrainingPhase.Finished

    const trainingData: FullTypingResult = {
      finishedAt: dayjs(),
      startedAt: this.startedAt!,
      // pressLogs: this.pressLogs,
      charLogs: this.charLogs,
      lessonCoords: this.lessonCoords,
    }

    // the timer is preventing an immediate transition to the result view, otherwise result view shortcuts could be triggered by last press in trainer
    setTimeout(() => {
      this.clearState()
      this.onFinish(trainingData)
    }, 50)
  }

  private startInterval() {
    this.intervalId = window.setInterval(() => {
      // auto-pause
      const timeSinceLastPress = performance.now() - (this.lastPressTimestamp ?? performance.now())
      if (timeSinceLastPress >= AUTO_PAUSE_AFTER_MS) {
        this.pause()
      }

      if (this.limitType === LimitType.Duration) {
        this.elapsedInSession += 1000
        if (this.limit <= this.elapsedTime('ms')) {
          this.finish()
        }
      }
    }, 1000)
  }

  public pause(): void {
    this.phase = TrainingPhase.Paused
    this.preserveElapsedTime()
    clearInterval(this.intervalId)
  }

  public resume(): void {
    this.phase = TrainingPhase.Running
    this.lastPressTimestamp = performance.now()
    this.startInterval()
  }

  private clearState() {
    this.clearInterval()
    this.intervalId = undefined
    this.startedAt = undefined
    this.elapsedTotal = 0
    this.elapsedInSession = 0
    this.textState = []
    this.typosCount = 0
    this.cursorIndex = 0
    this.correctCursorIndex = 0
    this.pressLogs = []
    this.firtTyposPoints = []
    this.charLogs = []
    this.lastPressTimestamp = undefined
  }

  public restart(newText?: string): void {
    this.clearState()

    // init
    this.init({
      text: newText ?? this.text,
      limitType: this.limitType,
      limit: this.limit,
      onFinish: this.onFinish,
      lessonCoords: this.lessonCoords,
      view: this.view!,
    })

    this.start()
  }

  private preserveElapsedTime() {
    this.elapsedTotal += this.elapsedInSession
    this.elapsedInSession = 0
  }

  public elapsedTime(units: 'ms' | 's') {
    const result = this.elapsedTotal + this.elapsedInSession
    if (units === 's') {
      return result / 1000
    }
    return result
  }

  public wrongSequence(): boolean {
    return this.firstWrongIndex() !== -1
  }

  public firstWrongIndex(): number {
    if (this.correctCursorIndex !== this.cursorIndex) {
      return this.correctCursorIndex
    }

    return -1
  }

  public getTextState(rows: number = this.textState.length): CharState[][] {
    let currentLineIndex = this.textState.findIndex((row) => {
      return row.findIndex((char) => char.index === this.correctCursorIndex) !== -1
    })

    if (currentLineIndex === -1) {
      currentLineIndex = this.textState.length - 1
    }

    if (this.textState.length - currentLineIndex >= rows) {
      return this.textState.slice(currentLineIndex, currentLineIndex + rows)
    } else {
      return this.textState.slice(-rows)
    }
  }

  public keyToPress(): LayeredKeyCode | null {
    if (this.wrongSequence()) {
      return new LayeredKeyCode('Backspace', Layer.Default)
    }

    const charState = this.getCharState(this.cursorIndex)

    if (!charState) {
      return null
    }

    if (this.isPaused()) {
      return new LayeredKeyCode('Space', Layer.Default)
    }

    const charToType = charState.toType
    const keyPresses = this.layout.getKeysToType(charToType)

    if (!keyPresses) {
      return null
    }
    if (keyPresses.length === 1) {
      return keyPresses[0]
    }
    return charState.typedDead ? keyPresses[1] : keyPresses[0]
  }

  private addChar(keyPress: KeyPress): boolean {
    let charState = this.getCharState(this.cursorIndex)
    const correctCharState = this.getCharState(this.correctCursorIndex)
    const keyChar = keyPress.keyChar

    // layeredCode exist only if TypeableKeyCode pressed
    if (!charState || !correctCharState || !keyChar) {
      return false
    }

    const layeredKeyCode = new LayeredKeyCode(keyPress.keyCode as TypeableKeyCode, keyPress.layer)

    // always add to press log
    const pressTimeMs = this.latestPressTimeMs()
    this.pressLogs.push(
      new PressLog(
        layeredKeyCode,
        keyPress.print[0]?.value ?? '',
        correctCharState.toType.value,
        pressTimeMs,
        keyPress.print.length > 1 || keyChar.value !== keyPress.print[0]?.value,
      ),
    )

    if (keyPress.print[0]?.value !== correctCharState.toType.value && !this.wrongSequence()) {
      this.typosCount++
    }

    const distinctChars = keyPress.print.map((char) => (char.printLength > 1 ? char.value.split('').map((v) => new Char(v)) : char)).flat()

    for (let i = 0; i < distinctChars.length; i++) {
      const typedCorrectly = distinctChars[i].isEqual(charState.toType) && this.correctCursorIndex === this.cursorIndex

      charState.typed = distinctChars[i]
      charState.typedDead = false
      this.correctCursorIndex += typedCorrectly ? 1 : 0
      this.cursorIndex++

      // handle char log
      if (typedCorrectly) {
        const lastPressLog = this.pressLogs[this.pressLogs.length - 1]
        const usedKeys: PressLog[] = lastPressLog.transformed ? this.pressLogs.slice(-2) : [lastPressLog]
        const extraPresses: PressLog[] = this.pressLogs.slice(0, -usedKeys.length)

        let extraFirstPresses: LayeredKeyCode[] = []
        let i = 0
        for (const point of this.firtTyposPoints) {
          if (extraPresses[i]) {
            extraFirstPresses.push(extraPresses[i].pressedKey as LayeredKeyCode)
          }
          const step = point - i
          i += step
        }

        const newCharLog = {
          char: charState.typed.value,
          isPressed: true,
          usedKeys: usedKeys,
          pressTimeMs: sum(usedKeys.map((k) => k.pressTimeMs)),
          extraPresses: extraPresses,
          extraPressTimeMs: sum(extraPresses.map((k) => k.pressTimeMs)),
          extraFirstPresses,
        } as CharLog

        this.charLogs.push(newCharLog)
        this.pressLogs = []
        this.firtTyposPoints = []
      }

      charState = this.getCharState(this.cursorIndex)
      if (!charState) return false
    }

    if (keyPress.deadPreview) {
      // dead
      charState.typed = keyPress.deadPreview
      charState.typedDead = true
    }

    return true
  }

  private removeChar(cursorMove: number) {
    const currChar = this.getCharState(this.cursorIndex)

    if (currChar?.typedDead) {
      currChar.typed = null
      currChar.typedDead = false
    }

    if (cursorMove) {
      const prevChar = this.getCharState(this.cursorIndex - 1)
      if (prevChar) {
        prevChar.typed = null
        prevChar.typedDead = false
        this.cursorIndex--
        this.correctCursorIndex = Math.min(this.correctCursorIndex, this.cursorIndex)
      }
    }

    if (this.correctCursorIndex === this.cursorIndex) {
      // just erased very first typo
      // bb←←cc←←a
      // 4 8

      this.firtTyposPoints.push(this.pressLogs.length)
    }
  }

  public registerPress(keyPress: KeyPress) {
    if (this.phase === TrainingPhase.Finished) {
      return
    }

    if (typeableKeyCodes.includes(keyPress.keyCode as TypeableKeyCode)) {
      // typos in row limit handling
      // not using === here because it's possible to go over the limit (i.e. as a 3d char you type Dead and then non-modifying key)
      if (this.cursorIndex - this.correctCursorIndex >= MAX_TYPOS_IN_ROW) {
        return
      }

      if (this.phase !== TrainingPhase.Running) {
        this.start()
      }

      const currentCharState = this.getCharState(this.cursorIndex)
      if (!currentCharState) {
        return
      }

      this.addChar(keyPress)

      if (this.isFinished()) {
        this.finish()
      }
    }

    if (keyPress.keyCode === 'Backspace') {
      const currChar = this.getCharState(this.cursorIndex)
      const pressTimeMs = this.latestPressTimeMs()

      // originally this prevents adding extra Backspace when going over the limit and pressing Dead, but it also makes sense in general
      if (!keyPress.cursorMove && !currChar?.typedDead) {
        return
      }

      // don't erase correct chars & when nothing typed yet
      if (this.correctCursorIndex === this.cursorIndex && !currChar?.typedDead) {
        return
      }

      this.lastPressTimestamp = performance.now()
      this.pressLogs.push(new PressLog('Backspace', 'Backspace', this.getCharState(this.correctCursorIndex)!.toType.value, pressTimeMs, false))

      this.removeChar(keyPress.cursorMove)
    }
  }

  private latestPressTimeMs(): number {
    let pressTimeMs = 0

    if (this.lastPressTimestamp) {
      pressTimeMs = performance.now() - this.lastPressTimestamp
    }
    this.lastPressTimestamp = performance.now()

    return pressTimeMs
  }

  public isPaused(): boolean {
    return this.phase === TrainingPhase.Paused
  }

  public isFinished(): boolean {
    return !this.wrongSequence() && this.cursorIndex >= this.textLength()
  }

  public textLength(): number {
    return sum(this.textState.map((l) => l.length))
  }

  private getCharState(index: number): CharState | null {
    for (let i = 0; i < this.textState.length; i++) {
      const row = this.textState[i]
      for (let j = 0; j < row.length; j++) {
        if (row[j].index === index) {
          return row[j]
        }
      }
    }
    return null
  }

  public accuracy(): number {
    const correctKeystrokes = this.charLogs.length
    const totalKeystrokes = correctKeystrokes + this.typosCount
    return this.typosCount ? toFixed((correctKeystrokes / totalKeystrokes) * 100, 1) : 100

    // NOTE: if want to calc total accuracy, use this:
    // const correctKeystrokes = this.textLength()
    // const totalKeystrokes = correctKeystrokes + this.typosCount
    // return this.typosCount ? toFixed((correctKeystrokes / totalKeystrokes) * 100, 1) : 100
  }
}
