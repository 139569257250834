import { KeyboardFormat, KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { Char } from '@/helpers/keyboards/KeyChar'
import type { TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { getUserOS } from '@/helpers/user-agent-utils'
import { type UserLocale } from '@/plugins/i18n'
import { LayoutDefinition } from '@/types/LayoutDefinition'
import { OS } from '@/types/main-types'

const accHighlights = {
  HomeRow: {
    а: {
      tc: 132,
      tt: 29129.9,
      pk: {
        '129': {
          pc: 132,
          pt: 27529.4,
          tsc: 3,
        },
      },
    },
    о: {
      tc: 121,
      tt: 25685.9,
      pk: {
        '132': {
          pc: 121,
          pt: 25087.6,
          tsc: 1,
        },
      },
    },
    ' ': {
      tc: 176,
      tt: 27739.6,
      pk: {
        '147': {
          pc: 176,
          pt: 27739.6,
        },
      },
    },
    в: {
      tc: 81,
      tt: 15613.6,
      pk: {
        '128': {
          pc: 81,
          pt: 15613.6,
        },
      },
    },
    л: {
      tc: 75,
      tt: 20251.4,
      pk: {
        '133': {
          pc: 75,
          pt: 17728.4,
          tsc: 3,
        },
      },
    },
    ы: {
      tc: 60,
      tt: 15736.9,
      pk: {
        '127': {
          pc: 60,
          pt: 14134.3,
          tsc: 2,
        },
      },
    },
    д: {
      tc: 75,
      tt: 28462.9,
      pk: {
        '134': {
          pc: 75,
          pt: 26589.8,
          tsc: 2,
        },
      },
    },
    ф: {
      tc: 42,
      tt: 10250,
      pk: {
        '126': {
          pc: 42,
          pt: 9607.9,
          tsc: 1,
        },
      },
    },
    ж: {
      tc: 46,
      tt: 10153.1,
      pk: {
        '135': {
          pc: 46,
          pt: 10153.1,
        },
      },
    },
    п: {
      tc: 37,
      tt: 7988.6,
      pk: {
        '130': {
          pc: 37,
          pt: 7988.6,
        },
      },
    },
    р: {
      tc: 47,
      tt: 9417.4,
      pk: {
        '131': {
          pc: 47,
          pt: 9417.4,
        },
      },
    },
  },
  TopRow: {
    г: {
      tc: 54,
      tt: 12548.1,
      pk: {
        '119': {
          pc: 54,
          pt: 12548.1,
        },
      },
    },
    к: {
      tc: 78,
      tt: 16870.8,
      pk: {
        '116': {
          pc: 78,
          pt: 13979.1,
          tsc: 3,
        },
      },
    },
    ' ': {
      tc: 186,
      tt: 31327.4,
      pk: {
        '147': {
          pc: 186,
          pt: 28316.6,
          tsc: 3,
        },
      },
    },
    п: {
      tc: 24,
      tt: 5711.7,
      pk: {
        '130': {
          pc: 24,
          pt: 5711.7,
        },
      },
    },
    о: {
      tc: 84,
      tt: 14600,
      pk: {
        '132': {
          pc: 84,
          pt: 13588.3,
          tsc: 1,
        },
      },
    },
    р: {
      tc: 49,
      tt: 10401.4,
      pk: {
        '131': {
          pc: 49,
          pt: 9680.3,
          tsc: 1,
        },
      },
    },
    в: {
      tc: 36,
      tt: 8024.7,
      pk: {
        '128': {
          pc: 36,
          pt: 8024.7,
        },
      },
    },
    а: {
      tc: 75,
      tt: 13087.5,
      pk: {
        '129': {
          pc: 75,
          pt: 11950.2,
          tsc: 2,
        },
      },
    },
    л: {
      tc: 27,
      tt: 5992.6,
      pk: {
        '133': {
          pc: 27,
          pt: 5255.4,
          tsc: 1,
        },
      },
    },
    д: {
      tc: 34,
      tt: 9768.9,
      pk: {
        '134': {
          pc: 34,
          pt: 7316.8,
          tsc: 3,
        },
      },
    },
    ж: {
      tc: 17,
      tt: 4228.7,
      pk: {
        '135': {
          pc: 17,
          pt: 4228.7,
        },
      },
    },
    ы: {
      tc: 28,
      tt: 5116,
      pk: {
        '127': {
          pc: 28,
          pt: 5116,
        },
      },
    },
    у: {
      tc: 51,
      tt: 13155.4,
      pk: {
        '115': {
          pc: 51,
          pt: 8945.3,
          tsc: 4,
        },
      },
    },
    ш: {
      tc: 52,
      tt: 16012.3,
      pk: {
        '120': {
          pc: 52,
          pt: 13318.8,
          tsc: 3,
        },
      },
    },
    ф: {
      tc: 13,
      tt: 3497.2,
      pk: {
        '126': {
          pc: 13,
          pt: 3497.2,
        },
      },
    },
    ц: {
      tc: 40,
      tt: 10392.8,
      pk: {
        '114': {
          pc: 40,
          pt: 10392.8,
        },
      },
    },
    щ: {
      tc: 38,
      tt: 10667.6,
      pk: {
        '121': {
          pc: 38,
          pt: 9106.6,
          tsc: 2,
        },
      },
    },
    й: {
      tc: 41,
      tt: 8424,
      pk: {
        '113': {
          pc: 41,
          pt: 8424,
        },
      },
    },
    з: {
      tc: 43,
      tt: 10295.7,
      pk: {
        '122': {
          pc: 43,
          pt: 8814.3,
          tsc: 1,
        },
      },
    },
    е: {
      tc: 36,
      tt: 6305.5,
      pk: {
        '117': {
          pc: 36,
          pt: 6305.5,
        },
      },
    },
    н: {
      tc: 43,
      tt: 8366.4,
      pk: {
        '118': {
          pc: 43,
          pt: 7754.6,
          tsc: 1,
        },
      },
    },
  },
  BottomRow: {
    м: {
      tc: 49,
      tt: 9973.3,
      pk: {
        '140': {
          pc: 49,
          pt: 9434.2,
          tsc: 1,
        },
      },
    },
    ь: {
      tc: 56,
      tt: 15068.3,
      pk: {
        '143': {
          pc: 56,
          pt: 13084.3,
          tsc: 2,
        },
      },
    },
    ' ': {
      tc: 181,
      tt: 25844.5,
      pk: {
        '147': {
          pc: 181,
          pt: 25844.5,
        },
      },
    },
    п: {
      tc: 14,
      tt: 4492,
      pk: {
        '130': {
          pc: 14,
          pt: 4492,
        },
      },
    },
    а: {
      tc: 53,
      tt: 8283.9,
      pk: {
        '129': {
          pc: 53,
          pt: 7186,
          tsc: 1,
        },
      },
    },
    н: {
      tc: 32,
      tt: 6670.7,
      pk: {
        '118': {
          pc: 32,
          pt: 6116,
          tsc: 1,
        },
      },
    },
    е: {
      tc: 41,
      tt: 6702.8,
      pk: {
        '117': {
          pc: 41,
          pt: 5424.7,
          tsc: 1,
        },
      },
    },
    л: {
      tc: 28,
      tt: 5975.6,
      pk: {
        '133': {
          pc: 28,
          pt: 5321.2,
          tsc: 1,
        },
      },
    },
    к: {
      tc: 31,
      tt: 6440.5,
      pk: {
        '116': {
          pc: 31,
          pt: 5599.1,
          tsc: 1,
        },
      },
    },
    о: {
      tc: 54,
      tt: 10586.4,
      pk: {
        '132': {
          pc: 54,
          pt: 9354.5,
          tsc: 2,
        },
      },
    },
    р: {
      tc: 38,
      tt: 7607.2,
      pk: {
        '131': {
          pc: 38,
          pt: 6398,
          tsc: 1,
        },
      },
    },
    щ: {
      tc: 5,
      tt: 1169,
      pk: {
        '121': {
          pc: 5,
          pt: 1169,
        },
      },
    },
    у: {
      tc: 18,
      tt: 3539,
      pk: {
        '115': {
          pc: 18,
          pt: 3539,
        },
      },
    },
    г: {
      tc: 9,
      tt: 2123.6,
      pk: {
        '119': {
          pc: 9,
          pt: 2123.6,
        },
      },
    },
    в: {
      tc: 16,
      tt: 3083.5,
      pk: {
        '128': {
          pc: 16,
          pt: 3083.5,
        },
      },
    },
    з: {
      tc: 10,
      tt: 5334.3,
      pk: {
        '122': {
          pc: 10,
          pt: 3820.1,
          tsc: 1,
        },
      },
    },
    д: {
      tc: 26,
      tt: 5078.7,
      pk: {
        '134': {
          pc: 26,
          pt: 5078.7,
        },
      },
    },
    ж: {
      tc: 9,
      tt: 2108.8,
      pk: {
        '135': {
          pc: 9,
          pt: 2108.8,
        },
      },
    },
    ц: {
      tc: 2,
      tt: 347.4,
      pk: {
        '114': {
          pc: 2,
          pt: 347.4,
        },
      },
    },
    ш: {
      tc: 7,
      tt: 1802.2,
      pk: {
        '120': {
          pc: 7,
          pt: 1802.2,
        },
      },
    },
    ы: {
      tc: 11,
      tt: 2052.8,
      pk: {
        '127': {
          pc: 11,
          pt: 2052.8,
        },
      },
    },
    й: {
      tc: 12,
      tt: 3617,
      pk: {
        '113': {
          pc: 12,
          pt: 3080.1,
          tsc: 1,
        },
      },
    },
    ф: {
      tc: 4,
      tt: 711.3,
      pk: {
        '126': {
          pc: 4,
          pt: 711.3,
        },
      },
    },
    с: {
      tc: 57,
      tt: 12042.3,
      pk: {
        '139': {
          pc: 57,
          pt: 12042.3,
        },
      },
    },
    б: {
      tc: 46,
      tt: 11872.6,
      pk: {
        '144': {
          pc: 46,
          pt: 10129.3,
          tsc: 2,
        },
      },
    },
    ч: {
      tc: 40,
      tt: 8820.8,
      pk: {
        '138': {
          pc: 40,
          pt: 8820.8,
        },
      },
    },
    ю: {
      tc: 38,
      tt: 8926.3,
      pk: {
        '145': {
          pc: 38,
          pt: 8926.3,
        },
      },
    },
    я: {
      tc: 38,
      tt: 8398.9,
      pk: {
        '137': {
          pc: 38,
          pt: 8398.9,
        },
      },
    },
    '.': {
      tc: 38,
      tt: 11915,
      pk: {
        '146': {
          pc: 38,
          pt: 10368.3,
          tsc: 2,
        },
      },
    },
    т: {
      tc: 49,
      tt: 15369.3,
      pk: {
        '142': {
          pc: 49,
          pt: 14573.8,
          tsc: 1,
        },
      },
    },
    и: {
      tc: 37,
      tt: 7028.8,
      pk: {
        '141': {
          pc: 37,
          pt: 7028.8,
        },
      },
    },
  },
  AdditionalLetters: {
    ё: {
      tc: 75,
      tt: 22168.2,
      pk: {
        '100': {
          pc: 75,
          pt: 18347.4,
          tsc: 5,
        },
      },
    },
    ф: {
      tc: 50,
      tt: 13121.9,
      pk: {
        '126': {
          pc: 50,
          pt: 12476.7,
          tsc: 1,
        },
      },
    },
    ' ': {
      tc: 168,
      tt: 31227.4,
      pk: {
        '147': {
          pc: 168,
          pt: 31227.4,
        },
      },
    },
    п: {
      tc: 17,
      tt: 5690.2,
      pk: {
        '130': {
          pc: 17,
          pt: 4360.2,
          tsc: 2,
        },
      },
    },
    о: {
      tc: 41,
      tt: 6866.3,
      pk: {
        '132': {
          pc: 41,
          pt: 6866.3,
        },
      },
    },
    л: {
      tc: 12,
      tt: 2732.4,
      pk: {
        '133': {
          pc: 12,
          pt: 2732.4,
        },
      },
    },
    т: {
      tc: 42,
      tt: 9809.8,
      pk: {
        '142': {
          pc: 42,
          pt: 9809.8,
        },
      },
    },
    г: {
      tc: 1,
      tt: 260.5,
      pk: {
        '119': {
          pc: 1,
          pt: 260.5,
        },
      },
    },
    к: {
      tc: 20,
      tt: 4079.1,
      pk: {
        '116': {
          pc: 20,
          pt: 4079.1,
        },
      },
    },
    и: {
      tc: 24,
      tt: 4164.4,
      pk: {
        '141': {
          pc: 24,
          pt: 4164.4,
        },
      },
    },
    й: {
      tc: 7,
      tt: 2480.4,
      pk: {
        '113': {
          pc: 7,
          pt: 2480.4,
        },
      },
    },
    ж: {
      tc: 98,
      tt: 38004.4,
      pk: {
        '135': {
          pc: 98,
          pt: 31297.5,
          tsc: 7,
        },
      },
    },
    ь: {
      tc: 13,
      tt: 3872.8,
      pk: {
        '143': {
          pc: 13,
          pt: 3872.8,
        },
      },
    },
    ч: {
      tc: 6,
      tt: 1436.3,
      pk: {
        '138': {
          pc: 6,
          pt: 1436.3,
        },
      },
    },
    м: {
      tc: 19,
      tt: 4314.9,
      pk: {
        '140': {
          pc: 19,
          pt: 4314.9,
        },
      },
    },
    а: {
      tc: 22,
      tt: 3632.5,
      pk: {
        '129': {
          pc: 22,
          pt: 3632.5,
        },
      },
    },
    н: {
      tc: 10,
      tt: 2307.3,
      pk: {
        '118': {
          pc: 10,
          pt: 2307.3,
        },
      },
    },
    в: {
      tc: 11,
      tt: 1994.6,
      pk: {
        '128': {
          pc: 11,
          pt: 1994.6,
        },
      },
    },
    р: {
      tc: 18,
      tt: 3546.1,
      pk: {
        '131': {
          pc: 18,
          pt: 3546.1,
        },
      },
    },
    у: {
      tc: 14,
      tt: 2331.7,
      pk: {
        '115': {
          pc: 14,
          pt: 2331.7,
        },
      },
    },
    с: {
      tc: 20,
      tt: 4542.9,
      pk: {
        '139': {
          pc: 20,
          pt: 4542.9,
        },
      },
    },
    ы: {
      tc: 6,
      tt: 1071.5,
      pk: {
        '127': {
          pc: 6,
          pt: 1071.5,
        },
      },
    },
    б: {
      tc: 6,
      tt: 1400.4,
      pk: {
        '144': {
          pc: 6,
          pt: 1400.4,
        },
      },
    },
    е: {
      tc: 19,
      tt: 5923.5,
      pk: {
        '117': {
          pc: 19,
          pt: 5051.3,
          tsc: 1,
        },
      },
    },
    я: {
      tc: 7,
      tt: 1479.4,
      pk: {
        '137': {
          pc: 7,
          pt: 1479.4,
        },
      },
    },
    д: {
      tc: 18,
      tt: 4634.1,
      pk: {
        '134': {
          pc: 18,
          pt: 4634.1,
        },
      },
    },
    ш: {
      tc: 5,
      tt: 2783,
      pk: {
        '120': {
          pc: 5,
          pt: 2783,
        },
      },
    },
    х: {
      tc: 54,
      tt: 20945.6,
      pk: {
        '123': {
          pc: 54,
          pt: 16227.3,
          tsc: 5,
        },
      },
    },
    з: {
      tc: 14,
      tt: 9541.4,
      pk: {
        '122': {
          pc: 14,
          pt: 6060.4,
          tsc: 3,
        },
      },
    },
    ъ: {
      tc: 74,
      tt: 32213.6,
      pk: {
        '124': {
          pc: 74,
          pt: 27771.9,
          tsc: 4,
        },
      },
    },
    ц: {
      tc: 5,
      tt: 1823.9,
      pk: {
        '114': {
          pc: 5,
          pt: 1823.9,
        },
      },
    },
    ю: {
      tc: 1,
      tt: 792.2,
      pk: {
        '145': {
          pc: 1,
          pt: 792.2,
        },
      },
    },
    '.': {
      tc: 1,
      tt: 1168.3,
      pk: {
        '146': {
          pc: 1,
          pt: 1168.3,
        },
      },
    },
    щ: {
      tc: 1,
      tt: 1922.2,
      pk: {
        '121': {
          pc: 1,
          pt: 895.1,
          tsc: 1,
        },
      },
    },
    э: {
      tc: 50,
      tt: 17571,
      pk: {
        '136': {
          pc: 50,
          pt: 17072.8,
          tsc: 1,
        },
      },
    },
  },
  PerFinger: {
    ф: {
      tc: 15,
      tt: 5168.7,
      pk: {
        '126': {
          pc: 15,
          pt: 5168.7,
        },
      },
    },
    й: {
      tc: 15,
      tt: 6874.3,
      pk: {
        '113': {
          pc: 15,
          pt: 6874.3,
        },
      },
    },
    я: {
      tc: 15,
      tt: 9489.9,
      pk: {
        '137': {
          pc: 15,
          pt: 8586,
          tsc: 1,
        },
      },
    },
    ' ': {
      tc: 100,
      tt: 21316.4,
      pk: {
        '147': {
          pc: 100,
          pt: 21316.4,
        },
      },
    },
    з: {
      tc: 15,
      tt: 6343,
      pk: {
        '122': {
          pc: 15,
          pt: 6343,
        },
      },
    },
    ж: {
      tc: 15,
      tt: 5984.1,
      pk: {
        '135': {
          pc: 15,
          pt: 5239.1,
          tsc: 1,
        },
      },
    },
    '.': {
      tc: 15,
      tt: 5755.1,
      pk: {
        '146': {
          pc: 15,
          pt: 5033.9,
          tsc: 1,
        },
      },
    },
    ц: {
      tc: 15,
      tt: 7100.7,
      pk: {
        '114': {
          pc: 15,
          pt: 6219.3,
          tsc: 1,
        },
      },
    },
    ч: {
      tc: 15,
      tt: 7840.5,
      pk: {
        '138': {
          pc: 15,
          pt: 6706.8,
          tsc: 2,
        },
      },
    },
    ы: {
      tc: 15,
      tt: 3790.8,
      pk: {
        '127': {
          pc: 15,
          pt: 3790.8,
        },
      },
    },
    щ: {
      tc: 15,
      tt: 11800.8,
      pk: {
        '121': {
          pc: 15,
          pt: 7234.9,
          tsc: 4,
        },
      },
    },
    ю: {
      tc: 15,
      tt: 7655.1,
      pk: {
        '145': {
          pc: 15,
          pt: 7655.1,
        },
      },
    },
    д: {
      tc: 15,
      tt: 5052.3,
      pk: {
        '134': {
          pc: 15,
          pt: 5052.3,
        },
      },
    },
    с: {
      tc: 15,
      tt: 4451.8,
      pk: {
        '139': {
          pc: 15,
          pt: 4451.8,
        },
      },
    },
    в: {
      tc: 15,
      tt: 4348.2,
      pk: {
        '128': {
          pc: 15,
          pt: 3253,
          tsc: 1,
        },
      },
    },
    у: {
      tc: 15,
      tt: 5401.4,
      pk: {
        '115': {
          pc: 15,
          pt: 3274,
          tsc: 2,
        },
      },
    },
    л: {
      tc: 15,
      tt: 6355.6,
      pk: {
        '133': {
          pc: 15,
          pt: 6355.6,
        },
      },
    },
    б: {
      tc: 15,
      tt: 9803.8,
      pk: {
        '144': {
          pc: 15,
          pt: 8992.8,
          tsc: 1,
        },
      },
    },
    ш: {
      tc: 15,
      tt: 5429.2,
      pk: {
        '120': {
          pc: 15,
          pt: 5429.2,
        },
      },
    },
    а: {
      tc: 9,
      tt: 3063.5,
      pk: {
        '129': {
          pc: 9,
          pt: 3063.5,
        },
      },
    },
    к: {
      tc: 9,
      tt: 2335.2,
      pk: {
        '116': {
          pc: 9,
          pt: 2335.2,
        },
      },
    },
    п: {
      tc: 9,
      tt: 2310.7,
      pk: {
        '130': {
          pc: 9,
          pt: 2310.7,
        },
      },
    },
    м: {
      tc: 9,
      tt: 2713.7,
      pk: {
        '140': {
          pc: 9,
          pt: 2713.7,
        },
      },
    },
    и: {
      tc: 9,
      tt: 1684.8,
      pk: {
        '141': {
          pc: 9,
          pt: 1684.8,
        },
      },
    },
    е: {
      tc: 9,
      tt: 3324.4,
      pk: {
        '117': {
          pc: 9,
          pt: 2323.6,
          tsc: 1,
        },
      },
    },
    о: {
      tc: 9,
      tt: 4604.5,
      pk: {
        '132': {
          pc: 9,
          pt: 4604.5,
        },
      },
    },
    г: {
      tc: 9,
      tt: 4221.4,
      pk: {
        '119': {
          pc: 9,
          pt: 4221.4,
        },
      },
    },
    ь: {
      tc: 9,
      tt: 5240.1,
      pk: {
        '143': {
          pc: 9,
          pt: 5240.1,
        },
      },
    },
    т: {
      tc: 9,
      tt: 4062,
      pk: {
        '142': {
          pc: 9,
          pt: 4062,
        },
      },
    },
    р: {
      tc: 9,
      tt: 3563.7,
      pk: {
        '131': {
          pc: 9,
          pt: 3563.7,
        },
      },
    },
    н: {
      tc: 9,
      tt: 5249.9,
      pk: {
        '118': {
          pc: 9,
          pt: 5249.9,
        },
      },
    },
  },
  Ngrams: {
    т: {
      tc: 174,
      tt: 28362.2,
      pk: {
        '142': {
          pc: 174,
          pt: 26249.8,
          tsc: 2,
        },
      },
    },
    ь: {
      tc: 86,
      tt: 16345,
      pk: {
        '143': {
          pc: 86,
          pt: 16345,
        },
      },
    },
    ' ': {
      tc: 283,
      tt: 39285.4,
      pk: {
        '147': {
          pc: 283,
          pt: 32664.8,
          tsc: 7,
        },
      },
    },
    а: {
      tc: 101,
      tt: 15510.8,
      pk: {
        '129': {
          pc: 101,
          pt: 13942.8,
          tsc: 2,
        },
      },
    },
    и: {
      tc: 75,
      tt: 10941.5,
      pk: {
        '141': {
          pc: 75,
          pt: 10459,
          tsc: 1,
        },
      },
    },
    с: {
      tc: 73,
      tt: 12802.1,
      pk: {
        '139': {
          pc: 73,
          pt: 12802.1,
        },
      },
    },
    я: {
      tc: 10,
      tt: 2032.8,
      pk: {
        '137': {
          pc: 10,
          pt: 2032.8,
        },
      },
    },
    у: {
      tc: 7,
      tt: 962,
      pk: {
        '115': {
          pc: 7,
          pt: 962,
        },
      },
    },
    в: {
      tc: 49,
      tt: 7031.8,
      pk: {
        '128': {
          pc: 49,
          pt: 7031.8,
        },
      },
    },
    о: {
      tc: 118,
      tt: 17087.7,
      pk: {
        '132': {
          pc: 118,
          pt: 17087.7,
        },
      },
    },
    н: {
      tc: 58,
      tt: 11109.6,
      pk: {
        '118': {
          pc: 58,
          pt: 9996.5,
          tsc: 1,
        },
      },
    },
    д: {
      tc: 38,
      tt: 6728,
      pk: {
        '134': {
          pc: 38,
          pt: 6728,
        },
      },
    },
    р: {
      tc: 134,
      tt: 25321.4,
      pk: {
        '131': {
          pc: 134,
          pt: 23483,
          tsc: 3,
        },
      },
    },
    л: {
      tc: 18,
      tt: 3744.1,
      pk: {
        '133': {
          pc: 18,
          pt: 3083,
          tsc: 1,
        },
      },
    },
    ч: {
      tc: 16,
      tt: 3192.1,
      pk: {
        '138': {
          pc: 16,
          pt: 3192.1,
        },
      },
    },
    ж: {
      tc: 12,
      tt: 2437.6,
      pk: {
        '135': {
          pc: 12,
          pt: 2437.6,
        },
      },
    },
    к: {
      tc: 7,
      tt: 1186,
      pk: {
        '116': {
          pc: 7,
          pt: 1186,
        },
      },
    },
    б: {
      tc: 11,
      tt: 2977,
      pk: {
        '144': {
          pc: 11,
          pt: 2977,
        },
      },
    },
    ы: {
      tc: 9,
      tt: 1421.7,
      pk: {
        '127': {
          pc: 9,
          pt: 1421.7,
        },
      },
    },
    з: {
      tc: 10,
      tt: 1552.2,
      pk: {
        '122': {
          pc: 10,
          pt: 1552.2,
        },
      },
    },
    е: {
      tc: 97,
      tt: 14392.6,
      pk: {
        '117': {
          pc: 97,
          pt: 13773.9,
          tsc: 1,
        },
      },
    },
    м: {
      tc: 25,
      tt: 4085.8,
      pk: {
        '140': {
          pc: 25,
          pt: 4085.8,
        },
      },
    },
    п: {
      tc: 78,
      tt: 18073.3,
      pk: {
        '130': {
          pc: 78,
          pt: 17495,
          tsc: 1,
        },
      },
    },
    й: {
      tc: 13,
      tt: 1711.1,
      pk: {
        '113': {
          pc: 13,
          pt: 1711.1,
        },
      },
    },
    щ: {
      tc: 1,
      tt: 107.9,
      pk: {
        '121': {
          pc: 1,
          pt: 107.9,
        },
      },
    },
    э: {
      tc: 1,
      tt: 133.9,
      pk: {
        '136': {
          pc: 1,
          pt: 133.9,
        },
      },
    },
    г: {
      tc: 5,
      tt: 1107,
      pk: {
        '119': {
          pc: 5,
          pt: 1107,
        },
      },
    },
    ю: {
      tc: 1,
      tt: 355.2,
      pk: {
        '145': {
          pc: 1,
          pt: 355.2,
        },
      },
    },
    ш: {
      tc: 2,
      tt: 182.9,
      pk: {
        '120': {
          pc: 2,
          pt: 182.9,
        },
      },
    },
    х: {
      tc: 2,
      tt: 502.6,
      pk: {
        '123': {
          pc: 2,
          pt: 502.6,
        },
      },
    },
    ц: {
      tc: 3,
      tt: 489.1,
      pk: {
        '114': {
          pc: 3,
          pt: 489.1,
        },
      },
    },
    ё: {
      tc: 1,
      tt: 767.1,
      pk: {
        '100': {
          pc: 1,
          pt: 767.1,
        },
      },
    },
  },
  FrequentWords: {
    и: {
      tc: 34,
      tt: 8048.6,
      pk: {
        '141': {
          pc: 34,
          pt: 8048.6,
        },
      },
    },
    ' ': {
      tc: 150,
      tt: 15146.5,
      pk: {
        '147': {
          pc: 150,
          pt: 15146.5,
        },
      },
    },
    в: {
      tc: 21,
      tt: 5556.5,
      pk: {
        '128': {
          pc: 21,
          pt: 4799.4,
          tsc: 1,
        },
      },
    },
    н: {
      tc: 29,
      tt: 6149.9,
      pk: {
        '118': {
          pc: 29,
          pt: 6149.9,
        },
      },
    },
    е: {
      tc: 46,
      tt: 5696.9,
      pk: {
        '117': {
          pc: 46,
          pt: 5696.9,
        },
      },
    },
    а: {
      tc: 44,
      tt: 5676.1,
      pk: {
        '129': {
          pc: 44,
          pt: 5179.1,
          tsc: 1,
        },
      },
    },
    я: {
      tc: 8,
      tt: 1138.7,
      pk: {
        '137': {
          pc: 8,
          pt: 1138.7,
        },
      },
    },
    б: {
      tc: 10,
      tt: 2406.1,
      pk: {
        '144': {
          pc: 10,
          pt: 1886,
          tsc: 1,
        },
      },
    },
    ы: {
      tc: 10,
      tt: 1074.4,
      pk: {
        '127': {
          pc: 10,
          pt: 1074.4,
        },
      },
    },
    т: {
      tc: 56,
      tt: 7300.8,
      pk: {
        '142': {
          pc: 56,
          pt: 7300.8,
        },
      },
    },
    ь: {
      tc: 26,
      tt: 3708.1,
      pk: {
        '143': {
          pc: 26,
          pt: 3708.1,
        },
      },
    },
    о: {
      tc: 76,
      tt: 10813.1,
      pk: {
        '132': {
          pc: 76,
          pt: 10813.1,
        },
      },
    },
    с: {
      tc: 26,
      tt: 3703.8,
      pk: {
        '139': {
          pc: 26,
          pt: 3703.8,
        },
      },
    },
    ч: {
      tc: 12,
      tt: 1652.2,
      pk: {
        '138': {
          pc: 12,
          pt: 1652.2,
        },
      },
    },
    п: {
      tc: 16,
      tt: 2794.3,
      pk: {
        '130': {
          pc: 16,
          pt: 2794.3,
        },
      },
    },
    э: {
      tc: 2,
      tt: 1037.5,
      pk: {
        '136': {
          pc: 2,
          pt: 1037.5,
        },
      },
    },
    к: {
      tc: 16,
      tt: 3290.9,
      pk: {
        '116': {
          pc: 16,
          pt: 3290.9,
        },
      },
    },
    м: {
      tc: 21,
      tt: 3191.8,
      pk: {
        '140': {
          pc: 21,
          pt: 3191.8,
        },
      },
    },
    з: {
      tc: 12,
      tt: 1919.6,
      pk: {
        '122': {
          pc: 12,
          pt: 1919.6,
        },
      },
    },
    у: {
      tc: 14,
      tt: 2031.5,
      pk: {
        '115': {
          pc: 14,
          pt: 2031.5,
        },
      },
    },
    й: {
      tc: 15,
      tt: 1614.7,
      pk: {
        '113': {
          pc: 15,
          pt: 1614.7,
        },
      },
    },
    г: {
      tc: 13,
      tt: 2831.9,
      pk: {
        '119': {
          pc: 13,
          pt: 2831.9,
        },
      },
    },
    д: {
      tc: 34,
      tt: 5159.1,
      pk: {
        '134': {
          pc: 34,
          pt: 5159.1,
        },
      },
    },
    л: {
      tc: 19,
      tt: 2219.7,
      pk: {
        '133': {
          pc: 19,
          pt: 2219.7,
        },
      },
    },
    ж: {
      tc: 11,
      tt: 1230.7,
      pk: {
        '135': {
          pc: 11,
          pt: 1230.7,
        },
      },
    },
    ё: {
      tc: 3,
      tt: 559,
      pk: {
        '100': {
          pc: 3,
          pt: 559,
        },
      },
    },
    щ: {
      tc: 2,
      tt: 332.8,
      pk: {
        '121': {
          pc: 2,
          pt: 332.8,
        },
      },
    },
    р: {
      tc: 21,
      tt: 2474.4,
      pk: {
        '131': {
          pc: 21,
          pt: 2474.4,
        },
      },
    },
    ш: {
      tc: 4,
      tt: 365.1,
      pk: {
        '120': {
          pc: 4,
          pt: 365.1,
        },
      },
    },
    х: {
      tc: 3,
      tt: 428.1,
      pk: {
        '123': {
          pc: 3,
          pt: 428.1,
        },
      },
    },
    ц: {
      tc: 2,
      tt: 308,
      pk: {
        '114': {
          pc: 2,
          pt: 308,
        },
      },
    },
    ю: {
      tc: 1,
      tt: 355.5,
      pk: {
        '145': {
          pc: 1,
          pt: 355.5,
        },
      },
    },
  },
  Shift: {
    ф: {
      tc: 1,
      tt: 3218,
      pk: {
        '126': {
          pc: 1,
          pt: 3218,
        },
      },
    },
    Ф: {
      tc: 3,
      tt: 3385.2,
      pk: {
        '226': {
          pc: 3,
          pt: 3385.2,
        },
      },
    },
    ' ': {
      tc: 108,
      tt: 17300.4,
      pk: {
        '147': {
          pc: 108,
          pt: 17300.4,
        },
      },
    },
    ы: {
      tc: 11,
      tt: 1809,
      pk: {
        '127': {
          pc: 11,
          pt: 1809,
        },
      },
    },
    Ы: {
      tc: 2,
      tt: 908.1,
      pk: {
        '227': {
          pc: 2,
          pt: 908.1,
        },
      },
    },
    в: {
      tc: 10,
      tt: 1744,
      pk: {
        '128': {
          pc: 10,
          pt: 1744,
        },
      },
    },
    В: {
      tc: 12,
      tt: 5461.1,
      pk: {
        '228': {
          pc: 12,
          pt: 5461.1,
        },
      },
    },
    а: {
      tc: 38,
      tt: 5726.6,
      pk: {
        '129': {
          pc: 38,
          pt: 5726.6,
        },
      },
    },
    А: {
      tc: 2,
      tt: 872.7,
      pk: {
        '229': {
          pc: 2,
          pt: 872.7,
        },
      },
    },
    п: {
      tc: 4,
      tt: 712.6,
      pk: {
        '130': {
          pc: 4,
          pt: 712.6,
        },
      },
    },
    П: {
      tc: 10,
      tt: 3696,
      pk: {
        '230': {
          pc: 10,
          pt: 3696,
        },
      },
    },
    р: {
      tc: 15,
      tt: 3122,
      pk: {
        '131': {
          pc: 15,
          pt: 3122,
        },
      },
    },
    Р: {
      tc: 4,
      tt: 1661.5,
      pk: {
        '231': {
          pc: 4,
          pt: 1661.5,
        },
      },
    },
    о: {
      tc: 38,
      tt: 6457.6,
      pk: {
        '132': {
          pc: 38,
          pt: 6457.6,
        },
      },
    },
    О: {
      tc: 6,
      tt: 2045.8,
      pk: {
        '232': {
          pc: 6,
          pt: 2045.8,
        },
      },
    },
    л: {
      tc: 12,
      tt: 3728.2,
      pk: {
        '133': {
          pc: 12,
          pt: 2249.3,
          tsc: 1,
        },
      },
    },
    Л: {
      tc: 5,
      tt: 1547.2,
      pk: {
        '233': {
          pc: 5,
          pt: 1547.2,
        },
      },
    },
    д: {
      tc: 7,
      tt: 1925.4,
      pk: {
        '134': {
          pc: 7,
          pt: 1436.4,
          tsc: 1,
        },
      },
    },
    Д: {
      tc: 6,
      tt: 1786.5,
      pk: {
        '234': {
          pc: 6,
          pt: 1786.5,
        },
      },
    },
    ж: {
      tc: 4,
      tt: 902.8,
      pk: {
        '135': {
          pc: 4,
          pt: 902.8,
        },
      },
    },
    Ж: {
      tc: 4,
      tt: 1295.3,
      pk: {
        '235': {
          pc: 4,
          pt: 1295.3,
        },
      },
    },
    й: {
      tc: 17,
      tt: 4014.3,
      pk: {
        '113': {
          pc: 17,
          pt: 3480.2,
          tsc: 1,
        },
      },
    },
    Й: {
      tc: 2,
      tt: 918.7,
      pk: {
        '213': {
          pc: 2,
          pt: 918.7,
        },
      },
    },
    ц: {
      tc: 4,
      tt: 897.3,
      pk: {
        '114': {
          pc: 4,
          pt: 897.3,
        },
      },
    },
    Ц: {
      tc: 2,
      tt: 977,
      pk: {
        '214': {
          pc: 2,
          pt: 977,
        },
      },
    },
    у: {
      tc: 10,
      tt: 2083,
      pk: {
        '115': {
          pc: 10,
          pt: 2083,
        },
      },
    },
    У: {
      tc: 4,
      tt: 1497.3,
      pk: {
        '215': {
          pc: 4,
          pt: 1497.3,
        },
      },
    },
    к: {
      tc: 5,
      tt: 732.4,
      pk: {
        '116': {
          pc: 5,
          pt: 732.4,
        },
      },
    },
    К: {
      tc: 5,
      tt: 3353.5,
      pk: {
        '216': {
          pc: 5,
          pt: 3353.5,
        },
      },
    },
    е: {
      tc: 17,
      tt: 4372.2,
      pk: {
        '117': {
          pc: 17,
          pt: 2274.7,
          tsc: 2,
        },
      },
    },
    Е: {
      tc: 2,
      tt: 536.3,
      pk: {
        '217': {
          pc: 2,
          pt: 536.3,
        },
      },
    },
    н: {
      tc: 16,
      tt: 3529.6,
      pk: {
        '118': {
          pc: 16,
          pt: 3529.6,
        },
      },
    },
    Н: {
      tc: 10,
      tt: 2942.9,
      pk: {
        '218': {
          pc: 10,
          pt: 2942.9,
        },
      },
    },
    г: {
      tc: 8,
      tt: 1389.9,
      pk: {
        '119': {
          pc: 8,
          pt: 1389.9,
        },
      },
    },
    Г: {
      tc: 4,
      tt: 1561.8,
      pk: {
        '219': {
          pc: 4,
          pt: 1561.8,
        },
      },
    },
    ш: {
      tc: 5,
      tt: 924.3,
      pk: {
        '120': {
          pc: 5,
          pt: 924.3,
        },
      },
    },
    Ш: {
      tc: 2,
      tt: 644.8,
      pk: {
        '220': {
          pc: 2,
          pt: 644.8,
        },
      },
    },
    щ: {
      tc: 2,
      tt: 688.9,
      pk: {
        '121': {
          pc: 2,
          pt: 688.9,
        },
      },
    },
    Щ: {
      tc: 2,
      tt: 862.2,
      pk: {
        '221': {
          pc: 2,
          pt: 862.2,
        },
      },
    },
    з: {
      tc: 6,
      tt: 1378,
      pk: {
        '122': {
          pc: 6,
          pt: 1378,
        },
      },
    },
    З: {
      tc: 3,
      tt: 1048,
      pk: {
        '222': {
          pc: 3,
          pt: 1048,
        },
      },
    },
    я: {
      tc: 10,
      tt: 2859.5,
      pk: {
        '137': {
          pc: 10,
          pt: 2257.3,
          tsc: 1,
        },
      },
    },
    Я: {
      tc: 3,
      tt: 1207.4,
      pk: {
        '237': {
          pc: 3,
          pt: 1207.4,
        },
      },
    },
    ч: {
      tc: 7,
      tt: 1199.8,
      pk: {
        '138': {
          pc: 7,
          pt: 1199.8,
        },
      },
    },
    Ч: {
      tc: 5,
      tt: 2959,
      pk: {
        '238': {
          pc: 5,
          pt: 2054.1,
          tsc: 1,
        },
      },
    },
    с: {
      tc: 13,
      tt: 2217.4,
      pk: {
        '139': {
          pc: 13,
          pt: 2217.4,
        },
      },
    },
    С: {
      tc: 11,
      tt: 3529.8,
      pk: {
        '239': {
          pc: 11,
          pt: 3529.8,
        },
      },
    },
    м: {
      tc: 7,
      tt: 2390.2,
      pk: {
        '140': {
          pc: 7,
          pt: 1447.5,
          tsc: 1,
        },
      },
    },
    М: {
      tc: 9,
      tt: 2883.7,
      pk: {
        '240': {
          pc: 9,
          pt: 2883.7,
        },
      },
    },
    и: {
      tc: 13,
      tt: 2096.8,
      pk: {
        '141': {
          pc: 13,
          pt: 2096.8,
        },
      },
    },
    И: {
      tc: 4,
      tt: 1566.4,
      pk: {
        '241': {
          pc: 4,
          pt: 1566.4,
        },
      },
    },
    т: {
      tc: 23,
      tt: 3735.1,
      pk: {
        '142': {
          pc: 23,
          pt: 3735.1,
        },
      },
    },
    Т: {
      tc: 4,
      tt: 2124.1,
      pk: {
        '242': {
          pc: 4,
          pt: 2124.1,
        },
      },
    },
    ь: {
      tc: 18,
      tt: 5368.1,
      pk: {
        '143': {
          pc: 18,
          pt: 3470,
          tsc: 2,
        },
      },
    },
    Ь: {
      tc: 2,
      tt: 1181.6,
      pk: {
        '243': {
          pc: 2,
          pt: 1181.6,
        },
      },
    },
    б: {
      tc: 5,
      tt: 1189,
      pk: {
        '144': {
          pc: 5,
          pt: 1189,
        },
      },
    },
    Б: {
      tc: 5,
      tt: 1622.4,
      pk: {
        '244': {
          pc: 5,
          pt: 1622.4,
        },
      },
    },
    ю: {
      tc: 3,
      tt: 710.2,
      pk: {
        '145': {
          pc: 3,
          pt: 710.2,
        },
      },
    },
    Ю: {
      tc: 2,
      tt: 741.6,
      pk: {
        '245': {
          pc: 2,
          pt: 741.6,
        },
      },
    },
    '.': {
      tc: 1,
      tt: 963.2,
      pk: {
        '146': {
          pc: 1,
          pt: 963.2,
        },
      },
    },
    ',': {
      tc: 2,
      tt: 933.8,
      pk: {
        '246': {
          pc: 2,
          pt: 933.8,
        },
      },
    },
    ё: {
      tc: 1,
      tt: 630.5,
      pk: {
        '100': {
          pc: 1,
          pt: 630.5,
        },
      },
    },
  },
  Punctuation: {
    м: {
      tc: 38,
      tt: 8699.2,
      pk: {
        '140': {
          pc: 38,
          pt: 8699.2,
        },
      },
    },
    а: {
      tc: 100,
      tt: 37962,
      pk: {
        '129': {
          pc: 100,
          pt: 11706.3,
          tsc: 2,
        },
      },
    },
    л: {
      tc: 33,
      tt: 8090.5,
      pk: {
        '133': {
          pc: 33,
          pt: 6247.8,
          tsc: 2,
        },
      },
    },
    о: {
      tc: 104,
      tt: 16664.5,
      pk: {
        '132': {
          pc: 104,
          pt: 14765.2,
          tsc: 2,
        },
      },
    },
    '-': {
      tc: 13,
      tt: 4173.9,
      pk: {
        '111': {
          pc: 13,
          pt: 3295.5,
          tsc: 1,
        },
      },
    },
    '=': {
      tc: 6,
      tt: 1032.1,
      pk: {
        '112': {
          pc: 6,
          pt: 1032.1,
        },
      },
    },
    '\\': {
      tc: 38,
      tt: 10667.6,
      pk: {
        '125': {
          pc: 38,
          pt: 9106.6,
          tsc: 3,
        },
      },
    },
    с: {
      tc: 43,
      tt: 8835.4,
      pk: {
        '139': {
          pc: 43,
          pt: 8835.4,
        },
      },
    },
    е: {
      tc: 67,
      tt: 9088.3,
      pk: {
        '117': {
          pc: 67,
          pt: 8366.5,
          tsc: 1,
        },
      },
    },
    н: {
      tc: 56,
      tt: 11940.1,
      pk: {
        '118': {
          pc: 56,
          pt: 10795.4,
          tsc: 1,
        },
      },
    },
    ь: {
      tc: 23,
      tt: 4299.9,
      pk: {
        '143': {
          pc: 23,
          pt: 3831.3,
          tsc: 1,
        },
      },
    },
    ' ': {
      tc: 200,
      tt: 42387.4,
      pk: {
        '147': {
          pc: 176,
          pt: 39398.7,
          tsc: 1,
        },
        '247': {
          pc: 24,
          pt: 2027.7,
        },
      },
    },
    и: {
      tc: 51,
      tt: 6494,
      pk: {
        '141': {
          pc: 51,
          pt: 6494,
        },
      },
    },
    ш: {
      tc: 6,
      tt: 519.8,
      pk: {
        '120': {
          pc: 6,
          pt: 519.8,
        },
      },
    },
    ж: {
      tc: 12,
      tt: 2059.5,
      pk: {
        '135': {
          pc: 12,
          pt: 2059.5,
        },
      },
    },
    т: {
      tc: 66,
      tt: 10687.7,
      pk: {
        '142': {
          pc: 66,
          pt: 9869.6,
          tsc: 1,
        },
      },
    },
    з: {
      tc: 19,
      tt: 3795.2,
      pk: {
        '122': {
          pc: 19,
          pt: 3795.2,
        },
      },
    },
    в: {
      tc: 28,
      tt: 6589.5,
      pk: {
        '128': {
          pc: 28,
          pt: 6589.5,
        },
      },
    },
    д: {
      tc: 44,
      tt: 7770.5,
      pk: {
        '134': {
          pc: 44,
          pt: 7770.5,
        },
      },
    },
    п: {
      tc: 23,
      tt: 6564.6,
      pk: {
        '130': {
          pc: 23,
          pt: 6564.6,
        },
      },
    },
    к: {
      tc: 41,
      tt: 7739.1,
      pk: {
        '116': {
          pc: 41,
          pt: 7739.1,
        },
      },
    },
    у: {
      tc: 22,
      tt: 3188.9,
      pk: {
        '115': {
          pc: 22,
          pt: 3188.9,
        },
      },
    },
    р: {
      tc: 43,
      tt: 7248.7,
      pk: {
        '131': {
          pc: 43,
          pt: 7248.7,
        },
      },
    },
    й: {
      tc: 32,
      tt: 7188.8,
      pk: {
        '113': {
          pc: 32,
          pt: 5656.9,
          tsc: 2,
        },
      },
    },
    б: {
      tc: 24,
      tt: 6507.4,
      pk: {
        '144': {
          pc: 24,
          pt: 6507.4,
        },
      },
    },
    х: {
      tc: 11,
      tt: 6219.8,
      pk: {
        '123': {
          pc: 11,
          pt: 3636.8,
          tsc: 2,
        },
      },
    },
    щ: {
      tc: 6,
      tt: 1032.1,
      pk: {
        '121': {
          pc: 6,
          pt: 1032.1,
        },
      },
    },
    ы: {
      tc: 18,
      tt: 1995.9,
      pk: {
        '127': {
          pc: 18,
          pt: 1995.9,
        },
      },
    },
    ф: {
      tc: 5,
      tt: 1181.7,
      pk: {
        '126': {
          pc: 5,
          pt: 1181.7,
        },
      },
    },
    '—': {
      tc: 20,
      tt: 20486.9,
      pk: {
        '411': {
          pc: 20,
          pt: 19412.6,
          tsc: 1,
        },
      },
    },
    ч: {
      tc: 17,
      tt: 3602.4,
      pk: {
        '138': {
          pc: 17,
          pt: 3602.4,
        },
      },
    },
    я: {
      tc: 11,
      tt: 2165.1,
      pk: {
        '137': {
          pc: 11,
          pt: 2165.1,
        },
      },
    },
    ц: {
      tc: 2,
      tt: 284.7,
      pk: {
        '114': {
          pc: 2,
          pt: 284.7,
        },
      },
    },
    г: {
      tc: 16,
      tt: 4992.6,
      pk: {
        '119': {
          pc: 16,
          pt: 3879.5,
          tsc: 1,
        },
      },
    },
    ',': {
      tc: 22,
      tt: 7195,
      pk: {
        '246': {
          pc: 22,
          pt: 5488.5,
          tsc: 3,
        },
      },
    },
    '.': {
      tc: 23,
      tt: 5387.5,
      pk: {
        '146': {
          pc: 23,
          pt: 4834.9,
          tsc: 1,
        },
      },
    },
    '?': {
      tc: 22,
      tt: 5595.4,
      pk: {
        '207': {
          pc: 22,
          pt: 5595.4,
        },
      },
    },
    ё: {
      tc: 3,
      tt: 854.8,
      pk: {
        '100': {
          pc: 3,
          pt: 854.8,
        },
      },
    },
    '!': {
      tc: 23,
      tt: 7137.6,
      pk: {
        '201': {
          pc: 23,
          pt: 7137.6,
        },
      },
    },
    ':': {
      tc: 22,
      tt: 9914.2,
      pk: {
        '206': {
          pc: 22,
          pt: 9914.2,
        },
      },
    },
    э: {
      tc: 1,
      tt: 152,
      pk: {
        '136': {
          pc: 1,
          pt: 152,
        },
      },
    },
    ';': {
      tc: 24,
      tt: 13129.5,
      pk: {
        '204': {
          pc: 24,
          pt: 11669.3,
          tsc: 2,
        },
      },
    },
    ю: {
      tc: 1,
      tt: 229,
      pk: {
        '145': {
          pc: 1,
          pt: 229,
        },
      },
    },
    '(': {
      tc: 20,
      tt: 54326.8,
      pk: {
        '209': {
          pc: 20,
          pt: 43852.5,
          tsc: 8,
        },
      },
    },
    ')': {
      tc: 20,
      tt: 24705.2,
      pk: {
        '210': {
          pc: 20,
          pt: 20812.4,
          tsc: 2,
        },
      },
    },
  },
  Numbers: {
    '1': {
      tc: 38,
      tt: 8699.2,
      pk: {
        '101': {
          pc: 38,
          pt: 8699.2,
        },
      },
    },
    2: {
      tc: 100,
      tt: 37962,
      pk: {
        '102': {
          pc: 100,
          pt: 11706.3,
          tsc: 2,
        },
      },
    },
    3: {
      tc: 33,
      tt: 8090.5,
      pk: {
        '103': {
          pc: 33,
          pt: 6247.8,
          tsc: 2,
        },
      },
    },
    4: {
      tc: 104,
      tt: 16664.5,
      pk: {
        '104': {
          pc: 104,
          pt: 14765.2,
          tsc: 2,
        },
      },
    },
    5: {
      tc: 13,
      tt: 4173.9,
      pk: {
        '105': {
          pc: 13,
          pt: 3295.5,
          tsc: 1,
        },
      },
    },
    6: {
      tc: 43,
      tt: 8835.4,
      pk: {
        '106': {
          pc: 43,
          pt: 8835.4,
        },
      },
    },
    7: {
      tc: 67,
      tt: 9088.3,
      pk: {
        '107': {
          pc: 67,
          pt: 8366.5,
          tsc: 1,
        },
      },
    },
    8: {
      tc: 56,
      tt: 11940.1,
      pk: {
        '108': {
          pc: 56,
          pt: 10795.4,
          tsc: 1,
        },
      },
    },
    9: {
      tc: 23,
      tt: 4299.9,
      pk: {
        '109': {
          pc: 23,
          pt: 3831.3,
          tsc: 1,
        },
      },
    },
    0: {
      tc: 200,
      tt: 42387.4,
      pk: {
        '110': {
          pc: 176,
          pt: 39398.7,
          tsc: 1,
        },
      },
    },
  },
}

const accHighlightsLayout = KeyboardLayout.fromLayoutDefinition(new LayoutDefinition(OS.mac, KeyboardFormat.ANSI, 'russian_pc', 'ru'))

const demoLayoutIds: Record<UserLocale, Record<OS, string>> = {
  en: {
    win: 'us',
    mac: 'standard',
    lnx: 'english_us',
  },
  es: {
    win: 'spanish',
    mac: 'spanish',
    lnx: 'english_us',
  },
  de: {
    win: 'german',
    mac: 'german',
    lnx: 'german',
  },
  ru: {
    win: 'russian',
    mac: 'russian',
    lnx: 'russian',
  },
  fr: {
    win: 'french',
    mac: 'french',
    lnx: 'english_us',
  },
  it: {
    win: 'italian',
    mac: 'italian',
    lnx: 'english_us',
  },
}

export const previewLayoutDefinition = (lang: UserLocale): LayoutDefinition => {
  const os = getUserOS()
  const layoutId = demoLayoutIds[lang][os]
  return new LayoutDefinition(os, KeyboardFormat.ANSI, layoutId, lang)
}

export const previewHighlightData = (layout: KeyboardLayout) => {
  const r = Object.fromEntries(
    Object.entries(accHighlights).map(([chapterKey, chapterStats]) => {
      return [
        chapterKey,
        Object.fromEntries(
          Object.entries(chapterStats).map(([char, data]) => {
            const keyCode = accHighlightsLayout.getKeysToType(new Char(char))![0]
            const localChar = layout.getChar(keyCode.keyCode as TypeableKeyCode, keyCode.layer)
            return [localChar, data]
          }),
        ),
      ]
    }),
  )
  return r
}
