import LandingView from '@/views/landing/LandingView.vue'
import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import NotFoundView from './components/NotFoundView.vue'
import { explicitUserLocales } from './plugins/i18n'
import { useCourseStore } from './stores/courseStore'
import { useRouterStore } from './stores/routerStore'
import { useTrainingSessionStore } from './stores/trainingSessionStore'
import { useTrainingStore } from './stores/trainingStore'
import { useUserStore } from './stores/userStore'
import CourseSetupView from './views/CourseSetupView.vue'
import HomeView from './views/HomeView.vue'
import IntroView from './views/IntroView.vue'
import PaymentCancelView from './views/PaymentCancelView.vue'
import PaymentSuccessView from './views/PaymentSuccessView.vue'
import ResultView from './views/ResultView.vue'
import TypingView from './views/TypingView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      // empty is for "en"
      path: `/:locale(${explicitUserLocales.join('|')}|)`,
      name: 'landing',
      component: LandingView,
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/PrivacyView.vue'),
      meta: { directAccess: true, title: 'Privacy Policy | Typing School' },
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('./views/TermsView.vue'),
      meta: { directAccess: true, title: 'Terms of Use | Typing School' },
    },

    // onboarding
    {
      path: '/setup',
      name: 'courseSetup',
      component: CourseSetupView,
      meta: { customHeader: true, customFooter: true, title: 'Setup | Typing School' },
    },

    // home
    {
      path: '/home',
      name: 'home',
      component: HomeView,
      meta: { customHeader: true, customFooter: true, title: 'Home | Typing School' },
    },

    {
      path: '/intro',
      name: 'intro',
      meta: { customHeader: true, customFooter: true, title: 'Intro | Typing School' },
      component: IntroView,
    },
    {
      path: '/typing',
      name: 'typing',
      meta: { customHeader: true, customFooter: true, title: 'Lesson | Typing School' },
      component: TypingView,
      beforeEnter: () => {
        const userStore = useUserStore()
        if (!userStore.currentLesson) {
          return { name: 'home' }
        }
        return true
      },
    },
    {
      path: '/result',
      name: 'result',
      meta: { customHeader: false, customFooter: true, title: 'Result | Typing School' },
      component: ResultView,
      beforeEnter: () => {
        const trainingStore = useTrainingStore()
        const lastTraining = trainingStore.lastTypingResult
        if (!lastTraining) {
          return { name: 'home' }
        }
        return true
      },
    },
    {
      path: '/payment/success/:paymentId',
      name: 'paymentSuccess',
      component: PaymentSuccessView,
      meta: { customHeader: true, customFooter: true },
    },
    {
      path: '/payment/cancel',
      name: 'paymentCancel',
      component: PaymentCancelView,
      meta: { customHeader: true, customFooter: true },
    },

    { path: '/:pathMatch(.*)*', name: 'notFound', component: NotFoundView, meta: { directAccess: true } },
  ],
})

router.beforeEach(async (to, from) => {
  nextTick(() => {
    if (typeof to.meta.title === 'string') {
      document.title = to.meta.title
    }
  })

  if (!!to.meta.directAccess) {
    return true
  }

  const routerStore = useRouterStore()
  const courseStore = useCourseStore()
  const userStore = useUserStore()
  const trainingSessionStore = useTrainingSessionStore()

  const waitForUserStore = () => {
    return new Promise((resolve) => {
      if (userStore.initialized) {
        resolve('Layout store is initialized')
      } else {
        userStore.waitForInit(() => resolve('Layout store is initialized'))
      }
    })
  }
  await waitForUserStore()

  // proper redirect from index
  if (to.name === 'landing') {
    if (trainingSessionStore.isActive) {
      return { name: 'home' }
    }
    return true
  }

  // prevent home load without layout setup
  if (!courseStore.courses.length) {
    if (to.name === 'courseSetup') {
      return true
    } else {
      routerStore.nextRoute = to
      return { name: 'courseSetup' }
    }
  }

  return true
})

export default router
