import { Finger, Hand, Row } from '@/helpers/keyboards/FingerMapping'
import { i18n, type LocalizedText } from '@/plugins/i18n'
import { capitalize } from 'lodash-es'
import { Chapter, type LessonData, LessonType } from './course-types'
import type { NumTrainingType } from './numbers/course-numbers-config'
import { numTrainingItems } from './numbers/course-numbers-config'

const fingerTitles: Record<Finger, LocalizedText> = {
  [Finger.Index]: {
    en: 'index',
    ru: 'указательный',
    es: 'índice',
    de: 'Zeigefinger',
    fr: 'index',
    it: 'indice',
  },
  [Finger.Middle]: {
    en: 'middle',
    ru: 'средний',
    es: 'corazón',
    de: 'Mittelfinger',
    fr: 'majeur',
    it: 'medio',
  },
  [Finger.Ring]: {
    en: 'ring',
    ru: 'безымянный',
    es: 'anular',
    de: 'Ringfinger',
    fr: 'annulaire',
    it: 'anulare',
  },
  [Finger.Little]: {
    en: 'little',
    ru: 'мизинец',
    es: 'meñique',
    de: 'kleiner Finger',
    fr: 'auriculaire',
    it: 'mignolo',
  },
  [Finger.Thumb]: {
    en: 'thumb',
    ru: 'большой',
    es: 'pulgar',
    de: 'Daumen',
    fr: 'pouce',
    it: 'pollice',
  },
}

const handTitles: Record<Hand, LocalizedText> = {
  [Hand.Left]: {
    en: 'left',
    ru: 'левый',
    es: 'izquierda',
    de: 'links',
    fr: 'gauche',
    it: 'sinistra',
  },
  [Hand.Right]: {
    en: 'right',
    ru: 'правый',
    es: 'derecha',
    de: 'rechts',
    fr: 'droite',
    it: 'destra',
  },
}

const rowTitles: Record<Row, LocalizedText> = {
  [Row.Home]: {
    en: 'Home row',
    ru: 'Домашний ряд',
    es: 'Fila base',
    de: 'Grundreihe',
    fr: 'Ligne de base',
    it: 'Riga base',
  },
  [Row.Top]: {
    en: 'Top row',
    ru: 'Верхний ряд',
    es: 'Fila superior',
    de: 'Obere Reihe',
    fr: 'Ligne supérieure',
    it: 'Riga superiore',
  },
  [Row.Bottom]: {
    en: 'Bottom row',
    ru: 'Нижний ряд',
    es: 'Fila inferior',
    de: 'Untere Reihe',
    fr: 'Ligne inférieure',
    it: 'Riga inferiore',
  },
}

const chapterTitles: Record<Chapter, LocalizedText> = {
  [Chapter.HomeRow]: rowTitles.Home,
  [Chapter.TopRow]: rowTitles.Top,
  [Chapter.BottomRow]: rowTitles.Bottom,
  [Chapter.AdditionalLetters]: {
    en: 'Additional letters',
    ru: 'Доп. буквы',
    es: 'Letras adicionales',
    de: 'Zusätzliche Buchstaben',
    fr: 'Lettres supplémentaires',
    it: 'Lettere aggiuntive',
  },
  [Chapter.PerFinger]: {
    en: 'Per finger',
    ru: 'Каждый палец',
    es: 'Por dedo',
    de: 'Finger für Finger',
    fr: 'Doigt par doigt',
    it: 'Dito per dito',
  },
  [Chapter.Ngrams]: {
    en: 'Bigrams',
    ru: 'Биграммы',
    es: 'Bigramas',
    de: 'Bigramme',
    fr: 'Bigrammes',
    it: 'Bigrammi',
  },
  [Chapter.FrequentWords]: {
    en: 'Common words',
    ru: 'Частые слова',
    es: 'Palabras comunes',
    de: 'Häufige Wörter',
    fr: 'Mots courants',
    it: 'Parole comuni',
  },
  [Chapter.Shift]: {
    en: 'Shift key',
    ru: 'Клавиша Shift',
    es: 'Tecla Mayúsculas',
    de: 'Umschalttaste',
    fr: 'Touche Maj',
    it: 'Tasto Maiuscolo',
  },
  [Chapter.Punctuation]: {
    en: 'Punctuation',
    ru: 'Знаки препинания',
    es: 'Puntuación',
    de: 'Zeichensetzung',
    fr: 'Ponctuation',
    it: 'Punteggiatura',
  },
  [Chapter.Summary]: {
    en: 'Full review',
    ru: 'Закрепление',
    es: 'Repaso completo',
    de: 'Gesamtüberblick',
    fr: 'Révision complète',
    it: 'Ripasso completo',
  },
  [Chapter.Numbers]: {
    en: 'Numbers',
    ru: 'Цифры',
    es: 'Números',
    de: 'Zahlen',
    fr: 'Chiffres',
    it: 'Numeri',
  },
}

export const getChapterTitle = (chapter: Chapter, withNumber = false) => {
  const number = Object.keys(chapterTitles).findIndex((v) => v === chapter) + 1
  const title = chapterTitles[chapter][i18n.global.locale.value]
  return withNumber ? `${number}. ${title}` : title
}

const lessonTitles: Record<LessonType, LocalizedText> = {
  [LessonType.NewLetters]: {
    en: 'Introduction to {0}',
    ru: 'Знакомство с {0}',
    es: 'Introducción a {0}',
    de: 'Einführung in {0}',
    fr: 'Introduction à {0}',
    it: 'Introduzione a {0}',
  },
  [LessonType.FocusOnNewLetters]: {
    en: 'Practicing {0}',
    ru: 'Тренировка {0}',
    es: 'Practicando {0}',
    de: 'Üben von {0}',
    fr: 'Pratique de {0}',
    it: 'Pratica di {0}',
  },
  // NOTE: just to not get undefined, logic for this lesson is unique
  [LessonType.SingleFinger]: {
    en: '',
    ru: '',
    es: '',
    de: '',
    fr: '',
    it: '',
  },
  [LessonType.Ngram]: {
    en: 'Practicing bigram: {0}',
    ru: 'Сочетание {0}',
    es: 'Practicando bigrama: {0}',
    de: 'Üben des Bigramms: {0}',
    fr: 'Pratique du bigramme : {0}',
    it: 'Pratica del bigramma: {0}',
  },
  [LessonType.PopularLowerWords]: {
    en: 'Most common words ({0})',
    ru: 'Частые слова ({0})',
    es: 'Palabras más comunes ({0})',
    de: 'Häufigste Wörter ({0})',
    fr: 'Mots les plus courants ({0})',
    it: 'Parole più comuni ({0})',
  },
  [LessonType.ShiftLettersRow]: {
    en: '{0} with Shift',
    ru: '{0} с Shift',
    es: '{0} con Mayúsculas',
    de: '{0} mit Umschalt',
    fr: '{0} avec Maj',
    it: '{0} con Maiuscolo',
  },
  [LessonType.ShiftPerFinger]: {
    en: 'Shift by finger',
    ru: 'Shift по пальцам',
    es: 'Mayúsculas por dedo',
    de: 'Umschalt pro Finger',
    fr: 'Maj par doigt',
    it: 'Maiuscolo per dito',
  },
  [LessonType.ShiftLettersRandom]: {
    en: 'Capitalized words ({0})',
    ru: 'Слова с Shift ({0})',
    es: 'Palabras en mayúsculas ({0})',
    de: 'Wörter mit Großbuchstaben ({0})',
    fr: 'Mots avec majuscules ({0})',
    it: 'Parole con maiuscole ({0})',
  },
  [LessonType.PunctuationMark]: {
    en: 'Practicing {0}',
    ru: 'Тренировка {0}',
    es: 'Practicando {0}',
    de: 'Üben von {0}',
    fr: 'Pratique de {0}',
    it: 'Pratica di {0}',
  },
  [LessonType.AllPunctuation]: {
    en: 'Text with punctuation ({0})',
    ru: 'Текст с пунктуацией ({0})',
    es: 'Texto con puntuación ({0})',
    de: 'Text mit Zeichensetzung ({0})',
    fr: 'Texte avec ponctuation ({0})',
    it: 'Testo con punteggiatura ({0})',
  },
  [LessonType.Numbers]: {
    en: 'Numbers Practice',
    ru: 'Тренировка цифр',
    es: 'Práctica de números',
    de: 'Zahlenübung',
    fr: 'Pratique des chiffres',
    it: 'Pratica dei numeri',
  },
}

export const getLessonTitle = (lesson: LessonData | null) => {
  if (!lesson) {
    return ''
  }

  const locale = i18n.global.locale.value
  const titleTemplate = lessonTitles[lesson.type][locale]

  switch (lesson.type) {
    case LessonType.NewLetters:
      return titleTemplate.replace('{0}', lesson.metadata.chars.map((c: string) => c.toUpperCase()).join(` ${i18n.global.t('and')} `))

    case LessonType.FocusOnNewLetters:
      return titleTemplate.replace('{0}', lesson.metadata.chars.map((c: string) => c.toUpperCase()).join(` ${i18n.global.t('and')} `))

    case LessonType.SingleFinger:
      return `${capitalize(handTitles[lesson.metadata.hand as Hand][locale])} ${fingerTitles[lesson.metadata.finger as Finger][locale]}`

    case LessonType.Ngram:
      return titleTemplate.replace('{0}', lesson.metadata.ngram.toUpperCase())

    case LessonType.PopularLowerWords:
      return titleTemplate.replace('{0}', (lesson.metadata.index + 1).toString())

    case LessonType.ShiftLettersRow:
      return titleTemplate.replace('{0}', capitalize(rowTitles[lesson.metadata.row as Row][locale]))

    case LessonType.ShiftPerFinger:
      return titleTemplate

    case LessonType.ShiftLettersRandom:
      return titleTemplate.replace('{0}', (lesson.metadata.index + 1).toString())

    case LessonType.PunctuationMark:
      return titleTemplate.replace('{0}', lesson.metadata.char)

    case LessonType.AllPunctuation:
      return titleTemplate.replace('{0}', (lesson.metadata.index + 1).toString())

    case LessonType.Numbers:
      return numTrainingItems[lesson.metadata.trainingType as NumTrainingType].title[locale]

    default:
      return 'Unknown Lesson'
  }

  /*
    Example output:

    NewLetters: "Learn letters f and j"
    NewSpecialLetter: "Learn letter ü"
    FocusOnNewLetters: "Practice letters f and j" OR "Practice letter ü"
    SingleFinger: "Pinky finger warm-up"
    Ngram: "Practice bigram — th"
    PopularLowerWords: "Most frequent word — part 1"
    ShiftLettersRow: "Learn Shift — top row"
    ShiftPerFinger: "Practice Shift — left hand"
    ShiftLettersRandom: "Capitalized words"
    PunctuationMark: "Punctuation — comma"
    AllPunctuation: "All punctuation — part 1"
  */
}
