import { localStorageKey } from '@/helpers/main-config'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

export const useTrainingSessionStore = defineStore('trainingSession', () => {
  const isActive = useLocalStorage<boolean>(localStorageKey.trainingSession, false)

  const start = () => {
    isActive.value = true
  }

  const clear = () => {
    isActive.value = false
  }

  return {
    isActive,
    start,
    clear,
  }
})
