<script setup lang="ts">
import Keyboard from '@/components/Keyboard.vue'
import { Char } from '@/helpers/keyboards/KeyChar'
import { isTypeable, type KeyCode } from '@/helpers/keyboards/KeyCode'
import { KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { Layer } from '@/helpers/keyboards/Layer'
import { accuracyTone, pressesColor, speedColor } from '@/helpers/metric-color-scales'
import type { UserLocale } from '@/plugins/i18n'
import { CourseHighlightStats } from '@/types/CourseHighlightStats'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { hslToString } from '@/types/color-types'
import { Metric } from '@/types/metric-types'
import { previewHighlightData, previewLayoutDefinition } from '@/views/landing/highlights-data'
import { useElementVisibility } from '@vueuse/core'
import Color from 'colorjs.io'
import { computed, ref, watch, watchEffect, type StyleValue } from 'vue'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()

const userLayout = ref(KeyboardLayout.fromLayoutDefinition(previewLayoutDefinition(locale.value as UserLocale)))
const accHighlights = ref(previewHighlightData(userLayout.value))
watch(locale, (to) => {
  userLayout.value = KeyboardLayout.fromLayoutDefinition(previewLayoutDefinition(to as UserLocale))
  accHighlights.value = previewHighlightData(userLayout.value)
})

const keySequence: KeyCode[][][] = [
  [
    ['KeyF', 'KeyJ'],
    ['KeyD', 'KeyK'],
    ['KeyS', 'KeyL'],
    ['KeyA', 'Semicolon'],
    ['KeyG', 'KeyH'],
  ],
  [
    ['KeyR', 'KeyU'],
    ['KeyE', 'KeyI'],
    ['KeyW', 'KeyO'],
    ['KeyQ', 'KeyP'],
    ['KeyT', 'KeyY'],
  ],
  [
    ['KeyV', 'KeyM'],
    ['KeyC', 'Comma'],
    ['KeyX', 'Period'],
    ['KeyZ', 'Slash'],
    ['KeyB', 'KeyN'],
  ],
  [
    ['Quote', 'Quote'],
    ['BracketLeft', 'BracketRight'],
    ['Backslash', 'IntlBackslash'],
    ['Digit6', 'Digit7'],
    ['Digit5', 'Digit8'],
    ['Digit4', 'Digit9'],
    ['Digit3', 'Digit0'],
    ['Digit2', 'Minus'],
    ['Digit1', 'Equal'],
    ['Backquote', 'Backquote'],
  ],
  // [
  //   ['Quote', 'Backquote'],
  //   ['BracketLeft', 'BracketRight'],
  //   ['Backslash', 'IntlBackslash'],
  //   ['Digit1', 'Digit2'],
  //   ['Digit3', 'Digit4'],
  //   ['Digit5', 'Digit6'],
  //   ['Digit7', 'Digit8'],
  //   ['Digit9', 'Digit0'],
  //   ['Minus', 'Equal'],
  // ],
]

const allMetrics = [Metric.Accuracy, Metric.Speed]
const metricIndex = ref(0)
const metric = computed(() => allMetrics[metricIndex.value])

const metricModelValue = defineModel('metric')
watchEffect(() => {
  metricModelValue.value = metric.value
})

const keysIncludedCount = ref(0)

const keysIncludes = computed(() => {
  return keySequence.flat(2).slice(0, keysIncludedCount.value)
})

const data = computed(() => {
  return CourseHighlightStats.parse(
    Object.fromEntries(
      Object.entries(accHighlights.value).map(([chapterKey, chapterStats]) => {
        return [
          chapterKey,
          Object.fromEntries(
            Object.entries(chapterStats).filter(([char]) => {
              const keyCode = userLayout.value.getKeysToType(new Char(char))?.[0].keyCode
              return keyCode && keysIncludes.value.includes(keyCode)
            }),
          ),
        ]
      }),
    ),
  )
})

const dataPerKey = computed(() => {
  return data.value.perKeycode(metric.value)
})

// single num value or [min, mid, max]
const rangePerLayer = computed<Partial<Record<Layer, number[]>>>(() => {
  return Object.fromEntries(
    Object.entries(dataPerKey.value.perLayer()).map(([k, v]) => {
      const values = v.values()
      const min = Math.min(...values)
      const max = Math.max(...values)
      return [k, [min, (min + max) / 2, max]]
    }),
  )
})

const currentLayer = ref(Layer.Default)

const calcColor = (value: number, range: number[]) => {
  const [min, , max] = range

  switch (metric.value) {
    case Metric.Speed:
      return speedColor(value, min, max)
    case Metric.Accuracy:
      return hslToString(accuracyTone(value, min, max))
    case Metric.Presses:
      return pressesColor(value, min, max)
    default:
      throw new Error('Unknown metric to calc color')
  }
}

const currentLayerRange = computed(() => {
  return rangePerLayer.value[currentLayer.value]
})

const currentLayerRangeValuesModel = defineModel('rangeValues')
const currentLayerRangeColorsModel = defineModel('rangeColors')

watchEffect(() => {
  currentLayerRangeValuesModel.value = currentLayerRange.value
  currentLayerRangeColorsModel.value = currentLayerRange.value ? currentLayerRange.value.map((v, i, arr) => calcColor(v, arr)) : null
})

const keyStyleFunc = (code: KeyCode, value: string, keyboardState: Layer): StyleValue => {
  if (!isTypeable(code)) {
    return {
      color: 'transparent',
      '--c-icon': 'transparent',
    }
  }

  let resultStyle: StyleValue = {
    fontSize: 'calc(var(--keyboard-size-unit) * 3.5)',
  }

  const charData = dataPerKey.value.get(new LayeredKeyCode(code, keyboardState))
  if (!charData) {
    return resultStyle
  }

  const range = rangePerLayer.value[keyboardState]
  if (!range) {
    throw new Error('No data for whole layer')
  }

  const color = calcColor(charData, range)

  const borderColor = new Color(color)
  borderColor.alpha = borderColor.alpha > 0.3 ? borderColor.alpha / 2 : borderColor.alpha

  resultStyle['--background-color'] = color
  resultStyle['--border-color'] = metric.value === Metric.Accuracy ? color : borderColor.toString()
  resultStyle['--text-color'] = metric.value === Metric.Accuracy ? '#000' : 'var(--c-text-primary)'

  return {
    ...resultStyle,
  }
}

// lifecycle

const intervalId = ref<ReturnType<typeof setInterval> | undefined>(undefined)
const intervalFn = () => {
  if (keySequence.flat(2).length <= keysIncludedCount.value) {
    keysIncludedCount.value = 0
    metricIndex.value = metricIndex.value + 1 >= allMetrics.length ? 0 : metricIndex.value + 1
    return
  }

  const toAdd = 2
  // const toAdd = keysIncludedCount.value < 30 ? 2 : 1
  keysIncludedCount.value += toAdd
}

const keyboardRef = ref(null)
const visible = useElementVisibility(keyboardRef)

watch(
  visible,
  (to) => {
    clearInterval(intervalId.value)
    intervalId.value = undefined

    if (to) {
      intervalId.value = setInterval(intervalFn, 150)
    } else {
      keysIncludedCount.value = 0
      metricIndex.value = 0
    }
  },
  { immediate: true },
)
</script>

<template>
  <Keyboard
    ref="keyboardRef"
    :layout="userLayout"
    :keyStyleFunc="keyStyleFunc"
    outlined
    :handlePresses="false"
    unbordered
    v-model:layoutState="currentLayer"
  />
</template>

<style lang="scss" scoped></style>
