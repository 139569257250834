// HSL color type

export type HSL = {
  hue: number
  saturation: number
  lightness: number
  opacity: number
}

export const hsl = (hue: number, saturation: number, lightness: number, opacity: number = 100): HSL => {
  return { hue, saturation, lightness, opacity }
}

export const hslToString = (hsl: HSL): string => {
  return `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%, ${hsl.opacity}%)`
}

// via GPT
export const hexToHsl = (hex: string): HSL => {
  hex = hex.replace(/^#/, '')

  // Parse the r, g, b values
  let bigint = parseInt(hex, 16)
  let r = (bigint >> 16) & 255
  let g = (bigint >> 8) & 255
  let b = bigint & 255

  // Convert r, g, b values to the range of 0 - 1
  r /= 255
  g /= 255
  b /= 255

  // Find the minimum and maximum values of r, g, and b
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h = 0,
    s = 0,
    l = (max + min) / 2 // Luminance

  if (max !== min) {
    let d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min) // Saturation

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6 // Normalize hue to be between 0 and 1
  }

  // Convert to percentage and scale hue to 360 degrees
  return {
    hue: Math.round(h * 360),
    saturation: Math.round(s * 100),
    lightness: Math.round(l * 100),
    opacity: 100, // default to fully opaque
  }
}
export const rgbToHsl = (rgb: RGB): HSL => {
  let { r, g, b } = rgb

  // Convert r, g, b from range 0-255 to 0-1
  r /= 255
  g /= 255
  b /= 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = 0,
    s = 0,
    l = (max + min) / 2 // Lightness

  if (max !== min) {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min) // Saturation

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6 // Normalize hue to 0-1
  }

  return {
    hue: Math.round(h * 360), // Convert hue to degrees (0-360)
    saturation: Math.round(s * 100), // Convert saturation to percentage
    lightness: Math.round(l * 100), // Convert lightness to percentage
    opacity: 100, // Default to full opacity
  }
}

// RGB color type

export type RGB = {
  r: number
  g: number
  b: number
}

// Shared

export const hslToRgb = (hsl: HSL): RGB => {
  const h = hsl.hue / 360
  const s = hsl.saturation / 100
  const l = hsl.lightness / 100
  let r, g, b

  if (s === 0) {
    r = g = b = l // Achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number): number => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return { r: r * 255, g: g * 255, b: b * 255 }
}

export const getContrastTextColor = (background: HSL): HSL => {
  const rgb = hslToRgb(background)
  const relativeLuminance = (val: number): number => {
    val /= 255
    return val <= 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4)
  }

  const luminance = 0.2126 * relativeLuminance(rgb.r) + 0.7152 * relativeLuminance(rgb.g) + 0.0722 * relativeLuminance(rgb.b)
  const lightnessThreshold = 0.179 // Adjust as needed

  return luminance > lightnessThreshold ? hsl(0, 0, 0, 100) : hsl(0, 0, 100, 100)
}
