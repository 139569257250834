import type { KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import type { LanguageMetadata } from '@/languages/languages-config'

export enum Charset {
  Lower = 0,
  Upper,
  Punctuation,
  Digits,
  Other,
}

export const allCharsets = [Charset.Lower, Charset.Upper, Charset.Punctuation, Charset.Digits, Charset.Other]

export const CharsetTitle: Record<Charset, string> = {
  [Charset.Lower]: 'Lower letters',
  [Charset.Upper]: 'Upper letters',
  [Charset.Punctuation]: 'Punctuation',
  [Charset.Digits]: 'Digits',
  [Charset.Other]: 'Other chars',
}

export type PartialCharsetMap = {
  [Charset.Lower]: string[]
  [Charset.Upper]: string[]
  [Charset.Punctuation]: string[]
}

export const allDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
export const someSpecialChars = ['_', '=', '*', '$', '>', '{', '}', '\\', '[', ']', '&', '+', '<', '|', '@', '#', '%', '~', '^', '`']

export const generateLegacyCharsets = (metadata: LanguageMetadata) => {
  return {
    [Charset.Lower]: metadata.lowerLetters,
    [Charset.Upper]: metadata.upperLetters,
    [Charset.Punctuation]: metadata.punctuation.map((p) => p.char),
  }
}

export const getFullCharsetMap = (metadata: LanguageMetadata, layout: KeyboardLayout, withSpace = false) => {
  const result = {
    ...generateLegacyCharsets(metadata),
    [Charset.Digits]: allDigits,
    [Charset.Other]: [] as string[],
  }

  const allDefinedChars = Object.values(result).flat()
  layout.allChars(withSpace).forEach((char) => {
    if (allDefinedChars.includes(char)) return
    result[Charset.Other].push(char)
  })

  return result
}
