import type { FullTypingResult } from '@/helpers/Trainer'
import { MAX_CALENDAR_DATA_DAYS } from '@/helpers/main-config'
import { toFixed, toISODateString, type DateStringKey } from '@/helpers/main-utils'
import { useUserStore } from '@/stores/userStore'
import dayjs from 'dayjs'
import { isEmpty, random } from 'lodash-es'
import { CharTypingResult } from './typing-result/CharTypingResult'

export class CourseCalendarStats {
  private _previewValue: Record<DateStringKey, CalendarDayStats> | null = null

  constructor(private _value: Record<DateStringKey, CalendarDayStats>) {}

  get value(): Record<DateStringKey, CalendarDayStats> {
    const userStore = useUserStore()
    return userStore.demoData ? this.previewData() : this._value
  }

  isEmpty() {
    return isEmpty(this._value)
  }

  update(fullResult: FullTypingResult, typingResult: CharTypingResult) {
    const trainingStartTime = fullResult.startedAt
    const currentHour = trainingStartTime.get('hour')
    const dateStringKey = toISODateString(trainingStartTime)

    const todayData = this._value[dateStringKey]
    if (todayData) {
      // append
      todayData.totalTypeTimeMsPerHour[currentHour] = toFixed(typingResult.typingTimeMs + (todayData.totalTypeTimeMsPerHour[currentHour] ?? 0), 2)
      todayData.trainingCount += 1
    } else {
      // new training
      this._value[dateStringKey] = {
        totalTypeTimeMsPerHour: { [currentHour]: toFixed(typingResult.typingTimeMs, 2) },
        trainingCount: 1,
      }

      // check if we should remove oldest day
      const dates = Object.keys(this._value)
      const oldestDate = trainingStartTime.subtract(MAX_CALENDAR_DATA_DAYS, 'days')
      const tooOldDates = dates.filter((d) => dayjs(d).isBefore(oldestDate))
      for (const date of tooOldDates) {
        delete this._value[date]
      }
    }
  }

  // to Firebase or localStorage object
  serialize(): CourseCalendarStatsSzd {
    return Object.fromEntries(
      Object.entries(this._value).map(([date, dateData]) => [
        date,
        {
          ttph: dateData.totalTypeTimeMsPerHour,
          trc: dateData.trainingCount,
        },
      ]),
    )
  }

  // from Firebase or localStorage object
  static parse(serialized: CourseCalendarStatsSzd): CourseCalendarStats {
    return new CourseCalendarStats(
      Object.fromEntries(
        Object.entries(serialized).map(([date, dateData]) => [
          date,
          {
            totalTypeTimeMsPerHour: dateData.ttph,
            trainingCount: dateData.trc,
          },
        ]),
      ),
    )
  }

  static template() {
    return new CourseCalendarStats({})
  }

  previewData(): Record<DateStringKey, CalendarDayStats> {
    if (this._previewValue !== null) {
      return this._previewValue
    }

    const today = dayjs().startOf('day')

    let trainingsPerDay: Record<string, number> = {}
    for (let i = 0; i < 30; i++) {
      const noTrainingsToday = Math.random() < 0.25
      const todayTrainings = !noTrainingsToday ? random(5, 20) : 0
      trainingsPerDay[toISODateString(today.subtract(i, 'days'))] = todayTrainings
    }

    let timePerHour = Array.from({ length: 24 }, () => 0)
    for (let i = 0; i < 23; i++) {
      const hourTypingTimeSec = i < 3 || i > 20 ? 0 : random(100, 3000)
      timePerHour[i] = hourTypingTimeSec
    }

    const result = Object.fromEntries(Object.entries(trainingsPerDay).map(([k, v]) => [k, { trainingCount: v, totalTypeTimeMsPerHour: timePerHour }]))
    this._previewValue = result
    return result
  }
}
export type CourseCalendarStatsSzd = Record<DateStringKey, CalendarDayStatsSzd>

export type CalendarDayStats = {
  totalTypeTimeMsPerHour: Record<number, number>
  trainingCount: number
}
export type CalendarDayStatsSzd = {
  ttph: Record<number, number>
  trc: number
}
