const varsList = [
  '--c-background',
  '--c-surface',
  '--c-surface-elevated',
  '--c-divider',
  '--c-text-primary',
  '--c-icon',
  '--c-text-secondary',
  '--c-text-tertiary',
  '--c-text-tertiary-on-primary',
  '--c-primary',
  '--c-primary-border',
  '--c-primary-hover',
  '--c-primary-active',
  '--c-text-on-primary',
  '--c-primary-text',
  '--c-primary-light',
  '--c-primary-subdued',
  '--c-primary-subdued-border',
  '--c-primary-subdued-hover',
  '--c-primary-subdued-active',
  '--c-primary-subdued-text',
  '--c-secondary',
  '--c-secondary-border',
  '--c-secondary-border-darker',
  '--c-secondary-hover',
  '--c-secondary-active',
  '--c-secondary-text',
  '--c-secondary-icon',
  '--c-keyboard-key-border',
  '--c-keyboard-key-shadow',
  '--c-keyboard-key-gradient-from',
  '--c-keyboard-key-gradient-to',
  '--c-keyboard-key-shadow-alternate',
  '--c-keyboard-key-gradient-from-alternate',
  '--c-keyboard-key-gradient-to-alternate',
  '--c-keyboard-key-text',
  '--c-keyboard-key-text-on-active',
  '--c-keyboard-key-bg-to-press',
  '--c-keyboard-key-to-press-shadow',
  '--c-keyboard-key-to-press-border',
  '--c-keyboard-gradient-from',
  '--c-keyboard-gradient-to',
  '--c-keyboard-bottom-shadow',
  '--c-hand-bg',
  '--c-hand-active-finger',
  '--c-hand-shadow',
  '--c-hand-nail-start',
  '--c-hand-nail',
  '--c-danger-text',
  '--c-danger-border',
  '--c-danger-bg',
  '--c-text-danger',
  '--c-success-text',
  '--c-success-border',
  '--c-success-bg',
  '--c-warning-text',
  '--c-warning-bg',
  '--c-backdrop',
]

export const generateRgbVars = () => {
  const bodyStyles = getComputedStyle(document.body)
  const body = document.body

  for (const variable of varsList) {
    const hexValue = bodyStyles.getPropertyValue(variable).trim()

    const r = parseInt(hexValue.slice(1, 3), 16)
    const g = parseInt(hexValue.slice(3, 5), 16)
    const b = parseInt(hexValue.slice(5, 7), 16)

    const rgbValue = `${r} ${g} ${b}`
    body.style.setProperty(`${variable}-rgb`, rgbValue)
  }
}
