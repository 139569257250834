<script setup lang="ts">
import { toFixed } from '@/helpers/main-utils'
import { useUserStore } from '@/stores/userStore'
import { Metric, Speed } from '@/types/metric-types'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const userStore = useUserStore()

type Props = {
  rangeValues: number[] // wpm, %
  rangeColors: string[]
  metric: Metric
}
const props = defineProps<Props>()

const items = computed(() => {
  let result = props.rangeValues.map((v, i) => ({ value: toFixed(v, 0), color: props.rangeColors[i] }))

  if (result[0].value === result[2].value) {
    // min = max
    result = result.slice(0, 1)
  } else if (result[0].value === result[1].value || result[1].value === result[2].value) {
    // min = med OR med = max
    result = [result[0], result[2]]
  }

  return result
})
</script>

<template>
  <div class="legend-wrapper">
    <div class="legend-item" v-for="item in items" :key="item.value">
      <div class="label" v-if="metric === Metric.Speed">
        {{ new Speed(item.value * 5).format({ appendUnit: false, unit: userStore.settings.speedUnit }) }}
        <span class="unit">{{ t(userStore.settings.speedUnit) }}</span>
      </div>
      <div class="label" v-else-if="metric === Metric.Accuracy">
        {{ item.value }}
        <span class="unit">%</span>
      </div>
      <div class="label" v-else>
        {{ item.value }}
      </div>
      <div class="color" :style="{ backgroundColor: item.color }"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.legend-wrapper {
  display: flex;
  gap: var(--s-unit);

  .label {
    font-size: var(--fz-xs);
    margin-bottom: var(--s-unit);
    padding: 0 6px;
    min-width: 36px;
    text-align: center;
    font-weight: 500;

    .unit {
      color: var(--c-text-tertiary);
    }
  }

  .color {
    width: 100%;
    height: var(--s-unit);
    border-radius: 999px;
  }
}
</style>
