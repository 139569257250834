// export const OperationalSystems = {
//   mac: 'mac',
//   win: 'win',
//   lin: 'lnx',
// } as const

// export type OS = keyof typeof OperationalSystems

export enum OS {
  mac = 'mac',
  win = 'win',
  lnx = 'lnx',
}

export const allOS: OS[] = [OS.mac, OS.win, OS.lnx]
