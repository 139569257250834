import { isTypeable, typeableKeyCodes, type KeyCode } from '@/helpers/keyboards/KeyCode'
import type { KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { Layer } from '@/helpers/keyboards/Layer'

export class LayeredKeyCode {
  constructor(
    public keyCode: KeyCode,
    public layer: Layer,
  ) {}

  format(layout: KeyboardLayout) {
    if (isTypeable(this.keyCode)) {
      return layout.getChar(this.keyCode, this.layer)
    }
    return this.keyCode
  }

  serialize() {
    const keyCodeIndex = typeableKeyCodes.findIndex((code) => code === this.keyCode) // always < 100
    if (keyCodeIndex === -1) {
      return `${this.layer}${this.keyCode}` // example: 1Escape
    }
    return parseInt(`${this.layer}${keyCodeIndex < 10 ? '0' : ''}${keyCodeIndex}`) // example: 100 (1 - default layer, 00 - Backquote)
  }

  id() {
    return this.serialize()
  }

  toJSON() {
    return this.serialize()
  }

  static parse(raw: number | string) {
    const layerIndex = parseInt(raw.toString()[0])
    // for typeable keycode it's number, but for others it's raw keycode
    const keyCodeOrIndex = raw.toString().slice(1)
    const keyCodeIndex = parseInt(keyCodeOrIndex)
    return new LayeredKeyCode(isNaN(keyCodeIndex) ? (keyCodeOrIndex as KeyCode) : typeableKeyCodes[keyCodeIndex], layerIndex)
  }
}

export type LayeredKeycodeId = number | string
