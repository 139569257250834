import type { OS } from '@/types/main-types'
import { Char, KeyChar } from './KeyChar'
import type { TypeableKeyCode } from './KeyCode'
import { Layer, allLayersByPriority, type LayersMap } from './Layer'

export class KeyboardKey {
  public code: TypeableKeyCode
  public keyChars: LayersMap<KeyChar>

  constructor(keyCode: TypeableKeyCode) {
    this.code = keyCode
    this.keyChars = Object.fromEntries(allLayersByPriority.map((l) => [l, KeyChar.template()])) as LayersMap<KeyChar>
  }

  static Space() {
    const result = new KeyboardKey('Space')
    result.keyChars = {
      [Layer.Default]: KeyChar.Space(),
      [Layer.Shift]: KeyChar.Space(),
      [Layer.CapsDefault]: KeyChar.Space(),
      [Layer.CapsShift]: KeyChar.Space(),
      [Layer.Option]: KeyChar.Space(),
      [Layer.ShiftOption]: KeyChar.Space(),
      [Layer.CapsOption]: KeyChar.Space(),
      [Layer.CapsShiftOption]: KeyChar.Space(),
    }
    return result
  }

  containsChar(char: Char): Layer | false {
    for (const [layer, keyChar] of Object.entries(this.keyChars)) {
      if (keyChar.char.isEqual(char)) {
        return Number(layer)
      }
    }
    return false
  }

  renderKeycap(layer: Layer, os: OS) {
    // if (layer > 4 && !this.symbols[layer].validValue) {
    //   // @ts-expect-error
    //   return this.symbols[layer - 4].validValue
    // }

    return this.keyChars[layer].value
  }

  isEqual(other: KeyboardKey) {
    for (const [layer, symbol] of Object.entries(this.keyChars)) {
      if (!symbol.isEqual(other.keyChars[layer as unknown as Layer])) {
        return false
      }
    }
    return true
  }
}
