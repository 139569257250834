import type { TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { getLayerFromKeyboardEvent } from '@/helpers/keyboards/Layer'
import { isMacOS } from '@/helpers/user-agent-utils'
import { KeyboardShortcut, type ModifierKey } from '@/types/KeyboardShortcut'
import { isEqual } from 'lodash-es'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useCourseStore } from './courseStore'

export const useShortcutStore = defineStore('shortcut', () => {
  const courseStore = useCourseStore()

  const activeModalId = ref<string | null>(null)
  const shortcuts = ref<KeyboardShortcut[]>([])

  const capsLockOn = ref(false)

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'CapsLock') {
      capsLockOn.value = !capsLockOn.value
      return
    }

    let modifiers: ModifierKey[] = []
    if (event.shiftKey) modifiers.push('Shift')
    if (event.altKey) modifiers.push('Alt')
    if (isMacOS() ? event.metaKey : event.ctrlKey) modifiers.push('Meta')

    let char = event.key
    // getting char from current layout config to resolve non-latin layout problem
    if (courseStore.currentExists) {
      const key = event.code as TypeableKeyCode
      const symbols = courseStore.current.layout.shortcutKeymap.get(key)?.keyChars
      if (symbols) {
        const layer = getLayerFromKeyboardEvent(event, capsLockOn.value)
        char = symbols[layer].value
      }
    }

    for (const shortcut of shortcuts.value) {
      if (
        shortcut.char.toUpperCase() === char.toUpperCase() &&
        ((shortcut.acceptAnyModifiers && !modifiers.includes('Meta')) || // ignore Shift/Alt, but don't execute for Cmd/Ctrl
          isEqual(shortcut.modifiersList.sort(), modifiers.sort())) &&
        shortcut.modalId === activeModalId.value
      ) {
        event.preventDefault()
        shortcut.action(event)
      }
    }
  }

  window.addEventListener('keydown', handleKeydown)

  const addShortcuts = (newShortcuts: KeyboardShortcut[]) => {
    // sorting is important, it allows to resolve conflicts between same-char but diff-modifier shortcuts
    // TODO when needed, also sort by modifier priority (1 meta/ctrl, 2 alt, 3 shift)
    shortcuts.value = [...shortcuts.value, ...newShortcuts]
    // .sort((a, b) => Object.values(b.modifiers).length - Object.values(a.modifiers).length)
  }

  const removeShortcuts = (shortcutsToRemove: KeyboardShortcut[]) => {
    if (shortcutsToRemove.some((s) => s.modalId === activeModalId.value)) {
      activeModalId.value = null
    }
    shortcuts.value = shortcuts.value.filter((shortcut) => {
      return !shortcutsToRemove.includes(shortcut)
    })
  }

  return {
    activeModalId,
    shortcuts,
    addShortcuts,
    removeShortcuts,
  }
})
