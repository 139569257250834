import 'tippy.js/animations/shift-away.css'
import 'tippy.js/dist/border.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/dist/tippy.css'
import type { App } from 'vue'
import VueTippy from 'vue-tippy'

const arrowSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" fill="none">
    <g>
      <path fill="currentColor" fill-rule="evenodd" d="M10 0 C13 0 16 8.75 20 8.75 V10 H0 V8.75 C3.975 8.75 7 0 10 0 Z"
        clip-rule="evenodd" />
    </g>
  </svg>
`
export const installTippy = (app: App) => {
  app.use(VueTippy, {
    directive: 'tippy',
    defaultProps: {
      animation: 'shift-away',
      arrow: arrowSvg,
      theme: 'ts',
      border: true,
      offset: [0, 12],
      // for debugging:
      // hideOnClick: false,
      // trigger: 'click',
    },
  })
}
