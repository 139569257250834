// 1 home row + problems
// 2 top row + problems
// 3 bottom row + problems
// 4 skipped letters
// 5 per finger
// 6 ngrams + problems
// 7 freq words + problems
// 8 shift + problems
// 9 punctuation + problems

import type { KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'

export enum Chapter {
  HomeRow = 'HomeRow',
  TopRow = 'TopRow',
  BottomRow = 'BottomRow',
  AdditionalLetters = 'AdditionalLetters',
  PerFinger = 'PerFinger',
  Ngrams = 'Ngrams',
  FrequentWords = 'FrequentWords',
  Shift = 'Shift',
  Punctuation = 'Punctuation',
  Summary = 'Summary',
  Numbers = 'Numbers',
}

export enum LessonType { //                                         metadata format:
  NewLetters = 'NewLetters', // ffj jjf ffjj ...                    {chars: [f, j], keycodes: TypeableKeyCode[]}    if one char, it needs special treatment (like ё or ü)
  FocusOnNewLetters = 'FocusOnNewLetters', // fkkdk dkkdd jdkk ...  {chars: [d,k], keycodes: TypeableKeyCode[]}
  SingleFinger = 'SingleFinger', // azq zqa zqa ...                 {hand: Hand, finger: Finger}
  Ngram = 'Ngram', // th the there ...                              {ngram: 'th'}
  PopularLowerWords = 'PopularLowerWords', // the, or, ...          {index: 0}
  ShiftLettersRow = 'ShiftLettersRow', // qQ wW ...                 {row: Row}
  ShiftPerFinger = 'ShiftPerFinger', // AQZ :P? ...                 {}
  ShiftLettersRandom = 'ShiftLettersRandom', // Apple Beard ...     {index: 0}
  PunctuationMark = 'PunctuationMark', // me, you, we ...           {char: ',', type: PunctuationMarkType}
  AllPunctuation = 'AllPunctuation', // Hello! Are we? ...          {index: 0, punctuation: {char: ',', type: PunctuationMarkType}[]}
  Numbers = 'Numbers', // 123456... (misc forms)                    {trainingType: NumTrainingType}
  // Problems = 'Problems', //                                      {chapter: Chapter}
}

export enum TrainerView {
  Info = 'Info',
  RunningLine = 'RunningLine',
  ThreeLines = 'ThreeLines',
}

export type LessonData = {
  type: LessonType
  view: TrainerView
  uniqueId: string
  availableOnTrial: boolean
  doneIfAddedLater?: boolean
  metadata?: any
}

export type Course = {
  layout: KeyboardLayout
  content: Record<Chapter, LessonData[]>
}

export type LessonCoords = { chapter: Chapter; index: number }
