import type { TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { Layer } from '@/helpers/keyboards/Layer'
import { randomMinMax } from '@/helpers/main-utils'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { DigitsData } from '@/types/DigitsData'
import { Speed } from '@/types/metric-types'
import { sample, shuffle, sum } from 'lodash-es'
import { NumTrainingType } from './course-numbers-config'

const MIN_NUMBER_LENGTH = 1
const MAX_NUMBER_LENGTH = 5
const TRAINING_DURATION_MS = 2 * 60 * 1000 // 2 min
const CHUNKS_COUNT = 3 // to calc safe training length based on chunk
const SAFE_CHUNK_SIZE = 0.9 // 90% to be sure user will type everything even if he is slower than usual
const DEFAULT_AVG_NUMS_SPEED = new Speed(25 * 5) // to calc perfect chunk
const DEFAULT_MAX_NUMS_SPEED = new Speed(100 * 5) // to calc safe training length
const TRAININGS_FOR_STATS = 12 // problem keys, problem numbers, avg speed
// const TOP_PROBLEM_NUMBERS = 10 // both for slow and error numbers

// digits could have 0-1 error priority and 0-1 speed priority
// we add NORMALIZATION_SHIFT to all numbers so low-priority digits are presented too
export const PRIORITY_NORMALIZATION_SHIFT = 0.5 // 0.25 seems to low, 1 seems to high, but feel free to adjust

export function generateNumTraining(trainingType: NumTrainingType, wordDict: string[], lessonLength: number): string {
  const userStore = useUserStore()
  const mapping = userStore.settings.fingerMapping === 'optimized' ? 0 : 1

  switch (trainingType) {
    case NumTrainingType.DigitAscDesc:
      return digitAscDesc(lessonLength)
    case NumTrainingType.DigitAscDescSpaced:
      return digitAscDescSpaced(lessonLength)
    case NumTrainingType.DigitAscDescHomeRow:
      return digitAscDescHomeRow(mapping, lessonLength)
    case NumTrainingType.DigitAscDescHomeRowSpaced:
      return digitAscDescHomeRowSpaced(mapping, lessonLength)
    case NumTrainingType.PairPattern1:
      return pairPattern1(mapping, lessonLength)
    case NumTrainingType.PairPattern2:
      return pairPattern2(mapping, lessonLength)
    case NumTrainingType.PairPattern3:
      return pairPattern3(mapping, lessonLength)
    case NumTrainingType.PairPattern4:
      return pairPattern4(mapping, lessonLength)
    case NumTrainingType.FibonacciSequence:
      return fibonacciSequence(lessonLength)
    case NumTrainingType.PowersOfTwoSequence:
      return powersOfTwoSequence(lessonLength)
    case NumTrainingType.SquareNumbersSequence:
      return squareNumbersSequence(lessonLength)
    case NumTrainingType.RandomNums:
      return generateNumbersTraining(false, undefined, wordDict, lessonLength)
    case NumTrainingType.RandomNumsWithWords:
      return generateNumbersTraining(true, undefined, wordDict, lessonLength)
    case NumTrainingType.LeftHand:
      return leftHand(mapping, wordDict, lessonLength)
    case NumTrainingType.RightHand:
      return rightHand(mapping, wordDict, lessonLength)
    default:
      throw Error('Unknown training type')
  }
}

function generateChunk(chunkSize: number, scores: DigitsData<number>, insertWords = true, wordInsertionFunc: () => string) {
  // about 34-35% of training are nums in current configuration
  // if only nums and spaces, then 75% of training are nums
  chunkSize = insertWords ? chunkSize * 0.34 : chunkSize * 0.75
  const scoresSum = sum(scores.rawValue)
  const multiplier = chunkSize / scoresSum
  let digits: number[] = []
  for (let i = 0; i < scores.rawValue.length; i++) {
    const occurence = Math.round(scores.rawValue[i] * multiplier)
    for (let j = 0; j < occurence; j++) {
      digits.push(i)
    }
  }
  digits = shuffle(digits)

  let numStr = digits.join('')
  let numbers = []
  while (numStr.length) {
    const numberLength = randomMinMax(MIN_NUMBER_LENGTH, MAX_NUMBER_LENGTH)
    if (numStr.length >= numberLength) {
      // ok
      if (insertWords) {
        numbers.push(wordInsertionFunc())
      }
      numbers.push(numStr.slice(0, numberLength))
      numStr = numStr.slice(numberLength)
    } else if (numStr.length >= MIN_NUMBER_LENGTH) {
      // min
      if (insertWords) {
        numbers.push(wordInsertionFunc())
      }
      numbers.push(numStr)
      numStr = ''
    } else {
      // make it zero and exit
      numStr = ''
    }
  }
  return numbers.join(' ')
}

function generateNumbersTraining(insertWords = true, digitWeights = DigitsData.init(1), wordDict: string[], lessonLength: number) {
  const wordInsertionFunc = () => sample(wordDict)!

  // about 34-35% of training are nums in current configuration
  // if only nums and spaces, then 75% of training are nums
  lessonLength = insertWords ? lessonLength * 0.34 : lessonLength * 0.75
  const scoresSum = sum(digitWeights.rawValue)
  const multiplier = lessonLength / scoresSum
  let digits: number[] = []
  for (let i = 0; i < digitWeights.rawValue.length; i++) {
    const occurence = Math.round(digitWeights.rawValue[i] * multiplier)
    for (let j = 0; j < occurence; j++) {
      digits.push(i)
    }
  }
  digits = shuffle(digits)

  let numStr = digits.join('')
  let numbers = []
  while (numStr.length) {
    const numberLength = randomMinMax(MIN_NUMBER_LENGTH, MAX_NUMBER_LENGTH)
    if (numStr.length >= numberLength) {
      // ok
      if (insertWords) {
        numbers.push(wordInsertionFunc())
      }
      numbers.push(numStr.slice(0, numberLength))
      numStr = numStr.slice(numberLength)
    } else if (numStr.length >= MIN_NUMBER_LENGTH) {
      // min
      if (insertWords) {
        numbers.push(wordInsertionFunc())
      }
      numbers.push(numStr)
      numStr = ''
    } else {
      // make it zero and exit
      numStr = ''
    }
  }
  return numbers.join(' ')
}

function leftHand(mapping: 0 | 1, wordDict: string[], lessonLength: number) {
  const digitWeights = [new DigitsData([0, 1, 1, 1, 1, 1, 1, 0, 0, 0]), new DigitsData([0, 1, 1, 1, 1, 1, 0, 0, 0, 0])]
  return generateNumbersTraining(true, digitWeights[mapping], wordDict, lessonLength)
}

function rightHand(mapping: 0 | 1, wordDict: string[], lessonLength: number) {
  const digitWeights = [new DigitsData([1, 0, 0, 0, 0, 0, 0, 1, 1, 1]), new DigitsData([1, 0, 0, 0, 0, 0, 1, 1, 1, 1])]
  return generateNumbersTraining(true, digitWeights[mapping], wordDict, lessonLength)
}

function digitAscDesc(lessonLength: number) {
  const cycle = '1234567890 0987654321 '
  let result = ''
  while (result.length < lessonLength) {
    result += cycle
  }
  return result.trim()
}

function digitAscDescSpaced(lessonLength: number) {
  const cycle = '1 2 3 4 5 6 7 8 9 0 0 9 8 7 6 5 4 3 2 1 '
  let result = ''
  while (result.length < lessonLength) {
    result += cycle
  }
  return result.trim()
}

function replaceWithLocalChars(text: string) {
  const courseStore = useCourseStore()
  const layout = courseStore.current.layout

  const keyMap: Record<string, TypeableKeyCode> = { f: 'KeyF', d: 'KeyD', s: 'KeyS', a: 'KeyA', j: 'KeyJ', k: 'KeyK', l: 'KeyL', ';': 'Semicolon' }

  for (const letter in keyMap) {
    text = text.replaceAll(letter, `{${keyMap[letter as keyof typeof keyMap]}}`)
  }

  for (const key of Object.values(keyMap)) {
    text = text.replaceAll(`{${key}}`, layout.getChar(key, Layer.Default)!)
  }

  return text
}

function digitAscDescHomeRow(mapping: 0 | 1, lessonLength: number) {
  const cycle = ['a12s3d4f56 j78k9l0 0l9k87j 65f4d3s21a ', 'a1s2d3f45 j67k8l9;0 0;9l8k76j 54f3d2s1a ']
  let result = ''
  while (result.length < lessonLength) {
    result += cycle[mapping]
  }
  return replaceWithLocalChars(result.trim())
}

function digitAscDescHomeRowSpaced(mapping: 0 | 1, lessonLength: number) {
  const cycle = ['a12 s3 d4 f56 j78 k9 l0 0l 9k 87j 65f 4d 3s 21a ', 'a1 s2 d3 f45 j67 k8 l9 ;0 0; 9l 8k 76j 54f 3d 2s 1a ']
  let result = ''
  while (result.length < lessonLength) {
    result += cycle[mapping]
  }
  return replaceWithLocalChars(result.trim())
}

function pairPattern1(mapping: 0 | 1, lessonLength: number) {
  const cycle = [
    '1212 2323 3434 4545 5656 6767 7878 8989 9090 0909 9898 8787 7676 6565 5454 4343 3232 2121 ',
    '1212 2323 3434 4545 5656 6767 7878 8989 9090 0909 9898 8787 7676 6565 5454 4343 3232 2121 ',
  ]
  let result = ''
  while (result.length < lessonLength) {
    result += cycle[mapping]
  }
  return result.trim()
}

function pairPattern2(mapping: 0 | 1, lessonLength: number) {
  const cycle = ['1313 2424 3535 4646 7979 8080 0808 9797 6464 5353 4242 3131 ', '1313 2424 3535 6868 7979 8080 0808 9797 8686 5353 4242 3131 ']
  let result = ''
  while (result.length < lessonLength) {
    result += cycle[mapping]
  }
  return result.trim()
}

function pairPattern3(mapping: 0 | 1, lessonLength: number) {
  const cycle = ['1212 3030 4949 5858 6767 7676 8585 9494 0303 2121 ', '1010 2929 3838 4747 5656 6565 7474 8383 9292 0101 ']
  let result = ''
  while (result.length < lessonLength) {
    result += cycle[mapping]
  }
  return result.trim()
}

function pairPattern4(mapping: 0 | 1, lessonLength: number) {
  const cycle = ['1212 3737 4848 5959 6060 0606 9595 8484 7373 2121 ', '1616 2727 3838 4949 5050 0505 9494 8383 7272 6161 ']
  let result = ''
  while (result.length < lessonLength) {
    result += cycle[mapping]
  }
  return result.trim()
}

// SEQUENCES

function isInENotation(num: number) {
  const str = num.toString()
  return str.includes('e') || str.includes('E')
}

function fibonacciSequence(lessonLength: number) {
  let result = ''
  let a = 1
  let b = 1
  while (result.length < lessonLength) {
    if (isInENotation(a)) {
      a = 1
      b = 1
    }
    result += a + ' '
    const c = a + b
    a = b
    b = c
  }
  return result.trim()
}

function powersOfTwoSequence(lessonLength: number) {
  let result = ''
  let power = 0
  let num = 1
  while (result.length < lessonLength) {
    if (isInENotation(num)) {
      power = 0
      num = 1
    }
    result += num + ' '
    power++
    num = Math.pow(2, power)
  }
  return result.trim()
}

function squareNumbersSequence(lessonLength: number) {
  let result = ''
  let num = 1
  while (result.length < lessonLength) {
    let square = Math.pow(num, 2)
    if (isInENotation(square)) {
      num = 1
      square = 1
    }
    result += square + ' '
    num++
  }
  return result.trim()
}
