import type { FingerMappingId } from '@/stores/userStore'
import { cloneDeep } from 'lodash-es'
import { Finger, Hand, logicalMapping, optimizedMapping } from './keyboards/FingerMapping'
import { digitKeyCodes } from './keyboards/KeyCode'

const coreKeyToFingerMapping = {
  [Hand.Left]: {
    Backquote: ['hand-left-2', -15, -22],
    // Digit1: ['hand-left-3', -18, -22],
    // Digit2: ['hand-left-4', -18, -21],
    // Digit3: ['hand-left-5', -20, -21],
    // Digit4: ['hand-left-6', -21, -23],
    // Digit5: ['hand-left-7', -21, -21],
    // Digit6: ['hand-left-8', -21, -20],
    KeyQ: ['hand-left-9', -1, -9],
    KeyW: ['hand-left-10', -1, -11],
    KeyE: ['hand-left-11', -1, -12],
    KeyR: ['hand-left-12', -3, -11],
    KeyT: ['hand-left-13', 0, -10],
    KeyA: ['hand-left-14', 0, 0],
    KeyS: ['hand-left-15', 0, 0],
    KeyD: ['hand-left-16', 0, 0],
    KeyF: ['hand-left-17', 0, 0],
    KeyG: ['hand-left-18', 0, 0],
    ShiftLeft_iso: ['hand-left-19', -8, 0],
    IntlBackslash: ['hand-left-20', 0, 0],
    ShiftLeft: ['hand-left-19', -8, 0],
    KeyZ: ['hand-left-21', 0, 0],
    KeyX: ['hand-left-22', -2, -2],
    KeyC: ['hand-left-23', 0, 0],
    KeyV: ['hand-left-24', 0, 0],
    KeyB: ['hand-left-25', 0, 0],
    AltLeft: ['hand-left-26', -48, 0],
    Space: ['hand-left-26', 0, 0],
  },
  [Hand.Right]: {
    Backspace: ['hand-right-2', 4, -22],
    Equal: ['hand-right-3', 1, -21],
    Minus: ['hand-right-4', 2, -23],
    // Digit0: ['hand-right-5', 2, -22],
    // Digit9: ['hand-right-6', 2, -24],
    // Digit8: ['hand-right-7', 3, -25],
    // Digit7: ['hand-right-8', 4, -20],
    Backslash: ['hand-right-9', 4, -8],
    Enter_iso: ['hand-right-9', 4, -8],
    BracketRight: ['hand-right-10', 1, -9],
    BracketLeft: ['hand-right-11', 0, -10],
    KeyP: ['hand-right-12', 0, -9],
    KeyO: ['hand-right-13', 0, -10],
    KeyI: ['hand-right-14', 1, -12],
    KeyU: ['hand-right-15', 0, -11],
    KeyY: ['hand-right-16', -4, -9],
    Enter: ['hand-right-17', 0, 0],
    Backslash_iso: ['hand-right-17', 0, 0],
    Quote: ['hand-right-18', 0, 0],
    Semicolon: ['hand-right-19', 0, 0],
    KeyL: ['hand-right-20', 0, 0],
    KeyK: ['hand-right-21', 0, 0],
    KeyJ: ['hand-right-22', 0, 0],
    KeyH: ['hand-right-23', 0, 0],
    ShiftRight: ['hand-right-24', 1, 0],
    Slash: ['hand-right-25', 0, 0],
    Period: ['hand-right-26', 1, -5],
    Comma: ['hand-right-27', 0, 0],
    KeyM: ['hand-right-28', 0, 0],
    KeyN: ['hand-right-29', 0, 1],
    Space: ['hand-right-30', 0, 0],
    AltRight: ['hand-right-30', 62, 0],
    AltGraph: ['hand-right-30', 43, 0],
  },
}

const adjustibleNumRowMapping = {
  [Hand.Left]: {
    [Finger.Little]: {
      Digit1: { svgId: 'hand-left-3', offset: { x: -18, y: -22 } },
      Digit2: { svgId: 'hand-left-4', offset: { x: -18, y: -21 } },
      Digit3: { svgId: 'hand-left-4', offset: { x: -4, y: -21 } },
      Digit4: { svgId: 'hand-left-4', offset: { x: 9, y: -21 } },
      Digit5: { svgId: 'hand-left-4', offset: { x: 22, y: -21 } },
      Digit6: { svgId: 'hand-left-4', offset: { x: 35, y: -21 } },
      Digit7: { svgId: 'hand-left-4', offset: { x: 48, y: -21 } },
      Digit8: { svgId: 'hand-left-4', offset: { x: 62, y: -21 } },
      Digit9: { svgId: 'hand-left-4', offset: { x: 75, y: -21 } },
      Digit0: { svgId: 'hand-left-4', offset: { x: 87, y: -21 } },
    },
    [Finger.Ring]: {
      Digit1: { svgId: 'hand-left-5', offset: { x: -46, y: -21 } },
      Digit2: { svgId: 'hand-left-5', offset: { x: -33, y: -21 } },
      Digit3: { svgId: 'hand-left-5', offset: { x: -20, y: -21 } },
      Digit4: { svgId: 'hand-left-5', offset: { x: -7, y: -21 } },
      Digit5: { svgId: 'hand-left-5', offset: { x: 6, y: -21 } },
      Digit6: { svgId: 'hand-left-5', offset: { x: 20, y: -21 } },
      Digit7: { svgId: 'hand-left-5', offset: { x: 33, y: -21 } },
      Digit8: { svgId: 'hand-left-5', offset: { x: 46, y: -21 } },
      Digit9: { svgId: 'hand-left-5', offset: { x: 59, y: -21 } },
      Digit0: { svgId: 'hand-left-5', offset: { x: 72, y: -21 } },
    },
    [Finger.Middle]: {
      Digit1: { svgId: 'hand-left-6', offset: { x: -60, y: -23 } },
      Digit2: { svgId: 'hand-left-6', offset: { x: -47, y: -23 } },
      Digit3: { svgId: 'hand-left-6', offset: { x: -33, y: -23 } },
      Digit4: { svgId: 'hand-left-6', offset: { x: -21, y: -23 } },
      Digit5: { svgId: 'hand-left-6', offset: { x: -7, y: -23 } },
      Digit6: { svgId: 'hand-left-6', offset: { x: 6, y: -23 } },
      Digit7: { svgId: 'hand-left-6', offset: { x: 19, y: -23 } },
      Digit8: { svgId: 'hand-left-6', offset: { x: 32, y: -23 } },
      Digit9: { svgId: 'hand-left-6', offset: { x: 45, y: -23 } },
      Digit0: { svgId: 'hand-left-6', offset: { x: 58, y: -23 } },
    },
    [Finger.Index]: {
      Digit1: { svgId: 'hand-left-7', offset: { x: -74, y: -21 } },
      Digit2: { svgId: 'hand-left-7', offset: { x: -60, y: -21 } },
      Digit3: { svgId: 'hand-left-7', offset: { x: -47, y: -21 } },
      Digit4: { svgId: 'hand-left-7', offset: { x: -34, y: -21 } },
      Digit5: { svgId: 'hand-left-7', offset: { x: -21, y: -21 } },
      Digit6: { svgId: 'hand-left-7', offset: { x: -7, y: -21 } },
      Digit7: { svgId: 'hand-left-7', offset: { x: 6, y: -21 } },
      Digit8: { svgId: 'hand-left-7', offset: { x: 19, y: -21 } },
      Digit9: { svgId: 'hand-left-7', offset: { x: 32, y: -21 } },
      Digit0: { svgId: 'hand-left-7', offset: { x: 45, y: -21 } },
    },
  },
  [Hand.Right]: {
    [Finger.Little]: {
      Digit1: { svgId: 'hand-right-5', offset: { x: -117, y: -22 } },
      Digit2: { svgId: 'hand-right-5', offset: { x: -104, y: -22 } },
      Digit3: { svgId: 'hand-right-5', offset: { x: -90, y: -22 } },
      Digit4: { svgId: 'hand-right-5', offset: { x: -77, y: -22 } },
      Digit5: { svgId: 'hand-right-5', offset: { x: -64, y: -22 } },
      Digit6: { svgId: 'hand-right-5', offset: { x: -51, y: -22 } },
      Digit7: { svgId: 'hand-right-5', offset: { x: -38, y: -22 } },
      Digit8: { svgId: 'hand-right-5', offset: { x: -25, y: -22 } },
      Digit9: { svgId: 'hand-right-5', offset: { x: -11, y: -22 } },
      Digit0: { svgId: 'hand-right-5', offset: { x: 2, y: -22 } },
    },
    [Finger.Ring]: {
      Digit1: { svgId: 'hand-right-6', offset: { x: -104, y: -22 } },
      Digit2: { svgId: 'hand-right-6', offset: { x: -90, y: -22 } },
      Digit3: { svgId: 'hand-right-6', offset: { x: -77, y: -22 } },
      Digit4: { svgId: 'hand-right-6', offset: { x: -64, y: -22 } },
      Digit5: { svgId: 'hand-right-6', offset: { x: -51, y: -22 } },
      Digit6: { svgId: 'hand-right-6', offset: { x: -38, y: -22 } },
      Digit7: { svgId: 'hand-right-6', offset: { x: -25, y: -22 } },
      Digit8: { svgId: 'hand-right-6', offset: { x: -11, y: -22 } },
      Digit9: { svgId: 'hand-right-6', offset: { x: 2, y: -22 } },
      Digit0: { svgId: 'hand-right-6', offset: { x: 15, y: -22 } },
    },
    [Finger.Middle]: {
      Digit1: { svgId: 'hand-right-7', offset: { x: -89, y: -24 } },
      Digit2: { svgId: 'hand-right-7', offset: { x: -76, y: -24 } },
      Digit3: { svgId: 'hand-right-7', offset: { x: -63, y: -24 } },
      Digit4: { svgId: 'hand-right-7', offset: { x: -50, y: -24 } },
      Digit5: { svgId: 'hand-right-7', offset: { x: -36, y: -24 } },
      Digit6: { svgId: 'hand-right-7', offset: { x: -23, y: -24 } },
      Digit7: { svgId: 'hand-right-7', offset: { x: -9, y: -24 } },
      Digit8: { svgId: 'hand-right-7', offset: { x: 3, y: -24 } },
      Digit9: { svgId: 'hand-right-7', offset: { x: 16, y: -24 } },
      Digit0: { svgId: 'hand-right-7', offset: { x: 29, y: -24 } },
    },
    [Finger.Index]: {
      Digit1: { svgId: 'hand-right-8', offset: { x: -77, y: -22 } },
      Digit2: { svgId: 'hand-right-8', offset: { x: -64, y: -22 } },
      Digit3: { svgId: 'hand-right-8', offset: { x: -51, y: -22 } },
      Digit4: { svgId: 'hand-right-8', offset: { x: -38, y: -22 } },
      Digit5: { svgId: 'hand-right-8', offset: { x: -25, y: -22 } },
      Digit6: { svgId: 'hand-right-8', offset: { x: -12, y: -22 } },
      Digit7: { svgId: 'hand-right-8', offset: { x: 1, y: -22 } },
      Digit8: { svgId: 'hand-right-8', offset: { x: 14, y: -22 } },
      Digit9: { svgId: 'hand-right-8', offset: { x: 27, y: -22 } },
      Digit0: { svgId: 'hand-right-8', offset: { x: 40, y: -22 } },
    },
  },
}

export const getKeyToHandMapping = (mapping: FingerMappingId) => {
  const mappingBase = cloneDeep(coreKeyToFingerMapping)
  const mappingConfig = mapping === 'logical' ? logicalMapping : optimizedMapping

  for (const code of digitKeyCodes) {
    const finger = mappingConfig.getFinger(code)!
    // @ts-expect-error ignore Thumb
    const handProps = adjustibleNumRowMapping[finger.hand][finger.finger][code]!
    // @ts-expect-error ignore not existing indexes
    mappingBase[finger.hand][code] = [handProps.svgId, handProps.offset.x, handProps.offset.y]
  }

  return mappingBase
}

// auto layout definition hint
export const keyToHandMappingForPointerFinger = {
  [Hand.Left]: {
    Backquote: ['hand-left-17', -64, -23],
    Digit1: ['hand-left-17', -51, -23],
    Digit2: ['hand-left-17', -38, -23],
    Digit3: ['hand-left-17', -25, -23],
    Digit4: ['hand-left-17', -11, -23],
    Digit5: ['hand-left-17', 2, -23],
    Digit6: ['hand-left-17', 15, -23],
    Digit7: ['hand-left-17', 28, -23],
    Digit8: ['hand-left-17', 41, -23],
    Digit9: ['hand-left-17', 54, -23],
    Digit0: ['hand-left-17', 67, -23],
    Minus: ['hand-left-17', 81, -23],
    Equal: ['hand-left-17', 94, -23],
    KeyQ: ['hand-left-17', -43, -11],
    KeyW: ['hand-left-17', -30, -11],
    KeyE: ['hand-left-17', -17, -11],
    KeyR: ['hand-left-17', -4, -11],
    KeyT: ['hand-left-17', 9, -11],
    KeyY: ['hand-left-17', 22, -11],
    KeyU: ['hand-left-17', 35, -11],
    KeyI: ['hand-left-17', 48, -11],
    KeyO: ['hand-left-17', 61, -11],
    KeyP: ['hand-left-17', 75, -11],
    BracketLeft: ['hand-left-17', 88, -11],
    BracketRight: ['hand-left-17', 101, -11],
    Backslash: ['hand-left-17', 114, -11],
    Backslash_iso: ['hand-left-17', 127, 1],
    KeyA: ['hand-left-17', -41, 1],
    KeyS: ['hand-left-17', -28, 1],
    KeyD: ['hand-left-17', -15, 1],
    KeyF: ['hand-left-17', -1, 1],
    KeyG: ['hand-left-17', 12, 1],
    KeyH: ['hand-left-17', 25, 1],
    KeyJ: ['hand-left-17', 38, 1],
    KeyK: ['hand-left-17', 51, 1],
    KeyL: ['hand-left-17', 64, 1],
    Semicolon: ['hand-left-17', 77, 1],
    Quote: ['hand-left-17', 91, 1],
    ShiftLeft: ['hand-left-17', -56, 14],
    ShiftLeft_iso: ['hand-left-17', -62, 14],
    IntlBackslash: ['hand-left-17', -47, 14],
    KeyZ_unknown: ['hand-left-17', -39, 14],
    KeyZ: ['hand-left-17', -34, 14],
    KeyX: ['hand-left-17', -21, 14],
    KeyC: ['hand-left-17', -8, 14],
    KeyV: ['hand-left-17', 5, 14],
    KeyB: ['hand-left-17', 19, 14],
    KeyN: ['hand-left-17', 32, 14],
    KeyM: ['hand-left-17', 45, 14],
    Comma: ['hand-left-17', 58, 14],
    Period: ['hand-left-17', 71, 14],
    Slash: ['hand-left-17', 81, 14],
    ControlLeft: ['hand-left-17', -60, 26],
    AltLeft: ['hand-left-17', -41, 26],
    MetaLeft: ['hand-left-17', -22, 26],
    AltLeftShift: ['hand-left-27', -55, 2],
    AltLeftShift_iso: ['hand-left-27', -47, 6, -26],
  },
  [Hand.Right]: {
    Backquote: ['hand-right-22', -100, -23],
    Digit1: ['hand-right-22', -87, -23],
    Digit2: ['hand-right-22', -74, -23],
    Digit3: ['hand-right-22', -62, -23],
    Digit4: ['hand-right-22', -48, -23],
    Digit5: ['hand-right-22', -35, -23],
    Digit6: ['hand-right-22', -22, -23],
    Digit7: ['hand-right-22', -9, -23],
    Digit8: ['hand-right-22', 4, -23],
    Digit9: ['hand-right-22', 17, -23],
    Digit0: ['hand-right-22', 30, -23],
    Minus: ['hand-right-22', 43, -23],
    Equal: ['hand-right-22', 57, -23],
    KeyQ: ['hand-right-22', -80, -11],
    KeyW: ['hand-right-22', -67, -11],
    KeyE: ['hand-right-22', -54, -11],
    KeyR: ['hand-right-22', -41, -11],
    KeyT: ['hand-right-22', -28, -11],
    KeyY: ['hand-right-22', -14, -11],
    KeyU: ['hand-right-22', -1, -11],
    KeyI: ['hand-right-22', 12, -11],
    KeyO: ['hand-right-22', 25, -11],
    KeyP: ['hand-right-22', 38, -11],
    BracketLeft: ['hand-right-22', 51, -11],
    BracketRight: ['hand-right-22', 64, -11],
    Backslash: ['hand-right-22', 77, -11],
    KeyA: ['hand-right-22', -77, 1],
    KeyS: ['hand-right-22', -64, 1],
    KeyD: ['hand-right-22', -51, 1],
    KeyF: ['hand-right-22', -38, 1],
    KeyG: ['hand-right-22', -25, 1],
    KeyH: ['hand-right-22', -12, 1],
    KeyJ: ['hand-right-22', 1, 1],
    KeyK: ['hand-right-22', 14, 1],
    KeyL: ['hand-right-22', 28, 1],
    Semicolon: ['hand-right-22', 41, 1],
    Quote: ['hand-right-22', 54, 1],
    Backslash_iso: ['hand-right-22', 67, 1],
    IntlBackslash: ['hand-right-22', -84, 14],
    KeyZ: ['hand-right-22', -71, 14],
    KeyX: ['hand-right-22', -58, 14],
    KeyC: ['hand-right-22', -44, 14],
    KeyV: ['hand-right-22', -31, 14],
    KeyB: ['hand-right-22', -18, 14],
    KeyN: ['hand-right-22', -5, 14],
    KeyM: ['hand-right-22', 8, 14],
    Comma: ['hand-right-22', 21, 14],
    Period: ['hand-right-22', 34, 14],
    Slash: ['hand-right-22', 47, 14],
    ShiftRight: ['hand-right-22', 63, 14],
    MetaRight: ['hand-right-22', 37, 26],
    AltRight: ['hand-right-22', 56, 26],
    AltGraph: ['hand-right-22', 37, 26],
    ControlRight: ['hand-right-22', 75, 26],
    AltRightShift: ['hand-right-31', 68, 2],
    AltGraphShift: ['hand-right-32', 54, 2],
  },
}
