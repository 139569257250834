import { Chapter } from '@/course/course-types'
import type { FullTypingResult } from '@/helpers/Trainer'
import { Char } from '@/helpers/keyboards/KeyChar'
import { calcAccuracy, calcSpeed, toFixed } from '@/helpers/main-utils'
import { allLangConfig } from '@/languages/all-lang-config'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { random, sum } from 'lodash-es'
import { allDigits, generateLegacyCharsets, someSpecialChars } from './Charset'
import { LayeredKeyCode } from './LayeredKeycode'
import { LayeredKeycodeMap } from './LayeredKeycodeMap'
import { Metric } from './metric-types'
import { CharTypingResult } from './typing-result/CharTypingResult'

export class CourseHighlightStats {
  private _previewValue: HighlightStats | null = null

  constructor(private _value: HighlightStats) {}

  get value(): HighlightStats {
    const userStore = useUserStore()
    return userStore.demoData ? this.previewData() : this._value
  }

  perKeycode(metric: Metric | 'typosCount'): LayeredKeycodeMap<number> {
    let mergedData = new LayeredKeycodeMap<HighlightKeyData>()

    for (const [dayId, dayData] of Object.entries(this.value)) {
      for (const [char, charData] of Object.entries(dayData)) {
        for (const [currKey, currKeyData] of charData.perKey.entries()) {
          const resultKeyData = mergedData.getOrCreate(LayeredKeyCode.parse(currKey), { ...highlightKeyDataTemplate })
          resultKeyData.pressCount += currKeyData.pressCount
          resultKeyData.totalPressTimeMs += currKeyData.totalPressTimeMs
          resultKeyData.totalTypingTimeMs += currKeyData.totalTypingTimeMs
          resultKeyData.typoCount += currKeyData.typoCount
          mergedData.set(LayeredKeyCode.parse(currKey), resultKeyData)
        }
      }
    }

    const result = new LayeredKeycodeMap<number>()
    let resultValue

    switch (metric) {
      case Metric.Presses:
        resultValue = mergedData.entries().map(([k, v]) => [k, v.pressCount])
        break
      case Metric.Speed:
        resultValue = mergedData.entries().map(([k, v]) => [k, calcSpeed(v.totalPressTimeMs, v.pressCount).wpm])
        break
      case Metric.Accuracy:
        resultValue = mergedData.entries().map(([k, v]) => [k, calcAccuracy(v.pressCount, v.typoCount).percentage])
        break
      case 'typosCount':
        resultValue = mergedData.entries().map(([k, v]) => [k, v.typoCount])
        break
    }

    resultValue = resultValue.filter(([k, v]) => LayeredKeyCode.parse(k).keyCode !== 'Space')

    result.value = Object.fromEntries(resultValue)
    return result
  }

  metrics() {
    let totalTyped = 0
    let totalTypingTimeMs = 0
    let totalTypos = 0

    for (const [dayIndex, dayData] of Object.entries(this.value)) {
      for (const [char, charData] of Object.entries(dayData)) {
        totalTyped += charData.typeCount
        totalTypingTimeMs += charData.totalTypeTimeMs
        totalTypos += sum(charData.perKey.values().map((v) => v.typoCount))
      }
    }

    return {
      speed: calcSpeed(totalTypingTimeMs, totalTyped),
      accuracy: calcAccuracy(totalTyped, totalTypos),
    }
  }

  update(fullResult: FullTypingResult, typingResult: CharTypingResult) {
    const trainingStartTime = fullResult.startedAt
    let chapterData = this._value[fullResult.lessonCoords.chapter]

    if (!chapterData) {
      this._value[fullResult.lessonCoords.chapter] = {}
      chapterData = this._value[fullResult.lessonCoords.chapter]!
    }

    for (const log of fullResult.charLogs) {
      let currCharData = chapterData[log.char]
      if (currCharData) {
        // append to char data
        currCharData.typeCount += 1
        currCharData.totalTypeTimeMs = toFixed(currCharData.totalTypeTimeMs + log.pressTimeMs + log.extraPressTimeMs, 2)
      } else {
        // new char data
        chapterData[log.char] = {
          typeCount: 1,
          totalTypeTimeMs: toFixed(log.pressTimeMs + log.extraPressTimeMs, 2),
          perKey: new LayeredKeycodeMap(),
        }
        currCharData = chapterData[log.char]
      }

      for (const [usedKeyIndex, usedKey] of log.usedKeys.entries()) {
        const lastUsedKey = usedKeyIndex === log.usedKeys.length - 1
        const keyData = currCharData.perKey.getOrCreate(usedKey.pressedKey as LayeredKeyCode, { ...highlightKeyDataTemplate })
        keyData.pressCount++
        keyData.totalPressTimeMs = toFixed(keyData.totalPressTimeMs + usedKey.pressTimeMs, 2)
        keyData.totalTypingTimeMs = toFixed(keyData.totalTypingTimeMs + usedKey.pressTimeMs + log.extraPressTimeMs, 2) // a bit fishy for dead keys
        keyData.typoCount += lastUsedKey ? log.extraFirstPresses.length : 0
      }
    }
  }

  // to Firebase or localStorage object
  serialize(): SerializedHighlightStats {
    return Object.fromEntries(
      Object.entries(this._value).map(([chapter, chapterData]) => {
        return [
          chapter,
          Object.fromEntries(
            Object.entries(chapterData).map(([char, charData]) => [
              char,
              {
                tc: charData.typeCount,
                tt: charData.totalTypeTimeMs,
                pk: Object.fromEntries(
                  Object.entries(charData.perKey.serialize()).map(([key, keyData]) => {
                    let val: SerializedHighlightKeyData = {
                      pc: keyData.pressCount,
                      pt: keyData.totalPressTimeMs,
                      tt: keyData.totalTypingTimeMs,
                    }
                    if (keyData.typoCount > 0) {
                      val.tsc = keyData.typoCount
                    }
                    return [key, val]
                  }),
                ),
              },
            ]),
          ),
        ]
      }),
    )
  }

  // from Firebase or localStorage object
  static parse(serialized: SerializedHighlightStats): CourseHighlightStats {
    return new CourseHighlightStats(
      Object.fromEntries(
        Object.entries(serialized).map(([chapter, chapterData]) => {
          return [
            chapter,
            Object.fromEntries(
              Object.entries(chapterData).map(([char, charData]) => [
                char,
                {
                  typeCount: charData.tc,
                  totalTypeTimeMs: charData.tt,
                  perKey: LayeredKeycodeMap.parse(
                    Object.fromEntries(
                      Object.entries(charData.pk).map(([key, keyData]) => [
                        key,
                        {
                          pressCount: keyData.pc,
                          totalPressTimeMs: keyData.pt,
                          totalTypingTimeMs: keyData.tt ?? keyData.pt, // fallback to pressTime, if legacy data
                          typoCount: keyData.tsc ?? 0,
                        },
                      ]),
                    ),
                  ),
                },
              ]),
            ),
          ]
        }),
      ),
    )
  }

  static template() {
    return new CourseHighlightStats({})
  }

  previewData() {
    if (this._previewValue !== null) {
      return this._previewValue
    }

    const courseStore = useCourseStore()
    const langCode = courseStore.current.definition.languageCode
    const layout = courseStore.current.layout

    let perChar: Record<string, HighlightCharData> = {}

    const langMeta = allLangConfig[langCode]
    const charsToInput = [...Object.values(generateLegacyCharsets(langMeta)), ...allDigits, ...someSpecialChars, ' ']

    charsToInput.flat().forEach((val, i) => {
      // speed 15-100 wpm
      // accuracy 92-100%

      const wpm = random(15, 100)
      const inputCount = random(1, 200)
      const avgInputTime = 60000 / (wpm * 5)
      const totalTypingTimeMs = avgInputTime * inputCount
      const accPct = Math.random() < 0.3 ? random(92, 100) : 100

      const typosCount = Math.round(inputCount * (1 - accPct / 100))
      const typoPressTimeMs = 300

      let perKey = new LayeredKeycodeMap<HighlightKeyData>()

      const key = layout.allKeys(true).find((k) => k.containsChar(new Char(val)))
      if (key) {
        const symbolIndex = Object.values(key.keyChars).findIndex((s) => s.value === val)
        if (symbolIndex !== -1) {
          perKey.set(new LayeredKeyCode(key.code, symbolIndex + 1), {
            pressCount: inputCount,
            totalPressTimeMs: totalTypingTimeMs - typosCount * typoPressTimeMs,
            totalTypingTimeMs,
            typoCount: typosCount,
          })
        }
      }

      perChar[val] = {
        totalTypeTimeMs: totalTypingTimeMs,
        typeCount: inputCount,
        perKey,
      }
    })

    const result = { [Chapter.HomeRow]: perChar }
    this._previewValue = result
    return result
  }
}

export type HighlightStats = Partial<Record<Chapter, HighlightUnitData>>
export type HighlightUnitData = Record<string, HighlightCharData>

export type SerializedHighlightStats = Partial<Record<Chapter, SerializedHighlightUnitData>>
export type SerializedHighlightUnitData = Record<string, SerializedHighlightCharData>

export type HighlightCharData = {
  // speed
  typeCount: number
  totalTypeTimeMs: number
  // acc
  // [totalTypos from keyCode]

  // keyboard highlight
  perKey: LayeredKeycodeMap<HighlightKeyData>
}
export type SerializedHighlightCharData = {
  tc: number
  tt: number
  pk: Record<number, SerializedHighlightKeyData>
}

export type HighlightKeyData = {
  pressCount: number // log dead if next key was correct
  totalPressTimeMs: number // same
  totalTypingTimeMs: number
  typoCount: number // typing char alternative method is not a typo (¨u instead of ü OR caps-A instead of shift-A)
}
export type SerializedHighlightKeyData = {
  pc: number // log dead if next key was correct
  pt: number // same
  tt: number
  tsc?: number // typing char alternative method is not a typo (¨u instead of ü OR caps-A instead of shift-A)
}

const highlightKeyDataTemplate: HighlightKeyData = {
  pressCount: 0,
  totalPressTimeMs: 0,
  totalTypingTimeMs: 0,
  typoCount: 0,
}
