<script setup lang="ts">
type Props = {
  label?: string
  disabled?: boolean
  checked?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  checked: false,
})

const emit = defineEmits(['checked'])

const handleClick = (e: Event) => {
  e.preventDefault()
  emit('checked', !props.checked)
}
</script>

<template>
  <div class="switch-wrapper">
    <label class="switch">
      <input :disabled="disabled" :checked="checked" @input="handleClick" type="checkbox" />
      <span class="slider"></span>
    </label>
    <div class="label" v-if="label" @click="handleClick">{{ label }}</div>
  </div>
</template>

<style lang="scss" scoped>
.switch-wrapper {
  display: flex;
  gap: var(--s-sm);
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:disabled + .slider {
      opacity: 0.5;
      cursor: auto;
    }

    &:checked + .slider {
      background-color: var(--c-primary);
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(calc(12px));
      -ms-transform: translateX(calc(12px));
      transform: translateX(calc(12px));
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    border-radius: 100px;
    background-color: var(--c-secondary-border);
    overflow: hidden;

    &:before {
      position: absolute;
      content: '';
      height: 12px;
      width: 12px;
      top: 50%;
      left: 2px;
      translate: 0 -50%;
      background-color: var(--c-text-on-primary);
      border-radius: 50%;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    }
  }
}

.label {
  cursor: pointer;
  user-select: none;
  font-weight: 500;
}
</style>
