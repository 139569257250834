import type { LocalizedText } from '@/plugins/i18n'
import type { ModifierKey } from '@/types/KeyboardShortcut'
import { ShortcutVariant } from '../layouts/layouts-config'

export type ShortcutConfig = {
  char: string
  modifiers?: ModifierKey[] | 'any'
}

export const shortcutsConfig = {
  // general
  shortcutsHint: {
    [ShortcutVariant.Default]: { char: '/', modifiers: ['Meta'] },
    [ShortcutVariant.German]: { char: '.', modifiers: ['Meta'] },
    [ShortcutVariant.French]: { char: ':', modifiers: ['Meta'] },
  } as Record<string, ShortcutConfig>,

  continue: { char: 'Enter' } as ShortcutConfig,
  edit: { char: 'e' } as ShortcutConfig,
  continueWithModifier: { char: 'Enter', modifiers: ['Meta'] } as ShortcutConfig,
  discard: { char: 'Escape' } as ShortcutConfig,

  // main
  selectHomeView: { char: '1' } as ShortcutConfig,
  selectTrainingView: { char: '2' } as ShortcutConfig,
  selectSettingsView: { char: '3' } as ShortcutConfig,
  nextView: { char: 'Tab' } as ShortcutConfig,
  prevView: { char: 'Tab', modifiers: ['Shift'] } as ShortcutConfig,
  nextTab: { char: ' ' } as ShortcutConfig,
  prevTab: { char: ' ', modifiers: ['Shift'] } as ShortcutConfig,
  aboutOrFeedback: { char: '?', modifiers: 'any' } as ShortcutConfig,
  toggleKeyboard: { char: 'k' } as ShortcutConfig,
  addLayout: { char: '+', modifiers: 'any' } as ShortcutConfig,

  // chars set
  lowerLettersSet: { char: 'l' } as ShortcutConfig,
  upperLettersSet: { char: 'u' } as ShortcutConfig,
  punctuationSet: { char: 'p' } as ShortcutConfig,
  digitsSet: { char: 'd' } as ShortcutConfig,
  otherChars: { char: 'o' } as ShortcutConfig,
  allChars: { char: 'a' } as ShortcutConfig,

  // training types
  trainNgrams: { char: 'n' } as ShortcutConfig,
  trainWords: { char: 'w' } as ShortcutConfig,
  trainShift: { char: 'S', modifiers: ['Shift'] } as ShortcutConfig,
  trainPunctuation: { char: 'p' } as ShortcutConfig,
  trainDigits: { char: 'd' } as ShortcutConfig,
  trainSpecialChars: { char: 's' } as ShortcutConfig,

  // practice view
  iterateNgramType: { char: 'f' } as ShortcutConfig,
  iterateNgramGeneration: { char: 'j' } as ShortcutConfig,
  iterateWordsDict: { char: 'f' } as ShortcutConfig,
  iterateShiftGeneration: { char: 'f' } as ShortcutConfig,
  iteratePunctiationShift: { char: 'f' } as ShortcutConfig,
  iterateDigitsMode: { char: 'f' } as ShortcutConfig,

  // trainer
  restart: { char: 'r', modifiers: ['Meta'] } as ShortcutConfig,
  resume: { char: ' ' } as ShortcutConfig,
  exit: { char: 'x' } as ShortcutConfig,
  toggleFingerMapping: { char: 'm' } as ShortcutConfig, // TODO

  // result
  iterateHighlightedMetric: { char: ' ' } as ShortcutConfig,
  iterateHighlightedMetricPrev: { char: ' ', modifiers: ['Shift'] } as ShortcutConfig,
  repeat: { char: 'r' } as ShortcutConfig,
}

export type ShortcutConfigKey = keyof typeof shortcutsConfig

// HINT

export type ShortcutHintConfig = {
  title: LocalizedText
  shortcuts: ShortcutConfig[]
}

// const mainRouteHint = () => {
//   let shortcuts: ShortcutHintConfig[] = [
//     {
//       title: {
//         en: 'Switch view',
//         ru: ''
//       },
//       shortcuts: [shortcutsConfig.nextView],
//     },
//     {
//       title: 'Switch tab',
//       shortcuts: [shortcutsConfig.nextTab],
//     },
//   ]

//   const courseStore = useCourseStore()
//   if (courseStore.courses.length < MAX_ACTIVE_LAYOUTS) {
//     shortcuts.push({
//       title: 'Add keyboard layout',
//       shortcuts: [shortcutsConfig.addLayout],
//     })
//   }
//   if (courseStore.courses.length > 1) {
//     shortcuts.push({
//       title: 'Switch layout',
//       shortcuts: [shortcutsConfig.toggleKeyboard],
//     })
//   }

//   shortcuts.push({
//     title: 'Complain / suggest',
//     shortcuts: [shortcutsConfig.aboutOrFeedback],
//   })

//   return shortcuts
// }

// key: route name
// export const shortcutsHintConfig = {
//   trainer: () =>
//     [
//       {
//         title: {
//           en: 'Resume',
//           ru: 'Продолжить',
//           es: 'Reanudar',
//           de: 'Fortsetzen',
//           fr: 'Reprendre',
//           it: 'Riprendi',
//         },
//         shortcuts: [shortcutsConfig.discard],
//       },
//       {
//         title: {
//           en: 'Restart',
//           ru: 'Перезапустить',
//           es: 'Reiniciar',
//           de: 'Neustart',
//           fr: 'Recommencer',
//           it: 'Ricomincia',
//         },
//         shortcuts: [shortcutsConfig.restart],
//       },
//       {
//         title: {
//           en: 'Toggle hands',
//           ru: 'Показ рук',
//           es: 'Mostrar manos',
//           de: 'Hände ein/aus',
//           fr: 'Afficher mains',
//           it: 'Mostra mani',
//         },
//         shortcuts: [shortcutsConfig.toggleHands],
//       },
//       {
//         title: {
//           en: 'Toggle keyboard',
//           ru: 'Показ клавиатуры',
//           es: 'Mostrar teclado',
//           de: 'Tastatur ein/aus',
//           fr: 'Afficher clavier',
//           it: 'Mostra tastiera',
//         },
//         shortcuts: [shortcutsConfig.toggleKeyboard],
//       },
//       {
//         title: {
//           en: 'Exit',
//           ru: 'Выйти',
//           es: 'Salir',
//           de: 'Beenden',
//           fr: 'Quitter',
//           it: 'Esci',
//         },
//         shortcuts: [shortcutsConfig.exit],
//       },

//       // {
//       //   title: 'Toggle fingers mapping',
//       //   shortcuts: [shortcutsConfig.toggleFingerMapping],
//       // },
//     ] as ShortcutHintConfig[],
//   // result: () =>
//   //   [
//   //     {
//   //       title: 'Switch metric',
//   //       shortcuts: [shortcutsConfig.iterateHighlightedMetric],
//   //     },
//   //     {
//   //       title: 'Continue',
//   //       shortcuts: [shortcutsConfig.continue],
//   //     },
//   //     {
//   //       title: 'Repeat',
//   //       shortcuts: [shortcutsConfig.repeat],
//   //     },
//   //     {
//   //       title: 'Exit',
//   //       shortcuts: [shortcutsConfig.exit],
//   //     },
//   //   ] as ShortcutHintConfig[],
// }
