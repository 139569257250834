import type { FullTypingResult } from '@/helpers/Trainer'
import { STATS_MS_PRECISION } from '@/helpers/main-config'
import { toFixed } from '@/helpers/main-utils'
import { type CharLog } from '../PressLog'
import type { OS } from '../main-types'
import { TypingResult } from '../typing-result/TypingResult'
import { ExtendedTypingResult } from './ExtendedTypingResult'

export class CharTypingResult extends TypingResult {
  dataPerChar: Record<string, ExtendedTypingResult>

  constructor(
    validPresses: number,
    validPressesTimeMs: number,
    validTypingTimeMs: number,
    invalidPresses: number,
    invalidTypingTimeMs: number,
    typos: number,
    dataPerChar: Record<string, ExtendedTypingResult>,
  ) {
    super(validPresses, validPressesTimeMs, validTypingTimeMs, invalidPresses, invalidTypingTimeMs, typos)
    this.dataPerChar = dataPerChar
  }

  static summarize(typings: CharTypingResult[]): CharTypingResult {
    const result = this.fromTypingResult(super.summarize(typings))

    let totalDataPerChar: Record<string, ExtendedTypingResult[]> = {}

    for (const typing of typings) {
      for (const [key, value] of Object.entries(typing.dataPerChar)) {
        if (!totalDataPerChar[key]) {
          totalDataPerChar[key] = []
        }
        totalDataPerChar[key].push(value)
      }
    }

    for (const [key, value] of Object.entries(totalDataPerChar)) {
      result.dataPerChar[key] = ExtendedTypingResult.summarize(value)
    }

    return result
  }

  // factories

  static fromTypingResult(typingData: TypingResult): CharTypingResult {
    return new CharTypingResult(
      typingData.validPresses,
      typingData.validPressesTimeMs,
      typingData.validTypingTimeMs,
      typingData.invalidPresses,
      typingData.invalidTypingTimeMs,
      typingData.typos,
      {},
    )
  }

  toJSON(): any {
    return {
      ...super.toJSON(),
      dataPerChar: this.dataPerChar,
    }
  }

  static fromJSON(parsed: any): CharTypingResult {
    const result = CharTypingResult.fromTypingResult(TypingResult.fromJSON(parsed))
    result.dataPerChar = parsed.dataPerChar
    return result
  }

  static fromFullTypingResult(fullResult: FullTypingResult, layoutOS: OS): CharTypingResult {
    let charLogs = fullResult.charLogs

    charLogs = charLogs.filter((c) => c.isPressed)

    const valid = charLogs
    const invalid = [] as CharLog[] // TODO remove

    let result = {
      validPresses: valid.length,
      validPressesTimeMs: toFixed(
        valid.reduce((acc, curr) => acc + curr.pressTimeMs, 0),
        STATS_MS_PRECISION,
      ),
      validTypingTimeMs: toFixed(
        valid.reduce((acc, curr) => acc + curr.pressTimeMs + curr.extraPressTimeMs, 0),
        STATS_MS_PRECISION,
      ),
      invalidPresses: invalid.length,
      invalidTypingTimeMs: toFixed(
        invalid.reduce((acc, curr) => acc + curr.pressTimeMs + curr.extraPressTimeMs, 0),
        STATS_MS_PRECISION,
      ),
    }

    let typosCount = 0
    // const digitsData = DigitsData.init(new ExtendedTypingResult(0, 0, 0, 0, 0, 0, []))
    let dataPerChar: Record<string, ExtendedTypingResult> = {}

    charLogs.forEach((charLog) => {
      // presses per char
      const charData = dataPerChar[charLog.char] ?? ExtendedTypingResult.template()
      const isPressValid = charLog.pressTimeMs !== 0 && !charLog.extraPresses.find((p) => p.pressTimeMs === 0)
      if (isPressValid) {
        charData.validPresses += 1
        charData.validPressesTimeMs += charLog.pressTimeMs
        charData.validTypingTimeMs += charLog.pressTimeMs + charLog.extraPressTimeMs
      } else {
        charData.invalidPresses += 1
        charData.invalidTypingTimeMs += charLog.pressTimeMs + charLog.extraPressTimeMs
      }

      // add typo to typos list in data per char
      charData.typosList = charData.typosList.concat(charLog.extraFirstPresses)
      charData.typos += charLog.extraFirstPresses.length
      dataPerChar[charLog.char] = charData

      typosCount += charLog.extraFirstPresses.length
    })

    // round values
    for (const [key, value] of Object.entries(dataPerChar)) {
      dataPerChar[key].validPressesTimeMs = toFixed(value.validPressesTimeMs, STATS_MS_PRECISION)
      dataPerChar[key].validTypingTimeMs = toFixed(value.validTypingTimeMs, STATS_MS_PRECISION)
      dataPerChar[key].invalidTypingTimeMs = toFixed(value.invalidTypingTimeMs, STATS_MS_PRECISION)
    }

    return new CharTypingResult(
      result.validPresses,
      result.validPressesTimeMs,
      result.validTypingTimeMs,
      result.invalidPresses,
      result.invalidTypingTimeMs,
      typosCount,
      dataPerChar,
    )
  }

  static template(): CharTypingResult {
    return new CharTypingResult(0, 0, 0, 0, 0, 0, {})
  }
}

// other types

// export type NumKeyStat = {
//   key: number // 0-9
//   correctPresses: number
//   incorrectPresses: number
//   avgPressTime: number | undefined
//   totalPressTimeMs: number
//   accuracy: Accuracy
//   avgSpeed: Speed
//   typosByPopularity: Record<string, number>
// }
