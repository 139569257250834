import type { FullTypingResult } from '@/helpers/Trainer'
import type { TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { Layer } from '@/helpers/keyboards/Layer'
import { useUserStore } from '@/stores/userStore'
import { cloneDeep } from 'lodash-es'
import { LayeredKeyCode, type LayeredKeycodeId } from './LayeredKeycode'
import { LayeredKeycodeMap } from './LayeredKeycodeMap'
import { CharTypingResult } from './typing-result/CharTypingResult'

export class CourseMispressesStats {
  private _previewValue: LayeredKeycodeMap<LayeredKeycodeId[]> | null = null

  // NOTE maybe refactor to LayeredKeycode[], but needs extra steps in (de)serializiation
  constructor(private _value: LayeredKeycodeMap<LayeredKeycodeId[]>) {}

  get value(): LayeredKeycodeMap<LayeredKeycodeId[]> {
    const userStore = useUserStore()
    return userStore.demoData ? this.previewData() : this._value
  }

  typosByLayeredKeycode(layeredKeycode: LayeredKeyCode): LayeredKeyCode[] {
    return (this.value.get(layeredKeycode) ?? []).map((k) => LayeredKeyCode.parse(k))
  }

  update(fullResult: FullTypingResult, typingResult: CharTypingResult) {
    for (const log of fullResult.charLogs) {
      // get latest key, which eventually typed char correctly
      const usedLayeredKeyCode = log.usedKeys.slice(-1)[0].pressedKey as LayeredKeyCode
      if (log.extraFirstPresses.length) {
        const typos = log.extraFirstPresses.map((p) => {
          let result
          try {
            result = p.serialize()
          } catch (e) {
            console.error(e)
            console.log('//p:', p)
            console.log('//extraFirstPresses:', log.extraFirstPresses)
            console.log('//charLog:', cloneDeep(log))
            console.log('//charLogs:', cloneDeep(fullResult.charLogs))
            result = p as unknown as number
          }
          return result
        })
        const currValue = this._value.getOrCreate(usedLayeredKeyCode, [])
        currValue.push(...typos)
        this._value.set(usedLayeredKeyCode, currValue)
      }
    }
  }

  // to Firebase or localStorage object
  serialize(): SerializedCourseMispressesStats {
    return this._value.serialize()
  }

  // from Firebase or localStorage object
  static parse(serialized: SerializedCourseMispressesStats): CourseMispressesStats {
    return new CourseMispressesStats(LayeredKeycodeMap.parse(serialized))
  }

  static template() {
    return new CourseMispressesStats(new LayeredKeycodeMap())
  }

  previewData() {
    if (this._previewValue !== null) {
      return this._previewValue
    }

    const defaultLayeredKeycodeId = (keycode: TypeableKeyCode) => new LayeredKeyCode(keycode, Layer.Default).id()

    const mispressesPreview = new LayeredKeycodeMap<LayeredKeycodeId[]>()
    mispressesPreview.value = {
      [defaultLayeredKeycodeId('KeyF')]: [
        defaultLayeredKeycodeId('KeyG'),
        defaultLayeredKeycodeId('KeyG'),
        defaultLayeredKeycodeId('KeyG'),
        defaultLayeredKeycodeId('KeyG'),
        defaultLayeredKeycodeId('KeyG'),
        defaultLayeredKeycodeId('KeyG'),
        defaultLayeredKeycodeId('KeyR'),
        defaultLayeredKeycodeId('KeyR'),
        defaultLayeredKeycodeId('KeyR'),
        defaultLayeredKeycodeId('KeyD'),
        defaultLayeredKeycodeId('KeyD'),
        defaultLayeredKeycodeId('KeyS'),
      ],
      [defaultLayeredKeycodeId('KeyJ')]: [
        defaultLayeredKeycodeId('KeyU'),
        defaultLayeredKeycodeId('KeyU'),
        defaultLayeredKeycodeId('KeyU'),
        defaultLayeredKeycodeId('KeyU'),
        defaultLayeredKeycodeId('KeyU'),
        defaultLayeredKeycodeId('KeyU'),
        defaultLayeredKeycodeId('KeyK'),
        defaultLayeredKeycodeId('KeyK'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyH'),
      ],
      [defaultLayeredKeycodeId('KeyA')]: [
        defaultLayeredKeycodeId('KeyQ'),
        defaultLayeredKeycodeId('KeyQ'),
        defaultLayeredKeycodeId('KeyW'),
        defaultLayeredKeycodeId('KeyW'),
        defaultLayeredKeycodeId('KeyW'),
        defaultLayeredKeycodeId('KeyS'),
        defaultLayeredKeycodeId('KeyZ'),
      ],
      [defaultLayeredKeycodeId('KeyL')]: [
        defaultLayeredKeycodeId('KeyP'),
        defaultLayeredKeycodeId('KeyO'),
        defaultLayeredKeycodeId('KeyO'),
        defaultLayeredKeycodeId('KeyO'),
        defaultLayeredKeycodeId('KeyO'),
        defaultLayeredKeycodeId('KeyK'),
        defaultLayeredKeycodeId('KeyM'),
      ],
      [defaultLayeredKeycodeId('KeyE')]: [
        defaultLayeredKeycodeId('KeyR'),
        defaultLayeredKeycodeId('KeyR'),
        defaultLayeredKeycodeId('KeyW'),
        defaultLayeredKeycodeId('KeyS'),
        defaultLayeredKeycodeId('KeyD'),
        defaultLayeredKeycodeId('KeyD'),
        defaultLayeredKeycodeId('KeyD'),
        defaultLayeredKeycodeId('KeyD'),
      ],
      [defaultLayeredKeycodeId('KeyC')]: [
        defaultLayeredKeycodeId('KeyX'),
        defaultLayeredKeycodeId('KeyX'),
        defaultLayeredKeycodeId('KeyV'),
        defaultLayeredKeycodeId('KeyD'),
        defaultLayeredKeycodeId('KeyD'),
        defaultLayeredKeycodeId('KeyF'),
      ],
      [defaultLayeredKeycodeId('KeyM')]: [
        defaultLayeredKeycodeId('KeyN'),
        defaultLayeredKeycodeId('KeyB'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyJ'),
      ],
      [defaultLayeredKeycodeId('KeyZ')]: [
        defaultLayeredKeycodeId('KeyA'),
        defaultLayeredKeycodeId('KeyS'),
        defaultLayeredKeycodeId('KeyD'),
        defaultLayeredKeycodeId('KeyX'),
      ],
      [defaultLayeredKeycodeId('KeyB')]: [
        defaultLayeredKeycodeId('KeyV'),
        defaultLayeredKeycodeId('KeyV'),
        defaultLayeredKeycodeId('KeyN'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyG'),
      ],
      [defaultLayeredKeycodeId('KeyD')]: [
        defaultLayeredKeycodeId('KeyS'),
        defaultLayeredKeycodeId('KeyE'),
        defaultLayeredKeycodeId('KeyE'),
        defaultLayeredKeycodeId('KeyE'),
        defaultLayeredKeycodeId('KeyC'),
        defaultLayeredKeycodeId('KeyF'),
      ],
      [defaultLayeredKeycodeId('KeyG')]: [
        defaultLayeredKeycodeId('KeyF'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyT'),
        defaultLayeredKeycodeId('KeyT'),
        defaultLayeredKeycodeId('KeyT'),
        defaultLayeredKeycodeId('KeyV'),
        defaultLayeredKeycodeId('KeyV'),
      ],
      [defaultLayeredKeycodeId('KeyH')]: [
        defaultLayeredKeycodeId('KeyG'),
        defaultLayeredKeycodeId('KeyJ'),
        defaultLayeredKeycodeId('KeyJ'),
        defaultLayeredKeycodeId('KeyJ'),
        defaultLayeredKeycodeId('KeyJ'),
        defaultLayeredKeycodeId('KeyJ'),
        defaultLayeredKeycodeId('KeyY'),
        defaultLayeredKeycodeId('KeyB'),
      ],
      [defaultLayeredKeycodeId('KeyI')]: [
        defaultLayeredKeycodeId('KeyU'),
        defaultLayeredKeycodeId('KeyU'),
        defaultLayeredKeycodeId('KeyO'),
        defaultLayeredKeycodeId('KeyO'),
        defaultLayeredKeycodeId('KeyO'),
        defaultLayeredKeycodeId('KeyK'),
        defaultLayeredKeycodeId('KeyK'),
        defaultLayeredKeycodeId('KeyJ'),
      ],
      [defaultLayeredKeycodeId('KeyK')]: [
        defaultLayeredKeycodeId('KeyJ'),
        defaultLayeredKeycodeId('KeyL'),
        defaultLayeredKeycodeId('KeyI'),
        defaultLayeredKeycodeId('KeyI'),
        defaultLayeredKeycodeId('KeyI'),
        defaultLayeredKeycodeId('KeyM'),
      ],
      [defaultLayeredKeycodeId('KeyN')]: [
        defaultLayeredKeycodeId('KeyB'),
        defaultLayeredKeycodeId('KeyB'),
        defaultLayeredKeycodeId('KeyB'),
        defaultLayeredKeycodeId('KeyM'),
        defaultLayeredKeycodeId('KeyH'),
        defaultLayeredKeycodeId('KeyJ'),
      ],
      [defaultLayeredKeycodeId('KeyO')]: [
        defaultLayeredKeycodeId('KeyI'),
        defaultLayeredKeycodeId('KeyP'),
        defaultLayeredKeycodeId('KeyP'),
        defaultLayeredKeycodeId('KeyP'),
        defaultLayeredKeycodeId('KeyL'),
        defaultLayeredKeycodeId('KeyK'),
      ],
      [defaultLayeredKeycodeId('KeyP')]: [
        defaultLayeredKeycodeId('KeyO'),
        defaultLayeredKeycodeId('KeyL'),
        defaultLayeredKeycodeId('KeyQ'),
        defaultLayeredKeycodeId('KeyQ'),
        defaultLayeredKeycodeId('KeyQ'),
        defaultLayeredKeycodeId('KeyW'),
      ],
      [defaultLayeredKeycodeId('KeyQ')]: [
        defaultLayeredKeycodeId('KeyW'),
        defaultLayeredKeycodeId('KeyA'),
        defaultLayeredKeycodeId('KeyA'),
        defaultLayeredKeycodeId('KeyA'),
        defaultLayeredKeycodeId('KeyS'),
        defaultLayeredKeycodeId('KeyP'),
        defaultLayeredKeycodeId('KeyP'),
      ],
    }

    const result = mispressesPreview
    this._previewValue = result
    return result
  }
}
export type SerializedCourseMispressesStats = Record<LayeredKeycodeId, LayeredKeycodeId[]>
