import type { FingerMappingId } from '@/stores/userStore'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { OS } from '@/types/main-types'
import { getFingerMapping, Hand } from './keyboards/FingerMapping'
import type { KeyCode, ModifierKeyCode } from './keyboards/KeyCode'
import { isCapsLayer, isOptionLayer, isShiftLayer } from './keyboards/Layer'

const leftHandKeyCodes: KeyCode[] = [
  'Backquote',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Digit6',
  'KeyQ',
  'KeyW',
  'KeyE',
  'KeyR',
  'KeyT',
  'KeyA',
  'KeyS',
  'KeyD',
  'KeyF',
  'KeyG',
  'ShiftLeft',
  'IntlBackslash',
  'KeyZ',
  'KeyX',
  'KeyC',
  'KeyV',
  'KeyB',
  'AltLeft',
  'Space',
]

const rightHandKeyCodes: KeyCode[] = [
  'Backspace',
  'Equal',
  'Minus',
  'Digit0',
  'Digit9',
  'Digit8',
  'Digit7',
  'Backslash',
  'BracketRight',
  'BracketLeft',
  'KeyP',
  'KeyO',
  'KeyI',
  'KeyU',
  'KeyY',
  'Enter',
  'Quote',
  'Semicolon',
  'KeyL',
  'KeyK',
  'KeyJ',
  'KeyH',
  'ShiftRight',
  'Slash',
  'Period',
  'Comma',
  'KeyM',
  'KeyN',
  'AltRight',
  'AltGraph',
  'Space',
]

export const whichHandToUse = (layeredKeyCode: LayeredKeyCode, os: OS, mappingId: FingerMappingId, isIndexFinger = false): Hand => {
  if (isIndexFinger && isOptionLayer(layeredKeyCode.layer) && os !== OS.mac) {
    return Hand.Left
  }

  const mapping = getFingerMapping(mappingId)
  return mapping.getFinger(layeredKeyCode.keyCode)?.hand ?? Hand.Left // Space for example
}

export const getModifierKeys = (layeredKeyCode: LayeredKeyCode, os: OS, mapping: FingerMappingId, isIndexFinger = false): ModifierKeyCode[] => {
  const { layer } = layeredKeyCode

  const leftRight: 'Left' | 'Right' = whichHandToUse(layeredKeyCode, os, mapping, isIndexFinger) === Hand.Left ? Hand.Right : Hand.Left

  let modifiers: ModifierKeyCode[] = []

  if (isCapsLayer(layer)) {
    modifiers.push('CapsLock')
  }
  if (isOptionLayer(layer)) {
    modifiers.push(os === OS.mac ? `Alt${leftRight}` : 'AltGraph')
  }
  if (isShiftLayer(layer)) {
    modifiers.push(`Shift${leftRight}`)
  }

  return modifiers
}
