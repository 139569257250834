<script setup lang="ts">
import { isSafari } from '@/helpers/user-agent-utils'
import { usePointer } from '@vueuse/core'
import { computed, useTemplateRef, type StyleValue } from 'vue'

type Props = {
  glowColor: string
}
defineProps<Props>()

type CardStyle = {
  card: StyleValue
  glow: StyleValue
}

const card = useTemplateRef('card')

const { x, y } = usePointer()

const cardStyle = computed<CardStyle>(() => {
  let result: CardStyle = { card: {}, glow: {} }

  if (isSafari()) {
    return result
  }

  if (!card.value) {
    return result
  }

  const cardRect = card.value.getBoundingClientRect()
  const elementX = x.value - cardRect.left
  const elementY = y.value - cardRect.top

  // is outside
  if (elementX < 0 || elementY < 0 || elementX > cardRect.width || elementY > cardRect.height) {
    return result
  }

  const centerX = cardRect.width / 2
  const centerY = cardRect.height / 2
  const rotateX = (elementY - centerY) / 200
  const rotateY = (centerX - elementX) / 200

  return {
    card: {
      transform: `perspective(1500px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    },
    glow: {
      display: 'block',
      transform: 'translate(-50%, -50%)',
      left: `${elementX}px`,
      top: `${elementY}px`,
    },
  }
})
</script>

<template>
  <div ref="card" class="card" :style="cardStyle.card">
    <div class="glow" :style="cardStyle.glow"></div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 14px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #131314;
  backdrop-filter: blur(32px);
  transition: transform 0.15s ease;
  padding: var(--padding-y, 5rem) var(--padding-x, 3rem);

  .content {
    position: relative;
    z-index: 10;
  }

  .glow {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    border-radius: 1000px;
    opacity: 0.5;
    background: v-bind(glowColor);
    // background: radial-gradient(73.6% 73.6% at 42.69% 26.76%, v-bind(glowColor) 0%, rgba(95, 199, 82, 0) 100%);
    filter: blur(180px);
    display: none;
  }
}
</style>
