import { toFixed } from '@/helpers/main-utils'
import type { LayeredKeyCode } from './LayeredKeycode'

export class PressLog {
  public pressTimeMs: number
  public pressed: string //char
  public pressedKey: LayeredKeyCode | 'Backspace' // key code
  public toType: string // real char to type, never Backspace
  public transformed: boolean

  constructor(pressedKey: LayeredKeyCode | 'Backspace', pressed: string, toType: string, pressTimeMs: number, transformed: boolean) {
    this.pressTimeMs = toFixed(pressTimeMs, 2)
    this.pressedKey = pressedKey
    this.pressed = pressed
    this.toType = toType
    this.transformed = transformed
  }
}

// export const derivePressTime = (pressLogs: PressLog[]) => {
//   return pressLogs.map((p, i, arr) => ({ ...p, pressTimeMs: i === 0 ? 0 : p.pressTimeMs.diff(arr[i - 1].pressTimeMs) }))
// }

export type CharLog = {
  char: string
  isPressed: boolean

  usedKeys: PressLog[]
  pressTimeMs: number

  extraPresses: PressLog[] // typos and Backspaces
  extraFirstPresses: LayeredKeyCode[]

  extraPressTimeMs: number // sum of extraPresses times
  // typosCount: number // count full keystroke as a single typo, but count again if fixed and typed typo again
}

export const getTextFromPressLogs = (pressLogs: PressLog[]) => {
  let originalText = ''
  let typosAtTheEnd = 0

  pressLogs.forEach((press, index) => {
    if (press.pressed === 'Backspace') {
      originalText = originalText.slice(0, -1)
    } else {
      originalText += press.pressed

      if (press.pressed === press.toType) {
        typosAtTheEnd = 0
      } else {
        typosAtTheEnd++
      }
    }
  })

  originalText = typosAtTheEnd ? originalText.slice(0, -typosAtTheEnd) : originalText
  return originalText
}
