import { buildCourse } from '@/course/course-builder'
import { Chapter, type LessonCoords, type LessonData } from '@/course/course-types'
import { average, toFixed } from '@/helpers/main-utils'
import type { FullTypingResult } from '@/helpers/Trainer'
import { useCourseStore, type LayoutData } from '@/stores/courseStore'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { max, sum } from 'lodash-es'
import { CharTypingResult } from './typing-result/CharTypingResult'

export class CourseCompletionStats {
  public value: CompletionStats

  constructor(val: CompletionStats | null) {
    this.value = val ?? new Map()
  }

  isEmpty() {
    return !this.value.size
  }

  update(fullResult: FullTypingResult, typingResult: CharTypingResult, layoutData: LayoutData) {
    const course = buildCourse(layoutData.layout)!
    const lessonData = course.content[fullResult.lessonCoords.chapter][fullResult.lessonCoords.index]

    const lessonCompletionData: LessonCompletionMetadata = {
      accuracyPct: toFixed(typingResult.accuracy.percentage, 2),
      speedWpm: toFixed(typingResult.speed.wpm, 2),
      typingTimeMs: toFixed(typingResult.typingTimeMs, 2),
      lastPassDate: dayjs(),
      passCount: (this.value.get(lessonData.uniqueId)?.passCount ?? 0) + 1,
    }
    this.value.set(lessonData.uniqueId, lessonCompletionData)
  }

  logInfoLesson(lessonId: string) {
    this.value.set(lessonId, {
      passCount: 1,
      lastPassDate: dayjs(),
      speedWpm: 0,
      accuracyPct: 0,
      typingTimeMs: 0,
    })
  }

  courseCompletion() {
    const courseStore = useCourseStore()
    const course = buildCourse(courseStore.current.layout)
    return course ? toFixed((this.value.size / Object.values(course.content).flat().length) * 100, 1) : 0
  }

  nextNotPassedLesson(lessonId?: string) {
    const courseStore = useCourseStore()
    const course = buildCourse(courseStore.current.layout)

    if (!course) {
      return { chapter: Chapter.HomeRow, index: 0 }
    }

    let shouldBeNext = false
    for (const chapter in course.content) {
      for (let i = 0; i < course.content[chapter as Chapter].length; i++) {
        const lessonData = course.content[chapter as Chapter][i]

        if (shouldBeNext) {
          return { chapter: chapter as Chapter, index: i }
        } else if (lessonId) {
          if (lessonData.uniqueId === lessonId) {
            shouldBeNext = true
          }
        } else if (!this.value.has(lessonData.uniqueId)) {
          return { chapter: chapter as Chapter, index: i }
        }
      }
    }

    return { chapter: Object.keys(course.content)[0] as Chapter, index: 0 }
  }

  nextLesson(): LessonCoords {
    if (this.value.size === 0) {
      return this.nextNotPassedLesson()
    }
    const allLessons = [...this.value.entries()]
    const latestDate = max(allLessons.map(([k, v]) => v.lastPassDate.unix()))

    const courseStore = useCourseStore()
    const course = buildCourse(courseStore.current.layout)

    if (!course) {
      return { chapter: Chapter.HomeRow, index: 0 }
    }

    const lessonsSequence = Object.values(course.content)
      .flat()
      .map((l) => l.uniqueId)
    allLessons.sort((a, b) => lessonsSequence.indexOf(a[0]) - lessonsSequence.indexOf(b[0]))
    allLessons.reverse()

    const latestLessonId = allLessons.find(([k, l]) => l.lastPassDate.unix() === latestDate)![0]
    return this.nextNotPassedLesson(latestLessonId)
  }

  isChapterCompleted(chapter: LessonData[]) {
    return chapter.every((l) => this.value.has(l.uniqueId))
  }

  chapterCompletionData(chapter: LessonData[]) {
    if (!this.isChapterCompleted(chapter)) {
      return null
    }

    // NOTE: filtering is important for
    // - legacy "info" type lesson
    // - very legacy completion stats with zeros (migrated)
    const lessonsStat = chapter.map((l) => this.lessonCompletionData(l.uniqueId)).filter((l) => l && l.accuracyPct) as LessonCompletionMetadata[]

    return {
      speedWpm: average(lessonsStat.map((s) => s.speedWpm))!,
      accuracyPct: average(lessonsStat.map((s) => s.accuracyPct))!,
      typingTimeMs: sum(lessonsStat.map((s) => s.typingTimeMs)),
    }
  }

  isLessonCompleted(uniqueId: string) {
    return this.value.has(uniqueId)
  }

  lessonCompletionData(uniqueId: string) {
    return this.value.get(uniqueId)
  }

  isCurrentChapter(chapter: Chapter) {
    return this.nextLesson().chapter === chapter
  }

  isCurrentLesson(chapter: Chapter, index: number) {
    const nextLesson = this.nextLesson()
    return nextLesson.chapter === chapter && nextLesson.index === index
  }

  // to Firebase or localStorage object
  serialize(): CourseCompletionStatsSzd {
    return Object.fromEntries(
      [...this.value.entries()].map(([k, v]) => [
        k,
        {
          pc: v.passCount,
          lpd: v.lastPassDate.toISOString(),
          sp: v.speedWpm,
          ac: v.accuracyPct,
          tt: v.typingTimeMs,
        },
      ]),
    )
  }

  // from Firebase or localStorage object
  static parse(serialized: CourseCompletionStatsSzd): CourseCompletionStats {
    let parsed: CompletionStats

    if (Array.isArray(serialized)) {
      const legacySerialized = serialized as unknown as CourseCompletionStatsSzdLegacy160924
      parsed = new Map(
        legacySerialized.map((id) => [
          id,
          {
            passCount: 1,
            lastPassDate: dayjs(),
            speedWpm: 0,
            accuracyPct: 0,
            typingTimeMs: 0,
          },
        ]),
      )
    } else {
      parsed = new Map(
        Object.entries(serialized).map(([id, v]) => [
          id,
          {
            passCount: v.pc,
            lastPassDate: dayjs(v.lpd),
            speedWpm: v.sp,
            accuracyPct: v.ac,
            typingTimeMs: v.tt,
          },
        ]),
      )
    }

    return new CourseCompletionStats(parsed)
  }

  static template() {
    return new CourseCompletionStats(null)
  }
}

export type LessonCompletionMetadata = {
  passCount: number
  lastPassDate: Dayjs
  speedWpm: number
  accuracyPct: number
  typingTimeMs: number
}
export type LessonCompletionMetadataSzd = {
  pc: number
  lpd: string
  sp: number
  ac: number
  tt: number
}

export type CompletionStats = Map<string, LessonCompletionMetadata>
export type CourseCompletionStatsSzd = Record<string, LessonCompletionMetadataSzd>

// old format before Sep 16, 2024
export type CompletionStatsLegacy160924 = Set<string>
export type CourseCompletionStatsSzdLegacy160924 = string[]
