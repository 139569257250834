import { useAppStore } from '@/stores/appStore'
import { hexToHsl, hsl, type HSL } from '@/types/color-types'
import type { Accuracy, Speed } from '@/types/metric-types'

export type KeyData = {
  presses: number
  accuracy: Accuracy
  speed: Speed
}

export const greenAccuracyIsMoreThan = 95
export const yellowAccuracyIsEqualOrMoreThan = 90

export const ACC_COLORS_DARK = ['#FF6658', '#FEBC2E', '#2BC83E']
export const ACC_COLORS_LIGHT = ['#F15142', '#FFAA33', '#2FBD40']

export const ACC_COLORS = () => {
  const { isDarkTheme } = useAppStore()
  return isDarkTheme ? ACC_COLORS_DARK : ACC_COLORS_LIGHT
}

export const accuracyColor = (value: number): string => {
  const [red, orange, green] = ACC_COLORS()

  if (value > greenAccuracyIsMoreThan) {
    return green
  } else if (value < yellowAccuracyIsEqualOrMoreThan) {
    return red
  }
  return orange
}

export const accuracyTone = (value: number, min: number, max: number): HSL => {
  const middle = (min + max) / 2
  const opacity = 100

  // const red = hsl(5, 79, 65, opacity)
  // const orange = hsl(41, 89, 64, opacity)
  // const green = hsl(113, 51, 55, opacity)
  const [red, orange, green] = ACC_COLORS().map((c) => hexToHsl(c))

  if (value >= max) {
    return green
  } else if (value <= min) {
    return red
  }

  // Interpolate between Orange and Red/Green
  if (value < middle) {
    // Scale between Red and Orange
    const scale = (value - min) / (middle - min)
    return hsl(
      red.hue + (orange.hue - red.hue) * scale,
      red.saturation + (orange.saturation - red.saturation) * scale,
      red.lightness + (orange.lightness - red.lightness) * scale,
      opacity,
    )
  } else {
    // Scale between Orange and Green
    const scale = (value - middle) / (max - middle)
    return hsl(
      orange.hue + (green.hue - orange.hue) * scale,
      orange.saturation + (green.saturation - orange.saturation) * scale,
      orange.lightness + (green.lightness - orange.lightness) * scale,
      opacity,
    )
  }
}

const calcOpacity = (value: number, minVal: number, maxVal: number, startFrom = 10) => {
  const scale = minVal === maxVal ? 1 : (value - minVal) / (maxVal - minVal)
  const opacity = Math.round(startFrom + 80 * scale)
  return opacity
}

// Speed color scale (wpm, min/max)
export const speedColor = (value: number, minVal: number, maxVal: number): string => {
  const appStore = useAppStore()
  const isDarkTheme = appStore.isDarkTheme

  const baseColor = ['0 117 235', '0 117 235']
  const opacity = calcOpacity(value, minVal, maxVal)
  return `rgb(${baseColor[isDarkTheme ? 1 : 0]} / ${opacity}%)`
}

// Press count color scale (min/max)
export const pressesColor = (value: number, minVal: number, maxVal: number): string => {
  const appStore = useAppStore()
  const isDarkTheme = appStore.isDarkTheme

  const baseColor = ['132 163 190', '103 142 176']
  // const baseColor = ['255 134 20', '255 134 20']

  // pass 40 to make it better looking for keyb coverage stats
  const opacity = calcOpacity(value, minVal, maxVal, 25)
  return `rgb(${baseColor[isDarkTheme ? 1 : 0]} / ${opacity}%)`
}

// css

// export const coloredMetricsCSS = (keysRawValue: KeyData[]) => {
//   type StylesObject = { speed: StyleValue; accuracy: StyleValue; presses: StyleValue }
//   const result: DigitsData<StylesObject> = DigitsData.init({ speed: {}, accuracy: {}, presses: {} })

//   const minPresses = min(keysRawValue.filter((k) => k.presses !== 0).map((k) => k.presses)) as number
//   const maxPresses = max(keysRawValue.filter((k) => k.presses !== 0).map((k) => k.presses)) as number

//   const minSpeed = min(keysRawValue.filter((k) => k.speed.value !== undefined).map((k) => k.speed.cpm)) as number
//   const maxSpeed = max(keysRawValue.filter((k) => k.speed.value !== undefined).map((k) => k.speed.cpm)) as number

//   for (let i = 0; i < keysRawValue.length; i++) {
//     const item = keysRawValue[i]

//     if (!item) {
//       continue
//     }

//     const keyData = result.rawValue[i]
//     if (item.presses !== 0) {
//       const pressesBg = pressesColor(item.presses, minPresses, maxPresses)
//       keyData.presses = {
//         backgroundColor: pressesBg,
//         color: 'var(--c-text-primary)',
//         border: 'none',
//       }
//     }
//     if (item.accuracy.value !== undefined) {
//       const accuracyBg = accuracyColor(item.accuracy.percentage)
//       keyData.accuracy = {
//         backgroundColor: hslToString(accuracyBg),
//         color: hslToString(getContrastTextColor(accuracyBg)),
//         border: 'none',
//       }
//     }
//     if (item.speed.value !== undefined) {
//       const speedBg = speedColor(item.speed.cpm, minSpeed, maxSpeed)
//       keyData.speed = {
//         backgroundColor: speedBg,
//         color: 'var(--c-text-primary)',
//         border: 'none',
//       }
//     }
//   }
//   return result
// }

// via GPT
// function getAccColorFromCssGradient(percent: number): RGB {
//   function hexToRgb(hex: string) {
//     const bigint = parseInt(hex.slice(1), 16)
//     return {
//       r: (bigint >> 16) & 255,
//       g: (bigint >> 8) & 255,
//       b: bigint & 255,
//     }
//   }

//   const gradient = [
//     { percent: 0, color: ACCURACY_COLOR_THRESHOLDS[0].color },
//     { percent: 50, color: ACCURACY_COLOR_THRESHOLDS[1].color },
//     { percent: 100, color: ACCURACY_COLOR_THRESHOLDS[2].color },
//   ]
//   let lower = gradient[0],
//     upper = gradient[gradient.length - 1]
//   for (let i = 0; i < gradient.length - 1; i++) {
//     if (percent >= gradient[i].percent && percent <= gradient[i + 1].percent) {
//       lower = gradient[i]
//       upper = gradient[i + 1]
//       break
//     }
//   }
//   const range = upper.percent - lower.percent
//   const rangePercent = (percent - lower.percent) / range
//   const lowerColor = hexToRgb(lower.color)
//   const upperColor = hexToRgb(upper.color)
//   const resultColor = {
//     r: Math.round(lowerColor.r + (upperColor.r - lowerColor.r) * rangePercent),
//     g: Math.round(lowerColor.g + (upperColor.g - lowerColor.g) * rangePercent),
//     b: Math.round(lowerColor.b + (upperColor.b - lowerColor.b) * rangePercent),
//   }
//   return resultColor
// }
