<script setup lang="ts">
import { buildCourse } from '@/course/course-builder'
import { getChapterTitle, getLessonTitle } from '@/course/course-lesson-titles'
import { Chapter, type LessonCoords, type LessonData } from '@/course/course-types'
import { accuracyColor } from '@/helpers/metric-color-scales'
import { localizedDateTimeFormat, localizedDayjs } from '@/plugins/dayjs'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { Speed } from '@/types/metric-types'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import LessonCompletionTooltip from './LessonCompletionTooltip.vue'

const { t } = useI18n()
const courseStore = useCourseStore()
const userStore = useUserStore()
const userLayout = computed(() => courseStore.current)
const layout = computed(() => userLayout.value.layout)
const router = useRouter()

const course = computed(() => buildCourse(layout.value))
const chapters = computed(() => (course.value ? course.value.content : null))

const lessonData = (chapter: Chapter, index: number) => {
  const coords: LessonCoords = { chapter, index }
  return chapters.value ? chapters.value[coords.chapter][coords.index] : null
}

const isChapterLocked = (chapter: Chapter) => {
  return !userStore.hasFullAccess && chapter !== Chapter.HomeRow
}

const isLessonLocked = (chapter: Chapter, index: number) => {
  return !lessonData(chapter, index)?.availableOnTrial && !userStore.hasFullAccess
}

// status
const completionStats = computed(() => courseStore.current.stats.completion)
const nextLesson = computed(() => completionStats.value.nextLesson())
const expandedChapters = ref<Set<Chapter>>(new Set([]))

watch(
  nextLesson,
  (to) => {
    expandedChapters.value.clear()
    expandedChapters.value.add(to.chapter)
  },
  { immediate: true },
)

const onLessonClick = (chapter: Chapter, index: number) => {
  const lessonCoords: LessonCoords = { chapter, index }
  if (isLessonLocked(chapter, index)) {
    return
  }
  userStore.currentLesson = lessonCoords
  const firstLesson = lessonData(Chapter.HomeRow, 0)!
  if (lessonCoords.chapter === Chapter.HomeRow && lessonCoords.index === 0 && !completionStats.value.isLessonCompleted(firstLesson.uniqueId)) {
    router.push({ name: 'intro' })
  } else {
    router.push({ name: 'typing' })
  }
}

const onChapterClick = (chapter: Chapter) => {
  if (expandedChapters.value.has(chapter)) {
    expandedChapters.value.delete(chapter)
  } else {
    expandedChapters.value.add(chapter)
  }
}

const lessonCompletionData = (uniqueId: string) => {
  if (!completionStats.value.isLessonCompleted(uniqueId)) {
    return null
  }
  const data = completionStats.value.lessonCompletionData(uniqueId)
  if (!data?.accuracyPct) {
    return null
  }

  const dateVal = localizedDayjs(data.lastPassDate).format(localizedDateTimeFormat())
  const accVal = data.accuracyPct.toFixed(1) + '%'
  const speedVal = new Speed(data.speedWpm * 5).format({ unit: userStore.settings.speedUnit })

  // Last completed on Oct 18, 2024, with 97.1% accuracy and 45.1 wpm speed. Tip: Aim for 98%+ accuracy to earn a green checkmark!
  return {
    ...data,
    accuracy: accVal,
    speed: speedVal,
    date: dateVal,
    color: accuracyColor(data.accuracyPct),
  }
}

const chapterCompletionData = (lessons: LessonData[]) => {
  const data = completionStats.value.chapterCompletionData(lessons)
  if (!data?.accuracyPct) {
    return null
  }
  return {
    ...data,
    value: `${data.accuracyPct.toFixed(1)}`,
    color: accuracyColor(data.accuracyPct),
  }
}
</script>

<template>
  <div class="course-wrapper">
    <h2 class="course-title">
      <span>{{ t('Home.courseProgress') }}</span>
      <span class="value kinda-mono">{{ completionStats.courseCompletion() }}%</span>
    </h2>

    <div
      v-for="(lessons, chapter, index) in chapters"
      :key="index"
      class="chapter"
      :class="{
        completed: completionStats.isChapterCompleted(lessons),
        current: nextLesson.chapter === chapter,
        expanded: expandedChapters.has(chapter),
      }"
    >
      <!-- chapter title -->
      <div @click="onChapterClick(chapter)" class="chapter-title">
        <!-- expand arrow -->
        <div class="arrow" :class="{ up: expandedChapters.has(chapter) }">
          <svg>
            <use href="#icon-arrow"></use>
          </svg>
        </div>
        <!-- text -->
        <span class="text">{{ getChapterTitle(chapter) }}</span>
        <!-- lock, checkmark -->
        <div
          v-if="isChapterLocked(chapter)"
          class="icon-append chapter-lock"
          v-tippy="{ content: t('Home.availableInFullVersion'), placement: 'right' }"
        >
          <svg>
            <use href="#icon-lock"></use>
          </svg>
        </div>
        <div class="icon-append completed" v-else-if="completionStats.isChapterCompleted(lessons)">
          <svg :style="{ color: chapterCompletionData(lessons)?.color }">
            <use :href="chapterCompletionData(lessons)?.accuracyPct === 100 ? '#hundred-percent' : '#checkmark-circle'"></use>
          </svg>
        </div>
      </div>

      <!-- lesson list, if expanded -->
      <div class="lesson-list" v-show="expandedChapters.has(chapter)">
        <tippy
          :theme="`ts ${isLessonLocked(chapter, i) ? '' : 'info'}`"
          placement="left"
          v-for="(lesson, i) in lessons"
          :key="`${userLayout.definition.toStringId()}-lesson-${i}`"
          class="lesson-title"
          :class="{
            first: i === 0,
            completed: completionStats.isLessonCompleted(lesson.uniqueId),
            current: nextLesson.chapter === chapter && nextLesson.index === i,
            locked: isLessonLocked(chapter, i),
          }"
          @click="onLessonClick(chapter, i)"
        >
          <div class="icon-prepend completed" v-if="lessonCompletionData(lesson.uniqueId)">
            <svg :style="{ color: lessonCompletionData(lesson.uniqueId)!.color }">
              <use :href="lessonCompletionData(lesson.uniqueId)?.accuracyPct === 100 ? '#hundred-percent' : '#checkmark-circle'"></use>
            </svg>
          </div>
          <div v-else class="icon-prepend">
            <svg>
              <use href="#circle-dashed"></use>
            </svg>
          </div>
          <!-- title text -->
          <span class="text">
            {{ getLessonTitle(lesson) }}
          </span>

          <template v-if="lessonCompletionData(lesson.uniqueId)" #content>
            <LessonCompletionTooltip :data="lessonCompletionData(lesson.uniqueId)!" />
          </template>
          <template v-else-if="isLessonLocked(chapter, i)" #content>
            {{ t('Home.availableInFullVersion') }}
          </template>
        </tippy>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.course-title {
  font-size: var(--fz-xs);
  font-weight: 400;
  color: var(--c-text-secondary);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 6px;
  padding: var(--s-sm) var(--s-sm) var(--s-sm) var(--s-sm);
  margin-bottom: var(--s-md);
  line-height: 1;
  display: none;

  .value {
    font-weight: 600;
    color: var(--c-text-primary);
  }
}

.chapter {
  position: relative;
  border-bottom: 1px solid rgb(var(--c-divider-rgb) / 1);

  &:last-of-type {
    border-bottom: none;
  }

  // border fade-out
  &:after {
    content: url('');
    height: 1px;
    width: 100px;
    bottom: -1px;
    right: 0;
    position: absolute;
    background: linear-gradient(90deg, rgb(var(--c-background-rgb) / 0) 0%, rgb(var(--c-background-rgb) / 100) 100%);
  }

  .chapter-title {
    position: relative;
    cursor: pointer;
    width: 100%;
    display: flex;
    user-select: none;
    font-size: var(--fz-sm);
    color: var(--c-text-secondary);
    line-height: 1;
    padding: 8px 6px;
    padding-left: 4px;
    transition: color 0.2s ease;

    &:hover {
      color: var(--c-text-primary);
      font-weight: 500;
    }

    .arrow {
      transition: all 0.3s ease-out;
      position: relative;
      display: inline-grid;
      place-items: center;
      margin-right: var(--s-xs);
      transform: rotate(-90deg);
      &.up {
        transform: rotate(0deg);
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .icon-append {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      padding: 0 7px;
      display: inline-grid;
      place-items: center;

      svg {
        width: 16px;
        height: 16px;
      }

      &.chapter-lock {
        color: var(--c-secondary-icon);
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  &.completed,
  &.current {
    .chapter-title {
      color: var(--c-text-primary);
    }
  }

  .lesson-list {
    padding: 2px 0 10px;

    .lesson-title {
      display: block;
      font-size: var(--fz-xs);
      line-height: 1;
      cursor: pointer;
      margin: 0 6px 0 20px;
      padding: 5px 6px 5px 22px;
      color: var(--c-text-secondary);
      border-radius: var(--br-sm);
      position: relative;

      &:hover {
        background: var(--c-secondary-hover);
      }
      &.current {
        background: var(--c-secondary-hover);
      }
      &.completed {
        color: var(--c-text-primary);
      }
      &.locked {
        cursor: not-allowed;
      }

      .icon-prepend {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        padding: 0 4px;
        display: inline-grid;
        place-items: center;
        color: var(--c-secondary-icon);

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
</style>
