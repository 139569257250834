import { DurationComponent, localizedDurationComponent } from '@/plugins/dayjs'
import dayjs from 'dayjs'

export const formatDuration = (
  duration: number | null | undefined,
  options?: {
    placeholder?: string
    lastUnitCanBeZero?: boolean
  },
) => {
  const defaultOptions = { placeholder: '—', lastUnitCanBeZero: true }
  const finalOptions = { ...defaultOptions, ...options }

  if (!duration) {
    return finalOptions.placeholder
  }

  const d = dayjs.duration(duration, 'seconds')

  const durationComponents = [
    { value: d.days(), label: DurationComponent.d },
    { value: d.hours(), label: DurationComponent.h },
    { value: d.minutes(), label: DurationComponent.m },
    { value: d.seconds(), label: DurationComponent.s },
    { value: d.milliseconds(), label: DurationComponent.ms },
  ]

  let result = ''
  let unitsAdded = 0
  for (const component of durationComponents) {
    if (component.value || unitsAdded) {
      if (unitsAdded === 1 && component.label === 'ms') {
        result = localizedDurationComponent(Math.round(d.asSeconds()), DurationComponent.s)
      } else {
        // don't add zeros
        if (Math.round(component.value) !== 0 || finalOptions.lastUnitCanBeZero) {
          result += localizedDurationComponent(Math.round(component.value), component.label) + ' '
        }
      }
      unitsAdded++
    }

    if (unitsAdded === 2) {
      break
    }
  }

  return result.trim()
}

export const formatNumChars = (
  value: number | null | undefined,
  options?: {
    placeholder?: string
    appendUnit?: boolean
  },
) => {
  const defaultOptions = { placeholder: '-', appendUnit: true }
  const finalOptions = { ...defaultOptions, ...options }

  if (!value) {
    return finalOptions.placeholder
  }

  const result = numberWithCommas(value)
  const unit = value > 1 ? 'chars' : 'char'
  return finalOptions.appendUnit ? `${result} ${unit}` : result
}

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const fillWithZeros = (number: number | string, length: number) => {
  let result = number.toString()
  while (result.length < length) {
    result = `0${result}`
  }
  return result
}
